import { createContext, useState, useEffect } from 'react';
import { captureMessage, setUser as setSentryUser } from '@sentry/react';
import { toast } from 'react-toastify';
import { likeProduct } from 'api/productApi';
import { addProductToCart, updateCartPrices } from 'custom-studio-constants';
import * as userApi from '../api/userApi';
import useTidioChatApi from 'hooks/useTidioChatApi';
import isArtist from 'utils/isArtist';

const getDefaultCart = () => {
	let savedCart;
	try {
		savedCart = JSON.parse(localStorage.getItem('cart'));
		if (savedCart) {
			savedCart.subOrders.forEach((subOrder) =>
				subOrder.cartItems.forEach((item) => {
					item.personalizationImages?.forEach((img) => {
						fetch(img.data)
							.then((res) => res.blob())
							.then((blob) => {
								img.file = blob;
							});
					});
				})
			);
		}
	} catch (err) {
		captureMessage('Failed to getDefaultCart', { extra: { err } });
	}
	return (
		savedCart || {
			subOrders: [],
			isLoaded: false,
		}
	);
};

const UserContext = createContext();
export default UserContext;

export function UserProvider({ children }) {
	const tidioChatApi = useTidioChatApi();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState(null);
	const [cart, setCart] = useState(getDefaultCart());
	const [likedProducts, setLikedProducts] = useState(getLikedProductsFromLocalStorage());
	const [checkingSession, setCheckingSession] = useState(true);

	const login = async (userData, includeWelcomeMessage = false) => {
		if (!userData) return;
		if (userData.error) {
			return toast.error(userData.error);
		}

		setUser(userData);
		setIsLoggedIn(true);
		setLikedProducts(userData.likedProducts);
		setCart(userData.cart);
		localStorage.removeItem('cart');

		if (includeWelcomeMessage) {
			toast.success('Bine ai venit!', {
				autoClose: 1500,
			});
		}
	};

	const logout = async (userData) => {
		await userApi.logoutUser();
		setUser(null);
		setIsLoggedIn(false);
		setLikedProducts([]);
		localStorage.removeItem('cart');
		setCart(getDefaultCart());
	};

	const setCartAndSave = (newCart) => {
		try {
			localStorage.setItem('cart', JSON.stringify(newCart));
		} catch (err) {
			captureMessage('Failed to save cart to local storage', { extra: { err } });
		}
		setCart(newCart);
	};

	const loadCart = async () => {
		if (user) {
			const res = await userApi.getCart();
			if (res.error) {
				toast.error(res.error);
			} else {
				setCart({ ...res, isLoaded: true });
			}
		} else {
			const newCart = { ...cart, isLoaded: true };
			updateCartPrices(newCart);
			setCartAndSave(newCart);
		}
	};

	const addToCart = async (product, selectedOptions, personalizationImages, personalizationText) => {
		if (user) {
			await userApi.addToCart(product._id, selectedOptions, personalizationText, personalizationImages);
		} else {
			const newCart = { ...cart };
			addProductToCart(
				newCart,
				product,
				selectedOptions,
				personalizationText,
				personalizationImages,
				(images) => images
			);
			setCartAndSave(newCart);
		}
	};

	const clearCart = () => {
		localStorage.removeItem('cart');
		setCart(getDefaultCart());
	};

	const updateCartItemQuantity = async (itemId, quantity) => {
		if (user) {
			const res = await userApi.updateCartItemQuantity(itemId, quantity);
			if (res.error) {
				toast.error(res.error);
			} else {
				setCart({ ...cart, ...res });
			}
		} else {
			const newCart = { ...cart };
			newCart.subOrders = newCart.subOrders
				.map((subOrder) => {
					const item = subOrder.cartItems.find((i) => i._id === itemId || i.id === itemId);
					if (item) {
						if (quantity === 0) {
							subOrder.cartItems = subOrder.cartItems.filter(
								(i) => !(i._id === itemId || i.id === itemId)
							);
							if (subOrder.cartItems.length === 0) {
								return null;
							}
						} else {
							item.quantity = Math.min(quantity, item.product.stock);
						}
					}

					return subOrder;
				})
				.filter(Boolean);

			updateCartPrices(newCart);
			setCartAndSave(newCart);
		}
	};

	const setDiscountCodes = (discountCodes) => {
		setUser({ ...user, discountCodes });
	};

	const checkSession = async (includeWelcomeMessage = false) => {
		setCheckingSession(true);
		const res = await userApi.checkLoginStatus();
		if (!res || !res.user) return null;

		login(res.user, includeWelcomeMessage);
		setCheckingSession(false);
		return res.user;
	};

	const setProductLike = async (productId, like) => {
		if (like) window.ttq.track('AddToWishlist', { email: user?.email });
		const res = await likeProduct(productId, like);

		if (!res.error) {
			if (like) {
				setLikedProducts([...likedProducts, productId]);
			} else {
				setLikedProducts(likedProducts.filter((id) => id !== productId));
			}
		}
	};

	// Check if user is logged in on app load
	useEffect(() => {
		checkSession(false).then(() => {
			setCheckingSession(false);
		});
	}, []);

	// Identify the logged in user with Tidio and Sentry
	useEffect(() => {
		if (user) {
			tidioChatApi?.setVisitorData({
				email: user.email,
				tags: isArtist(user) ? ['artist'] : ['not-artist'],
			});
			setSentryUser({ email: user.email, isArtist: isArtist(user) });
		} else {
			tidioChatApi?.setVisitorData({});
			setSentryUser({});
		}
	}, [user, tidioChatApi]);

	useEffect(() => {
		if (isLoggedIn) {
			const localStorageLikedProducts = getLikedProductsFromLocalStorage();
			if (localStorageLikedProducts.length) {
				userApi.addProductsToLikedProducts(localStorageLikedProducts).then((res) => {
					if (!res.error) {
						setUser({ ...user, likedProducts: res.likedProducts });
						setLikedProducts(res.likedProducts);
					}
				});
			}
			setLikedProductsInLocalStorage([]);
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (!isLoggedIn) {
			setLikedProductsInLocalStorage(likedProducts);
		}
	}, [isLoggedIn, likedProducts]);

	return (
		<UserContext.Provider
			value={{
				isLoggedIn,
				user,
				cart,
				checkingSession,
				likedProducts,
				tidioChatApi,
				checkSession,
				logout,
				login,
				setUser,
				loadCart,
				setCart,
				addToCart,
				clearCart,
				updateCartItemQuantity,
				setDiscountCodes,
				setProductLike,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}

function getLikedProductsFromLocalStorage() {
	try {
		const storedLikes = localStorage.getItem('product-likes');
		if (storedLikes) {
			return JSON.parse(storedLikes);
		}
	} catch (err) {
		captureMessage('Failed to getLikedProductsFromLocalStorage()', { extra: err });
		console.error(err);
	}

	return [];
}

function setLikedProductsInLocalStorage(productIds) {
	try {
		localStorage.setItem('product-likes', JSON.stringify(productIds));
	} catch (err) {
		captureMessage('Failed to setLikedProductsInLocalStorage()', { extra: err });
		console.error(err);
	}
}
