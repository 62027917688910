import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { becomeArtist } from 'api/userApi';
import { ArtistGirl } from 'icons';
import useGrayBackground from '../../hooks/useGrayBackground';
import useScrollToTop from 'hooks/useScrollToTop';
import WhiteContainer from 'components/WhiteContainer';
import UserContext from 'context/UserContext';
import AddAddressModal from 'components/modals/AddAddressModal';
import Individual from './Individual';
import Company from './Company';
import isArtist from 'utils/isArtist';

const COMPANY = 'company';
const INDIVIDUAL = 'individual';

export default function BecomeArtist(props) {
	useScrollToTop();
	useGrayBackground();

	const { user, setUser } = useContext(UserContext);
	const [showAddressModal, setShowAddressModal] = useState(false);
	const [page, setPage] = useState(INDIVIDUAL);
	const navigate = useNavigate();

	const onSubmit = async (formData) => {
		const data = Object.fromEntries(formData);
		const res = await becomeArtist(data);

		if (res.error) {
			toast.error(res.error);
		} else {
			setUser(res);
			toast.success('Mult succes!');
			navigate('/user/profile', { replace: true });
		}
	};

	if (isArtist(user)) {
		return <Navigate to="/user/profile" replace />;
	}

	return (
		<div className="md:container md:mt-10">
			<div className="bg-blue relative w-full md:rounded-lg py-6 px-2.5 md:py-6 md:px-10 lg:py-12 xl:px-24 md:!pr-[420px] md:h-[600px] text-white">
				<h2 className="mb-8 text-center md:text-left font-semibold">
					Creează-ți propriul magazin online!
					<br />
					Simplu și gratuit.
				</h2>
				<i>
					<div className="mb-4 md:mb-0">
						<div className="mb-3 flex items-center">
							<RiCheckboxCircleFill size={24} className="mr-2 shrink-0" />
							<span>Acceptă plăți online.</span>
						</div>
						<div className="mb-3 flex items-center">
							<RiCheckboxCircleFill size={24} className="mr-2 shrink-0" />
							<span>Vinde produse ca persoană fizică.</span>
						</div>
						<div className="mb-3 flex items-center">
							<RiCheckboxCircleFill size={24} className="mr-2 shrink-0" />
							<span>Acces de oriunde, oricând la magazinul tău online.</span>
						</div>
						<div className="mb-3 flex items-center">
							<RiCheckboxCircleFill size={24} className="mr-2 shrink-0" />
							<span>Transformă orice obiect personalizat într-o afacere de succes!</span>
						</div>
					</div>
				</i>

				<div className="max-w-[400px] mx-auto md:w-[400px] md:absolute right-[20px] xl:right-[50px] top-[20px] text-primary z-10">
					<WhiteContainer className="flex overflow-hidden mb-4 !shadow-none p-2">
						<div
							className={`tab rounded-md ${page === INDIVIDUAL ? 'active' : ''}`}
							onClick={() => setPage(INDIVIDUAL)}
						>
							Persoană fizică
						</div>
						<div
							className={`tab rounded-md ${page === COMPANY ? 'active' : ''}`}
							onClick={() => setPage(COMPANY)}
						>
							Persoană juridică
						</div>
					</WhiteContainer>

					{page === INDIVIDUAL ? (
						<Individual
							user={user}
							onSubmit={onSubmit}
							openAddressModal={() => setShowAddressModal(true)}
						/>
					) : (
						<Company
							user={user}
							onSubmit={onSubmit}
							openAddressModal={() => setShowAddressModal(true)}
						/>
					)}
				</div>

				{showAddressModal && <AddAddressModal onRequestClose={() => setShowAddressModal(false)} />}

				<ArtistGirl className="md:absolute -bottom-[68px] md:-bottom-[60px] md:ml-20 max-w-[320px] md:max-w-none mx-auto -mb-[84px] md:mb-0 mt-4" />
			</div>
		</div>
	);
}
