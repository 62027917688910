import { useState, useEffect, useMemo } from 'react';

export default function Avatar({ photo, name = '', className = 'w-9 h-9', face }) {
	const [error, setError] = useState(false);

	const onError = (e) => {
		setError(true);
	};

	const onLoad = () => {
		if (error) setError(false);
	};

	// solves the bug with the photo not showing when user edits an erroring avatar
	useEffect(() => {
		setError(false);
	}, [photo]);

	if (photo && !error) {
		return (
			<div className="rounded-full bg-eee">
				<img
					className={`user-photo ${className}`}
					src={photo}
					referrerPolicy="no-referrer"
					onError={onError}
					onLoad={onLoad}
					loading="lazy"
					alt={name}
				/>
			</div>
		);
	} else {
		return <MockAvatar className={`${className}`} name={name} face={face} />;
	}
}

function MockAvatar({ name, face, className }) {
	const [fontSize, setFontSize] = useState(0);
	const [container, setContainer] = useState(null);

	const initials = useMemo(() => {
		return name
			.split(' ')
			.slice(0, 2)
			.map((x) => x.charAt(0).toUpperCase())
			.join('');
	}, [name]);

	// update font size based on avatar size
	useEffect(() => {
		if (!container) return;

		const onResize = () => setFontSize(container.clientWidth * 0.39);
		window.addEventListener('resize', onResize);
		onResize();

		return () => window.removeEventListener('resize', onResize);
	}, [container]);

	if (name) {
		return (
			<div
				className={`bg-eee text-secondary font-semibold rounded-full flex justify-center items-center shrink-0 select-none ${className}`}
				ref={setContainer}
				style={{ fontSize: `${fontSize}px`, paddingTop: '1px' }}
			>
				{initials}
			</div>
		);
	}

	return <div className="p-0.5">{face}</div>;
}
