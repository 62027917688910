import { Link, useNavigate } from 'react-router-dom';
import { formatRating, formatReceivedReviews } from 'utils/format';
import Avatar from 'components/Avatar';
import RatingStars from 'components/RatingStars';
import Button from 'components/Button';
import { RiSendPlaneFill } from 'react-icons/ri';
import { useContext, useEffect, useRef, useState } from 'react';
import UserContext from 'context/UserContext';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import LoginPanel from 'components/login/LoginPanel';

export const ContactArtistButton = ({ artist, className }) => {
	const { user } = useContext(UserContext);
	const [showLoginPanel, setShowLoginPanel] = useState(false);
	const navigate = useNavigate();
	const contactArtistAfterLogin = useRef();

	const onContactArtist = () => {
		if (user) {
			if (user._id === artist._id) {
				return toast.info('Tu ești artistul 😎');
			}
			ReactGA.event({ action: 'Contact artist', category: 'Chat' });
			navigate('/chat', {
				state: {
					messageUser: {
						_id: artist._id,
						displayName: artist.displayName,
						photo: artist.photo,
					},
				},
			});
		} else {
			ReactGA.event({ action: 'Contact artist (as guest)', category: 'Chat' });
			contactArtistAfterLogin.current = true;
			setShowLoginPanel(true);
		}
	};

	// perform actions after login if necessary
	useEffect(() => {
		if (!showLoginPanel && !user) {
			contactArtistAfterLogin.current = false;
		}

		if (!showLoginPanel && user) {
			if (contactArtistAfterLogin.current) {
				contactArtistAfterLogin.current = false;
				onContactArtist();
			}
		}
	}, [user, showLoginPanel]);

	return (
		<>
			{/* Login panel */}
			{showLoginPanel && <LoginPanel onRequestClose={() => setShowLoginPanel(false)} />}

			<Button
				text="Contactează vânzătorul"
				containerClassName="link w-full"
				className="flex items-center px-8 md:px-0"
				onClick={onContactArtist}
				icon={<RiSendPlaneFill className="mr-2" size={19} />}
			/>
		</>
	);
};

export default function ArtistPanel({ artist, className = '' }) {
	const rating = formatRating(artist.averageRating);

	return (
		<div className={className}>
			<Link to={`/artist/${artist.username}`} className={`flex items-center w-fit`}>
				<Avatar photo={artist.photo} name={artist.displayName} className="w-11 h-11" />
				<div className="ml-2">
					<div>
						<span className="font-semibold text-secondary">Vânzător: </span>
						<span className="link">{artist.displayName}</span>
					</div>
					<div className="flex items-center text-md text-secondary">
						<RatingStars rating={rating} size={14} />
						{rating !== 0 && <span className="ml-1.5 mt-0.5">{rating}</span>}
						<span className="ml-1.5 mt-0.5">
							({formatReceivedReviews(artist.receivedReviews)})
						</span>
					</div>
				</div>
			</Link>
		</div>
	);
}
