export default function ResponsiveImage({ src, onClick, className = '', alt, loading }) {
	return (
		<div
			className={`responsive-square rounded-md bg-gray object-cover overflow-hidden ${className}`}
			onClick={onClick}
		>
			<img src={src} className="w-full object-cover rounded-md" alt={alt} loading={loading} />
		</div>
	);
}
