import { OrderStatus } from 'custom-studio-constants/constants';

export function getProductStatus({ denied, approved, publiclyListed, customFor, stock }) {
	if (denied) return 'Respins';
	if (!approved && publiclyListed) return 'În așteptare';
	if (customFor) return 'Custom';
	if (!publiclyListed) return 'Ascuns';
	if (stock === 0) return 'Stoc epuizat';
	return 'Activ';
}

export function getProductStatusType(status) {
	if (status === 'Respins') return 'danger';
	if (status === 'În așteptare') return 'primary';
	if (status === 'Custom') return 'alert';
	if (status === 'Ascuns') return 'light';
	if (status === 'Stoc epuizat') return 'danger';
	return 'success';
}

export function getOrderStatusType(status) {
	if (status === OrderStatus.AwaitingPayment) return 'light';
	if (status === OrderStatus.AwaitingConfirmation) return 'warning';
	if (status === OrderStatus.Processing) return 'processing';
	if (status === OrderStatus.Shipped) return 'primary';
	if (status === OrderStatus.Finalized) return 'success';
	if (status === OrderStatus.Cancelled) return 'danger';
	return 'success';
}
