import ReactGA from 'react-ga4';
import UserContext from 'context/UserContext';
import Button from '../Button';
import Form from '../Form';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postJson } from 'api/_methods';
import { FacebookLoginButton, GoogleLoginButton } from './ProviderLogin';
import { InputText } from '../Input';
import { UIModalCloseButton } from 'components/modals/UIModal';
import { LoginBanner } from 'icons';

export default function Login({ goToRegister, onClose }) {
	const { login } = useContext(UserContext);

	const onLogin = async (formData, e) => {
		const { email, password } = Object.fromEntries(formData);

		if (!email || !password) {
			return toast.error('Lipsă credențiale!');
		}

		ReactGA.event('login', { method: 'local' });

		const res = await postJson('/login', { email: email.toLowerCase(), password });

		if (res.error) {
			toast.error(res.error);
		} else {
			login(res);
			onClose(res);
		}
	};

	return (
		<>
			<div className="login-banner">
				<LoginBanner />
				<UIModalCloseButton theme="light" onClick={onClose} />
			</div>

			<div className="mt-3 px-5 pb-7 md:px-8 md:pb-6">
				<h2 className="text-center font-semibold text-[26px]">Intră în cont</h2>

				<div className="text-center mb-5">
					<span>Nu ai cont?</span>
					<span className="ml-1 link select-none" onClick={goToRegister}>
						Creează unul acum!
					</span>
				</div>

				<Form onSubmit={onLogin}>
					<InputText type="email" name="email" placeHolder="E-mail" containerClassName="!mb-2" />

					<div className="relative">
						<InputText
							type="password"
							name="password"
							placeHolder="Parolă"
							containerClassName="!mb-2"
						/>
					</div>

					<Button type="submit" containerClassName="light w-full" text="Autentificare" />
				</Form>

				<div className="text-center my-4 text-secondary">sau conectează-te cu</div>

				<GoogleLoginButton onClose={onClose} className="mb-1.5" />
				<FacebookLoginButton onClose={onClose} className="mb-1.5" />

				<Link to="/forgot-password" className="text-sm mt-5 block text-secondary text-center">
					Ai uitat parola?
				</Link>
			</div>
		</>
	);
}
