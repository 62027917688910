import ChatContext from 'context/ChatContext';
import { useContext, useEffect, useRef, useState } from 'react';

// TODO: bug here when logging out, the title does not change
export default function UnseenMessagesBadge() {
	const { chats } = useContext(ChatContext);
	const [unseen, setUnseen] = useState(0);
	const originalTitle = useRef(document.title);

	// Count total unseen messages every time chats change
	useEffect(() => {
		const unseenMessages = chats.reduce((total, chat) => (total += chat.unseenMessages), 0);

		// update browser window title
		document.title = originalTitle.current;
		if (unseenMessages) {
			document.title = `(${unseenMessages}) ${document.title}`;
		}

		setUnseen(unseenMessages);
	}, [chats]);

	if (!unseen) return;

	return <div className="navbar__badge">{unseen}</div>;
}
