import ContentLoader from 'react-content-loader';

export function PublicProfileSkeleton({ className }) {
	return (
		<ContentLoader
			speed={2}
			width="100%"
			style={{ maxWidth: '600px' }}
			viewBox="0 0 600 180"
			backgroundColor="#f2f2f2"
			foregroundColor="#ffffff"
			className={className}
		>
			<circle cx="75" cy="75" r="75" />
			<rect x="180" y="18" rx="3" ry="3" width="88" height="10" />
			<rect x="180" y="36" rx="3" ry="3" width="52" height="10" />
			<rect x="180" y="66" rx="3" ry="3" width="410" height="10" />
			<rect x="180" y="82" rx="3" ry="3" width="380" height="10" />
			<rect x="180" y="98" rx="3" ry="3" width="178" height="10" />
		</ContentLoader>
	);
}

export function ChatImageSkeleton() {
	return (
		<ContentLoader
			speed={2}
			width={200}
			height={200}
			viewBox="0 0 200 200"
			backgroundColor="#f4f4f4"
			foregroundColor="#fff"
		>
			<rect x="0" y="0" rx="20" ry="20" width="200" height="200" />
		</ContentLoader>
	);
}

export function ProductCardSkeleton({ className = '' }) {
	return (
		<div className={`product-card late-fade-in !p-0 ${className}`}>
			<ContentLoader
				speed={2}
				width="100%"
				viewBox="0 0 230 320"
				backgroundColor="#f2f2f2"
				foregroundColor="#fff"
			>
				<rect x="8" y="8" rx="8" ry="8" width="214" height="214" />
				<rect x="8" y="234" rx="5" ry="5" width="214" height="12" />
				<rect x="8" y="290" rx="5" ry="5" width="50" height="20" />
				<rect x="170" y="290" rx="5" ry="5" width="50" height="20" />
			</ContentLoader>
		</div>
	);
}

export function ProductCoverImageSkeleton() {
	return (
		<ContentLoader
			speed={2}
			width="100%"
			viewBox="0 0 638 638"
			backgroundColor="#f4f4f4"
			foregroundColor="#fff"
		>
			<rect x="0" y="0" rx="15" ry="15" width="638" height="638" />
		</ContentLoader>
	);
}

export function PersonalizationImagePreviewSkeleton() {
	return (
		<ContentLoader
			speed={2}
			width={112}
			heiught={112}
			viewBox="0 0 112 112"
			backgroundColor="#f4f4f4"
			foregroundColor="#fff"
		>
			<rect x="0" y="0" rx="15" ry="15" width="112" height="112" />
		</ContentLoader>
	);
}

export function ArtistCardSkeleton({ className = '' }) {
	return (
		<div className={`artist-card late-fade-in !px-0 ${className}`}>
			<ContentLoader
				speed={2}
				width="100%"
				viewBox="0 0 240 168"
				backgroundColor="#f2f2f2"
				foregroundColor="#ffffff"
			>
				<circle cx="120" cy="30" r="30" />
				<rect x="50" y="75" rx="6" ry="6" width="140" height="15" />
				<rect x="35" y="100" rx="6" ry="6" width="170" height="15" />
				<rect x="35" y="135" rx="5" ry="5" width="80" height="25" />
				<rect x="125" y="135" rx="5" ry="5" width="80" height="25" />
			</ContentLoader>
		</div>
	);
}
