import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useGrayBackground() {
	const location = useLocation();

	useEffect(() => {
		document.body.classList.add('bg-gray');
		return () => {
			document.body.classList.remove('bg-gray');
		};
	}, [location]);
}
