import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import usePageTitle from './usePageTitle';

export default function useOutletTitle(title) {
	const [setTitle] = useOutletContext();
	usePageTitle(title + ' - Custom Studio');

	useEffect(() => {
		setTitle(title);
	}, [title]);
}
