class Notifier {
	supportsNotification() {
		return 'Notification' in window;
	}

	getPermission() {
		if (!this.supportsNotification()) return null;
		return Notification.permission;
	}

	showNotification(options) {
		const permission = this.getPermission();

		if (permission === 'granted') {
			this._showNotification(options);
		} else if (permission === 'default') {
			this._requestAndShowPermission(options);
		}
	}

	requestPermission() {
		if (!this.supportsNotification()) return;
		if (Notification.permission !== 'granted') {
			Notification.requestPermission();
		}
	}

	hasPermission() {
		return this.getPermission() === 'granted';
	}

	_showNotification({ title, body, icon }) {
		if (!this.supportsNotification()) return;
		if (document.visibilityState === 'visible') return;

		const notification = new Notification(title, { body, icon });

		notification.onclick = () => {
			notification.close();
			window.parent.focus();
		};
	}

	_requestAndShowPermission(options) {
		if (!this.supportsNotification()) return;
		Notification.requestPermission((permission) => {
			if (permission === 'granted') {
				this._showNotification(options);
			}
		});
	}
}

export default new Notifier();
