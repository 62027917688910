import { FaDiscord } from 'react-icons/fa';
import { CommunityIcon, Wave } from '../../icons';
import { DISCORD } from '../../constants';

export default function JoinDiscord({ isMobile }) {
	return (
		<div>
			<Wave className="-mb-[2px]" />

			<div className="bg-[#5865F2] flex justify-center pt-8 lg:pt-0 px-8">
				{!isMobile && (
					<div className="hidden lg:flex items-end mr-20">
						<CommunityIcon />
					</div>
				)}
				<div className="text-white">
					<div className="join-our-discord-title system-font">
						Alătură-te comunității noastre de Discord!
					</div>
					<div className="text-start flex flex-col text-sm md:text-base">
						<div className="mb-1">• Fii la curent cu ultimele noutăți. </div>
						<div className="mb-1">• Implică-te în procesul de dezvoltare a platformei.</div>
						<div>• Află sfaturi de început de la ceilalți artiști.</div>
					</div>
					<div className="flex items-center md:justify-start mt-8 mb-7">
						<a href={DISCORD} target="_blank" rel="noreferrer" title="join discord">
							<FaDiscord className="text-[60px] mr-6" />
						</a>
						<a href={DISCORD} target="_blank" rel="noreferrer" title="join discord">
							<div className="join-discord-button">Join Server</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
