import UserContext from 'context/UserContext';
import { useContext, useState } from 'react';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';

export default function ProductHeart({ productId, className }) {
	const { likedProducts, setProductLike } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const isLiked = likedProducts.includes(productId);

	const onChangeLike = async (e) => {
		e.preventDefault();
		if (loading) return;
		setLoading(true);
		await setProductLike(productId, !isLiked);
		setLoading(false);
	};

	return (
		<div className={`product-heart ${className}`} onClick={onChangeLike}>
			{!isLiked && <RiHeartLine size={22} className=" text-tertiary" />}
			{isLiked && <RiHeartFill size={22} className=" text-red" />}
		</div>
	);
}
