import { highlightProduct } from 'api/productApi';
import { useState } from 'react';
import { RiStarFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

export default function ProductHighlight({ product }) {
	const [loading, setLoading] = useState(false);
	const [highlight, setHighlight] = useState(product.highlight);

	const onChangeHighlight = async (e) => {
		e.preventDefault();
		if (loading) return;
		setLoading(true);
		const res = await highlightProduct(product._id, !highlight);
		if (res.error) {
			toast.error(res.error);
		} else {
			setHighlight(!highlight);
		}
		setLoading(false);
	};

	return (
		<div className={`product-heart !left-2 !top-2 !right-auto`} onClick={onChangeHighlight}>
			{!highlight && <RiStarFill size={22} className=" text-tertiary" />}
			{highlight && <RiStarFill size={22} className=" text-yellow" />}
		</div>
	);
}
