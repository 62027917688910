import { Bag, Candle, Decor, Jewelry, Painting, RockingHorse, Shirt, Sneakers, Things } from 'icons';

import normalizeStringLanguage from './normalizeStringLanguage';

export function isTouchDevice() {
	return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

export function hasDuplicates(array) {
	return new Set(array).size !== array.length;
}

export function removeDuplicates(array) {
	return [...new Set(array)];
}

export function arrayIntersection(a, b) {
	return a.filter((val) => b.includes(val));
}

/**
 * Tries to read an int from a string.
 * Can also return '+', '-' or ''.
 * @param {string} str
 * @returns {string|number|NaN}
 */
export function readPartialInt(str) {
	str = str.replace(/[^0-9+-]/g, ''); // allow only digits and + and - signs
	if (str === '+' || str === '-' || str === '') {
		return str;
	}
	return parseInt(str);
}

export function getAllArtists(cartOrOrder) {
	return cartOrOrder.subOrders.map((subOrder) => subOrder.artist);
}

export function getAllProducts(cartOrOrder) {
	return cartOrOrder.subOrders
		.map((subOrder) => subOrder.cartItems)
		.flat()
		.map((item) => item.product);
}

export function oneOrMore(value, ifMore = '', ifLess = '') {
	return value === 1 ? ifLess : ifMore;
}

export function getCategoryIcon(category) {
	switch (category) {
		case 'Decor':
			return Decor;
		case 'Accesorii':
			return Bag;
		case 'Bijuterii':
			return Jewelry;
		case 'Tablouri':
			return Painting;
		case 'Haine':
			return Shirt;
		case 'Sneakers':
			return Sneakers;
		case 'Jucării':
			return RockingHorse;
		case 'Lumânări':
			return Candle;
		case 'Diverse':
			return Things;
		default:
			return null;
	}
}

export function isBucharest(city) {
	city = normalizeStringLanguage(city).toLowerCase();
	return city.includes('bucuresti') || city.includes('bucharest');
}

export function isInAppBrowser() {
	const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv)'];
	const regex = new RegExp(`(${rules.join('|')})`, 'ig');
	return navigator.userAgent.match(regex);
}

export function addDaysToDate(date, days) {
	const copy = new Date(date);
	copy.setDate(copy.getDate() + days);
	return copy;
}

export function endOfDay(date) {
	const copy = new Date(date);
	copy.setHours(23, 59, 59);
	return copy;
}

export function pick(obj, keys) {
	const result = {};
	for (const key of keys.split(' ')) {
		result[key] = obj[key];
	}
	return result;
}
