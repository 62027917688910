import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { InputSelect } from './Input';
import { formatNumber } from 'utils/format';
import ReactGA from 'react-ga4';
import UserContext from 'context/UserContext';
import LoadingSpinner from './LoadingSpinner';
import PersonalizationModal from './modals/PersonalizationModal';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';

export default function CartItem({ item, isInCart, reviewToken }) {
	const { updateCartItemQuantity } = useContext(UserContext);
	const [updating, setUpdating] = useState(false);
	const [showPersonalizationModal, setShowPersonalizationModal] = useState(false);
	const product = item.product || item;
	const quantitiesArray = useMemo(() => (isInCart ? [...Array(product.stock + 1).keys()] : []), [isInCart]);
	const selectedOptionsPriceChange = item.selectedOptionsPriceChange || 0;

	const renderedDetails = (
		<>
			<div className="mb-1 text-secondary">
				Livrare estimată: {product.estimatedDelivery.minDays} - {product.estimatedDelivery.maxDays}{' '}
				zile
			</div>
			{(item.selectedOptions.length > 0 ||
				item.personalizationText ||
				item.personalizationImages?.length > 0) && (
				<div
					className="link inline-block"
					onClick={() => {
						setShowPersonalizationModal(true);
					}}
				>
					Vezi opțiuni și personalizare
				</div>
			)}
			{reviewToken && (
				<div className="link">
					<a
						href={`${window.location.origin}/products/${product.slug}?reviewToken=${reviewToken}`}
						rel="noreferrer"
						target="_blank"
					>
						Link de recenzie pentru client
					</a>
				</div>
			)}
		</>
	);

	const onChangeQuantity = async (quantity) => {
		ReactGA.event({ action: 'Change item quantity', category: 'Cart' });
		setUpdating(true);
		await updateCartItemQuantity(item._id || item.id, quantity);
		setUpdating(false);
	};

	return (
		<div>
			<div className="flex">
				<Link
					to={`/products/${product.slug}`}
					className="w-24 h-24 lg:w-32 lg:h-32 shrink-0 mr-3 lg:mr-4 bg-eee rounded-lg"
				>
					<img src={product.thumbnail} className="w-full rounded-lg" alt={product.title} />
				</Link>
				<div className="flex flex-col md:flex-row w-full">
					<div className="w-full">
						<Link
							to={`/products/${product.slug}`}
							className="font-semibold mb-1 line-clamp-2 text-md md:text-[16px] w-fit"
						>
							{[product.title, ...item.selectedOptions.map((o) => o.value)].join(', ')}
						</Link>

						<div className="hidden md:block">{renderedDetails}</div>
					</div>

					<div className="flex items-center justify-between mt-2 md:flex-col md:items-end md:justify-start shrink-0 md:ml-2 md:mt-0">
						<span className="font-semibold md:text-lg mr-4 md:mb-2 md:mr-0">
							{formatNumber(product.price + selectedOptionsPriceChange)} Lei
						</span>

						{isInCart ? (
							updating ? (
								<LoadingSpinner className="text-secondary mx-2 my-1" size={23} />
							) : (
								<div className="flex items-center">
									<div
										className="change-cart-item-quantity"
										onClick={() => onChangeQuantity(item.quantity - 1)}
									>
										<RiSubtractLine />
									</div>
									<InputSelect
										id={`select-quantity-${item._id || item.id}`}
										onSelect={onChangeQuantity}
										selectedOption={item.quantity}
										options={quantitiesArray}
										containerClassName="!mb-0 mx-2"
										size="small"
									/>
									<div
										className="change-cart-item-quantity"
										onClick={() => onChangeQuantity(item.quantity + 1)}
									>
										<RiAddLine />
									</div>
								</div>
							)
						) : (
							<div className="text-md text-secondary">{item.quantity} buc</div>
						)}
					</div>
				</div>
			</div>

			<div className="md:hidden my-4">{renderedDetails}</div>

			{showPersonalizationModal && (
				<PersonalizationModal
					selectedOptions={item.selectedOptions}
					personalizationText={item.personalizationText}
					personalizationImages={item.personalizationImages}
					onRequestClose={() => setShowPersonalizationModal(false)}
				/>
			)}

			<hr className="border-eee my-4" />
		</div>
	);
}
