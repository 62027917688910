import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RiAddLine, RiDeleteBin2Line } from 'react-icons/ri';
import { MAX_PHOTO_MB_SIZE, MAX_PHOTO_SIZE } from 'custom-studio-constants';
import LoadingSpinner from './LoadingSpinner';
import UIModal from './modals/UIModal';
import Button from './Button';
import { captureException } from '@sentry/react';

const placeholderId = 'image-placeholder';

export default function ImagePicker({ className = '', images, setImages, contain = false }) {
	const [previewImage, setPreviewImage] = useState(null);
	const [loadingImages, setLoadingImages] = useState(0);
	const [draggingEl, setDraggingEl] = useState(null);
	const [dragging, setDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);

	const onAddImages = (e) => {
		const files = e.target.files;
		if (!files) return;

		for (const file of files) {
			if (file.size > MAX_PHOTO_SIZE) {
				toast.error(`O imagine depășește ${MAX_PHOTO_MB_SIZE} MB.`);
				continue;
			}
			if (!file.type.startsWith('image/')) {
				toast.error('Sunt permise doar imagini.');
				continue;
			}

			try {
				const fileReader = new FileReader();
				fileReader.onloadend = (e) => {
					setImages((prev) => [
						...prev,
						{
							file,
							id: `${file.name}-${Date.now()}`,
							data: e.target.result,
						},
					]);
					setLoadingImages((prev) => prev - 1);
				};
				setLoadingImages((prev) => prev + 1);
				fileReader.readAsDataURL(file);
			} catch (err) {
				console.error(err);
				toast.error('O imagine selectată este invalidă.');
				captureException(err);
			}
		}
	};

	const onRemoveImage = (id) => {
		setImages((prev) => prev.filter((img) => img.id !== id));
	};

	const onDrag = useCallback(
		(e) => {
			if (e.touches && e.touches[0]) {
				e = e.touches[0];
			}

			if (!dragging) {
				setDragging(true);
			}

			draggingEl.style.top = `${e.pageY - startY + window.scrollY}px`;
			draggingEl.style.left = `${e.pageX - startX}px`;

			let placeholderIndex = images.findIndex((img) => img.id === placeholderId);

			for (let i = 0; i < images.length; ++i) {
				const img = images[i];
				if (img.id === placeholderId) continue;
				if (img.id === draggingEl.id) continue;

				const div = document.getElementById(img.id);

				if (elementsOverlapping(draggingEl, div)) {
					const newImages = [...images];
					if (placeholderIndex > i) {
						while (placeholderIndex !== i) {
							swap(newImages, placeholderIndex, placeholderIndex - 1);
							swap(newImages, placeholderIndex + 1, placeholderIndex);
							placeholderIndex--;
						}
						setImages(newImages);
					} else {
						while (placeholderIndex !== i - 1) {
							swap(newImages, placeholderIndex + 1, placeholderIndex + 2);
							swap(newImages, placeholderIndex, placeholderIndex + 1);
							placeholderIndex++;
						}
						setImages(newImages);
					}

					break;
				}
			}
		},
		[draggingEl, images, startX, startY, dragging]
	);

	const onDragEnd = useCallback(
		(e) => {
			if (e.touches) {
				document.body.style.overflow = 'unset';
			}

			// Remove the position styles
			draggingEl.style.removeProperty('top');
			draggingEl.style.removeProperty('left');
			draggingEl.style.removeProperty('position');

			setDraggingEl(null);
			setStartX(0);
			setStartY(0);
			setImages((prev) => prev.filter((img) => img.id !== placeholderId));
		},
		[draggingEl, onDrag]
	);

	const onDragStart = useCallback((e) => {
		const draggingEl = e.currentTarget;
		const rect = draggingEl.getBoundingClientRect();

		if (e.touches && e.touches[0]) {
			e = e.touches[0];
			document.body.style.overflow = 'hidden';
		}

		const startX = e.pageX - rect.left;
		const startY = e.pageY - rect.top;
		setStartX(startX);
		setStartY(startY);

		draggingEl.style.position = 'absolute';
		draggingEl.style.top = `${e.pageY - startY + window.scrollY}px`;
		draggingEl.style.left = `${e.pageX - startX}px`;

		// add placeholder
		setImages((prev) => {
			const id = draggingEl.id;
			const index = prev.findIndex((img) => img.id === id);
			return [...prev.slice(0, index), { id: placeholderId }, ...prev.slice(index)];
		});

		setDraggingEl(draggingEl);
	}, []);

	useEffect(() => {
		if (draggingEl) {
			document.addEventListener('mousemove', onDrag);
			document.addEventListener('mouseup', onDragEnd);
			document.addEventListener('touchmove', onDrag);
			document.addEventListener('touchend', onDragEnd);
			return () => {
				document.removeEventListener('mousemove', onDrag);
				document.removeEventListener('mouseup', onDragEnd);
				document.removeEventListener('touchmove', onDrag);
				document.removeEventListener('touchend', onDragEnd);
			};
		} else {
			if (dragging) {
				setDragging(false);
			}
		}
	}, [draggingEl, onDrag, onDragEnd]);

	return (
		<div className={`flex flex-wrap ${className}`}>
			<input
				id="add-product-image"
				type="file"
				accept=".png,.jpg,.jpeg"
				hidden
				multiple
				value=""
				readOnly
				onChange={onAddImages}
			/>
			<label htmlFor="add-product-image" className="add-product-image-btn">
				{!!loadingImages && <LoadingSpinner size={28} />}
				{!loadingImages && (
					<>
						<RiAddLine size={28} />
						<p className="text-sm">Imagine</p>
					</>
				)}
			</label>
			{images.map((img) =>
				img.id === placeholderId ? (
					<div
						key={img.id}
						className="mr-1.5 mb-1.5 product-image-preview bg-gray border-2 border-dotted border-[#ddd]"
					/>
				) : (
					<div
						id={img.id}
						key={img.id}
						className="mr-1.5 mb-1.5 cursor-pointer select-none active:cursor-move"
						onMouseDown={onDragStart}
						onTouchStart={onDragStart}
						onClick={() => {
							if (dragging) return;
							setPreviewImage(img);
						}}
					>
						<img
							src={img.data}
							className={`product-image-preview ${contain ? 'contain' : ''}`}
							draggable={false}
						/>
					</div>
				)
			)}

			{previewImage && (
				<UIModal
					name="image-preview"
					onRequestClose={() => setPreviewImage(null)}
					closeOnContainerClick
					className="image-picker-preview-modal"
				>
					<img src={previewImage.data} className={contain ? 'contain' : ''} />

					<Button
						containerClassName="image-preview-delete-button"
						text="Șterge"
						icon={<RiDeleteBin2Line className="mr-2" size={20} />}
						onClick={() => {
							onRemoveImage(previewImage.id);
							setPreviewImage(null);
						}}
					/>
				</UIModal>
			)}
		</div>
	);
}

function elementsOverlapping(a, b) {
	const rectA = a.getBoundingClientRect();
	const rectB = b.getBoundingClientRect();

	const x1 = rectA.left + rectA.width / 2;
	const y1 = rectA.top + rectA.height / 2;

	const x2 = rectB.left + rectB.width / 2;
	const y2 = rectB.top + rectB.height / 2;

	return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2) <= 42;
}

function swap(arr, i, j) {
	const temp = arr[i];
	arr[i] = arr[j];
	arr[j] = temp;
}
