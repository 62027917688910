import { Courier, OrderStatus } from 'custom-studio-constants';
import { InputText, RadioBox } from './Input';

import AskForInvoiceAlert from './AskForInvoiceAlert';
import Button from './Button';
import UIModal from './modals/UIModal';
import { changeSubOrderStatus } from 'api/orderApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const OrderConfirmationType = {
	AcceptOrder: 'accept',
	ShipOrder: 'shipped',
	FinishOrder: 'finish',
	CancelOrder: 'cancel',
};

export default function OrderStatusConfirmationModal({
	type,
	onRequestClose,
	orderId,
	artistId,
	shouldAskForInvoice,
	subOrderTotal,
}) {
	const navigate = useNavigate();
	const [awb, setAwb] = useState('');
	const [courier, setCourier] = useState(null);

	let theme = 'blue';
	let buttonStyle = 'primary';

	if (type === OrderConfirmationType.CancelOrder) {
		theme = 'red';
		buttonStyle = 'danger';
	} else if (type === OrderConfirmationType.FinishOrder) {
		theme = 'green';
		buttonStyle = 'success';
	}

	const changeStatus = async (newStatus) => {
		const res = await changeSubOrderStatus(orderId, newStatus, artistId, courier, awb);

		if (res.error) {
			toast.error(res.error);
		} else {
			onRequestClose();
			navigate(-1);
			toast.success(res.message);
		}
	};

	const onConfirm = () => {
		if (type === OrderConfirmationType.AcceptOrder) {
			changeStatus(OrderStatus.Processing);
		} else if (type === OrderConfirmationType.ShipOrder) {
			if (!courier) {
				return toast.error('Bifează curierul ales.');
			}
			changeStatus(OrderStatus.Shipped);
		} else if (type === OrderConfirmationType.FinishOrder) {
			changeStatus(OrderStatus.Finalized);
		} else if (type === OrderConfirmationType.CancelOrder) {
			changeStatus(OrderStatus.Cancelled);
		}
	};

	return (
		<UIModal name="order-status-change" className="w-full max-w-[350px]" onRequestClose={onRequestClose}>
			<div className={`py-4 bg-${theme} text-white`}>
				<h3 className="text-center font-semibold">Ești sigur?</h3>
			</div>
			<div className="px-5 py-4">
				{type === OrderConfirmationType.AcceptOrder && (
					<p className="text-center">
						Clientul va fi anunțat că ai acceptat comanda și te-ai apucat de treabă.
					</p>
				)}

				{type === OrderConfirmationType.ShipOrder && (
					<div className="">
						<p className="text-center mb-4">Clientul va fi anunțat că ai expediat comanda.</p>

						<p className="mb-1 font-semibold">Curier:</p>

						{Object.keys(Courier).map((key) => (
							<RadioBox
								key={key}
								name="courier"
								label={Courier[key]}
								checked={courier === key}
								onCheck={() => setCourier(key)}
							/>
						))}

						<InputText
							className="mt-4"
							placeHolder="AWB (cod de urmărire)"
							value={awb}
							onChange={(e) => setAwb(e.target.value)}
						/>
					</div>
				)}

				{type === OrderConfirmationType.FinishOrder && (
					<>
						<p className="text-center">
							Clientul a primit comanda?
							<br />
							(va fi rugat să lase o recenzie)
						</p>
						{shouldAskForInvoice && <AskForInvoiceAlert subOrderTotal={subOrderTotal} />}
					</>
				)}

				{type === OrderConfirmationType.CancelOrder && (
					<p className="text-center">
						Vrei să anulezi comanda? Dacă plata a fost făcută online, clientul va primi banii
						înapoi.
					</p>
				)}

				<div className="flex justify-between mt-5">
					<Button containerClassName="light" text="Nu" onClick={onRequestClose} />
					<Button containerClassName={buttonStyle} text="Da" onClick={onConfirm} />
				</div>
			</div>
		</UIModal>
	);
}
