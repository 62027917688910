import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getGivenReviews, getProfile, getUserPublicProducts, getArtistReviews } from '../api/userApi';
import { ProductCardSkeleton } from 'utils/skeletons';
import ProductCard from '../components/ProductCard';
import WhiteContainer from '../components/WhiteContainer';
import useGrayBackground from '../hooks/useGrayBackground';
import useScrollToTop from '../hooks/useScrollToTop';
import ProfileSection from '../components/ProfileSection';
import ReviewsContainer from '../components/ReviewsContainer';
import isArtist from 'utils/isArtist';
import usePageTitle from 'hooks/usePageTitle';
import AdminUserActions from './admin/AdminUserActions';

export default function PublicProfile() {
	const { key } = useLocation();
	return <_PublicProfile key={key} />;
}

/**
 * This page can be reached in two ways:
 * (1) /profile/userId
 * (2) /artist/username
 *
 * If the user is an artist, but the page is loaded with option (1),
 * the page automatically navigates to option (2).
 */
function _PublicProfile() {
	useGrayBackground();
	useScrollToTop();
	const location = useLocation();
	const navigate = useNavigate();
	const { idOrUsername } = useParams();
	const [profile, setProfile] = useState(null);
	const [products, setProducts] = useState(null);
	const [reviewsData, setReviewsData] = useState(null);

	usePageTitle(profile?.displayName && `${profile.displayName} - Custom Studio`);

	useEffect(() => {
		// fetch profile
		(async () => {
			const res = await getProfile(idOrUsername);
			if (res.error) {
				toast.error(res.error);
				navigate('/artists');
			} else {
				setProfile(res);
			}
		})();
	}, [idOrUsername]);

	useEffect(() => {
		if (!isArtist(profile)) return;

		// fetch products
		(async () => {
			const res = await getUserPublicProducts(profile._id);
			if (res.error) return toast.error(res.error);
			setProducts(res);
		})();

		// navigate to artist's url
		if (location.pathname.includes('/profile/') || location.pathname.includes(profile._id)) {
			navigate(`/artist/${profile.username}`, { replace: true });
		}
	}, [profile]);

	// setup reviews data
	useEffect(() => {
		if (profile) {
			const data = [];

			if (isArtist(profile)) {
				data.push({
					label: 'Recenzii primite',
					reviewsCount: profile.receivedReviews,
					getReviews: getArtistReviews.bind(null, profile._id),
				});
			}

			data.push({
				label: 'Recenzii acordate',
				reviewsCount: profile.givenReviews,
				getReviews: getGivenReviews.bind(null, profile._id),
			});

			setReviewsData(data);
		}
	}, [profile]);

	const renderProducts = () => {
		if (!products) {
			return (
				<div className="product-cards-swiper flex overflow-hidden">
					<ProductCardSkeleton className="mr-5 late-fade-in" />
					<ProductCardSkeleton className="mr-5 late-fade-in" />
				</div>
			);
		}
		if (products.length === 0) {
			return (
				<div className="flex items-center justify-center h-[200px] bg-gray rounded-lg">
					<p className="text-secondary">Nicio creație momentan 😔</p>
				</div>
			);
		}
		return (
			<div className="products-grid bg-gray pt-5 flex flex-wrap justify-around -mx-4 px-1 md:rounded-xl">
				{products.reverse().map((p) => (
					<ProductCard key={p._id} product={p} className="mb-5 mx-1" />
				))}
			</div>
		);
	};

	const renderProductSection = () => {
		if (!isArtist(profile)) return;
		return (
			<div className="mt-8">
				<div>
					<div className="text-lg font-semibold mb-3">Produse</div>
					<div className="text-sm flex mb-5">
						{profile.categories.map((cat) => (
							<div
								key={cat}
								className="light w-max py-1 px-4 rounded-md mr-3 font-semibold cursor-pointer"
							>
								{cat}
							</div>
						))}
					</div>
				</div>

				{renderProducts()}
			</div>
		);
	};

	const renderProductsAndReviews = () => {
		if (!profile) return;
		return (
			<>
				{renderProductSection()}

				{reviewsData && <ReviewsContainer data={reviewsData} className="mt-12" />}
			</>
		);
	};

	return (
		<div className="container !px-0 mb-24">
			<AdminUserActions profile={profile} />

			<WhiteContainer className="p-4 my-5 md:p-10">
				<ProfileSection profile={profile} />

				{renderProductsAndReviews()}
			</WhiteContainer>
		</div>
	);
}
