import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';
import { getCategoryIcon } from 'utils/utils';

export default function CategoryLink({ children, category, className = '', onClick }) {
	const Icon = useMemo(() => getCategoryIcon(category), [category]);

	return (
		<Link to={`/products/category/${category}`}>
			<div className={`category-button ${className}`} onClick={onClick}>
				<div className="flex items-center">
					<Icon className="w-5 mr-3" />
					{category}
				</div>
				<RiArrowRightSLine size={20} className="text-tertiary -mr-1" />
			</div>
		</Link>
	);
}
