import LoadingSpinner from './LoadingSpinner';

export default function Button({
	type = 'button',
	containerClassName = '',
	className = '',
	size = 'default',
	text,
	onClick,
	icon,
	iconRight,
	loading = false,
	...restProps
}) {
	if (size === 'small') {
		containerClassName += ' !py-1 !px-3 !text-md !rounded-sm';
	}

	const onClickProxy = (e) => {
		if (loading) {
			e.preventDefault();
			return;
		}
		if (onClick) {
			onClick(e);
		}
	};

	return (
		<button type={type} className={`btn ${containerClassName}`} onClick={onClickProxy} {...restProps}>
			{loading && (
				<div className="loading-spinner-container">
					<LoadingSpinner size={24} />
				</div>
			)}
			<div className={`flex items-center justify-center ${loading ? 'opacity-0' : ''} ${className}`}>
				{!iconRight && icon}
				{text}
				{iconRight && icon}
			</div>
		</button>
	);
}
