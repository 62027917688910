export default function AddressWrapper({ className = '', address, compact }) {
	if (!address) return;
	return (
		<div className={`${className}`}>
			<p>
				{address.city}, {address.county}
				{compact && `, ${address.address}`}
				{compact && `, ${address.phone}`}
			</p>
			{!compact && <p>{address.address}</p>}
			{!compact && <p>{address.phone}</p>}
		</div>
	);
}
