import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PaymentMethod, isTempEmail } from 'custom-studio-constants';
import { InputText, RadioBox } from 'components/Input';
import { formatNumber, formatShippingCost } from 'utils/format';
import { getAllProducts } from 'utils/utils';
import {
	RiVisaLine,
	RiMastercardFill,
	RiInboxUnarchiveFill,
	RiUserLine,
	RiMapPin2Line,
	RiDonutChartLine,
	RiHandCoinLine,
	RiBankCardFill,
} from 'react-icons/ri';
import ReactGA from 'react-ga4';
import Button from 'components/Button';
import WhiteContainer from 'components/WhiteContainer';
import UserContext from 'context/UserContext';
import useGrayBackground from 'hooks/useGrayBackground';
import useScrollToTop from 'hooks/useScrollToTop';
import Form from 'components/Form';
import LoginPanel from 'components/login/LoginPanel';
import { order } from 'api/orderApi';
import { toast } from 'react-toastify';
import { captureMessage } from '@sentry/react';

function Container({ children, className }) {
	return <WhiteContainer className={`p-5 sm:p-7 ${className}`}>{children}</WhiteContainer>;
}

export default function CheckOut() {
	useScrollToTop();
	useGrayBackground();
	const navigate = useNavigate();
	const { user, cart, checkSession, clearCart } = useContext(UserContext);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Online);

	const totalCashOnDeliveryExtraCost = useMemo(
		() => cart.subOrders.reduce((acc, so) => acc + so.artist.cashOnDeliveryExtraCost, 0),
		[cart]
	);

	// Find which products don't accept cash on delivery payment
	const nonCashOnDeliveryProducts = useMemo(
		() => getAllProducts(cart).filter((p) => !p.acceptCashOnDelivery),
		[cart]
	);

	const shippingCost = useMemo(
		() =>
			paymentMethod === PaymentMethod.CashOnDelivery
				? cart.shippingCost + totalCashOnDeliveryExtraCost
				: cart.shippingCost,
		[cart, paymentMethod]
	);

	const total = useMemo(
		() =>
			paymentMethod === PaymentMethod.CashOnDelivery
				? cart.total + totalCashOnDeliveryExtraCost
				: cart.total,
		[cart, paymentMethod]
	);

	// Creates order from user's cart and redirects to Stripe
	const onOrder = async (formData) => {
		const { fullName, email } = Object.fromEntries(formData);

		if (fullName.trim().split(' ').length < 2) {
			return toast.error('Te rog să introduci numele complet.');
		}

		const res = await order({ formData, paymentMethod, cart: !user && cart });

		if (res.error) {
			toast.error(res.error);
		} else {
			ReactGA.event({ action: 'Place Order', category: 'Cart' });
			window.ttq.track('PlaceAnOrder', { value: total, currency: 'RON', email });
			window.fbq('track', 'Purchase', { value: total, currency: 'RON' });

			clearCart();

			if (cart.total === 0 || paymentMethod === PaymentMethod.CashOnDelivery) {
				if (res.userHasBeenLoggedIn) {
					await checkSession();
				}
				navigate('/order-placed');
			} else {
				if (res.stripeCheckoutUrl) {
					window.location = res.stripeCheckoutUrl;
				} else {
					toast.error('Eroare! Vă rugăm să ne contactați.');
					captureMessage('No stripeCheckoutUrl in response!', { extra: { res } });
				}
			}
		}
	};

	useEffect(() => {
		ReactGA.event({ action: 'Initiate Checkout', category: 'Cart' });
		window.ttq.track('InitiateCheckout', { email: user?.email });
		if (!cart.isLoaded) {
			navigate('/cart', { replace: true });
		}
	}, []);

	if (!cart.isLoaded) {
		return;
	}

	return (
		<>
			{showLoginModal && <LoginPanel onRequestClose={() => setShowLoginModal(false)} />}

			<Form onSubmit={onOrder} className="container fade-in">
				<h3 className="my-5 md:mt-8 text-dark-secondary text-center">Finalizare comandă</h3>

				<div className="flex flex-wrap gap-4 flex-col md:flex-row justify-center">
					<Container className="flex-1">
						<div className="flex items-center justify-between">
							<div className="flex items-center text-xl">
								<RiUserLine className="mr-2 mb-1" /> Contact
							</div>
							{!user && (
								<span className="text-secondary text-md md:text-base">
									Ai un cont?{' '}
									<span className="link" onClick={() => setShowLoginModal(true)}>
										Conectează-te
									</span>
								</span>
							)}
						</div>

						<hr className="border-eee my-3" />

						<div className="flex flex-wrap gap-4">
							<InputText
								label="Email"
								name="email"
								defaultValue={user?.email && (isTempEmail(user.email) ? '' : user.email)}
								type="email"
								containerClassName="max-w-[300px] !mb-0"
								required
								disabled={user?.email && !isTempEmail(user.email)}
							/>
							<InputText
								label="Nume"
								name="fullName"
								defaultValue={user?.fullName}
								containerClassName="max-w-[250px]"
								required
							/>
						</div>

						<div className="flex items-center mt-5 md:mt-8 text-xl">
							<RiMapPin2Line className="mr-2 mb-1" /> Adresă de livrare
						</div>
						<hr className="border-eee my-3" />
						<div className="flex flex-wrap gap-4">
							<InputText
								name="city"
								label="Localitate"
								defaultValue={user?.address?.city}
								containerClassName="max-w-[200px] !mb-0"
								required
							/>
							<InputText
								name="county"
								label="Județ / Sector"
								defaultValue={user?.address?.county}
								containerClassName="max-w-[200px]"
								required
							/>
						</div>

						<InputText
							name="address"
							label="Adresă"
							defaultValue={user?.address?.address}
							placeHolder="Stradă și număr, etc."
							required
						/>

						<InputText
							name="phone"
							type="tel"
							defaultValue={user?.address?.phone}
							label="Telefon"
							containerClassName="max-w-[200px]"
							required
						/>
					</Container>

					{/* Payment method and summary */}
					<div className="md:max-w-[300px] lg:max-w-[320px]">
						<Container className="w-full">
							{nonCashOnDeliveryProducts.length === 0 && (
								<div className="mb-8">
									<div className="flex items-center text-xl">
										<RiHandCoinLine className="mr-2" />
										Metoda de plată
									</div>
									<hr className="border-eee my-3" />
									<RadioBox
										name="payment-method"
										label="Card online"
										className="mb-3"
										checked={paymentMethod === PaymentMethod.Online}
										onCheck={() => setPaymentMethod(PaymentMethod.Online)}
									/>
									<RadioBox
										name="payment-method"
										label={
											<span>
												Numerar la livrare
												{totalCashOnDeliveryExtraCost > 0 && (
													<span className="text-secondary ml-2">
														(+{totalCashOnDeliveryExtraCost} lei)
													</span>
												)}
											</span>
										}
										checked={paymentMethod === PaymentMethod.CashOnDelivery}
										onCheck={() => setPaymentMethod(PaymentMethod.CashOnDelivery)}
									/>
								</div>
							)}

							<div className="flex items-center text-xl">
								<RiDonutChartLine className="mr-2" />
								Sumar comandă
							</div>
							<hr className="border-eee my-3" />
							<div className="flex justify-between text-secondary">
								<div>
									<div>Cost produse</div>
									<div>Cost livrare</div>
									{!!cart.discount && <div className="text-green">Cod reducere</div>}
								</div>
								<div className="items-end flex flex-col">
									<div>{formatNumber(cart.productsPrice)} Lei</div>
									<div>{formatShippingCost(shippingCost)}</div>
									{!!cart.discount && (
										<div className="text-green">-{formatNumber(cart.discount)} Lei</div>
									)}
								</div>
							</div>
							<div className="flex justify-between mb-4 font-semibold">
								<div>Total</div>
								<div>{formatNumber(total)} Lei</div>
							</div>

							{paymentMethod === PaymentMethod.Online ? (
								<Button
									type="submit"
									text="Continuă spre plată"
									containerClassName="w-full success font-semibold !py-4"
									icon={<RiBankCardFill className="mr-3 -ml-3" size={18} />}
								/>
							) : (
								<Button
									type="submit"
									text="Plasează comanda"
									containerClassName="w-full success font-semibold !py-4"
									icon={<RiInboxUnarchiveFill className="mr-3 -ml-3" size={18} />}
								/>
							)}
						</Container>

						<div className="p-3 sm:p-6 text-center text-secondary text-sm">
							<p>
								Plasând comanda ești de acord cu{' '}
								<Link to="/terms-of-service" className="link">
									Termeni și Condiții
								</Link>{' '}
								și{' '}
								<Link to="/shipping-and-refund" className="link">
									politica de retur
								</Link>
								.
							</p>

							<div className="flex justify-center items-center text-secondary mt-2">
								<RiVisaLine className="mr-2" size={26} />
								<RiMastercardFill className="ml-2" size={22} />
							</div>
						</div>
					</div>
				</div>
			</Form>
		</>
	);
}
