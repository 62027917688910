import { useContext } from 'react';
import { toast } from 'react-toastify';
import { postJson } from '../../api/_methods';
import { InputText } from '../Input';
import { FacebookLoginButton, GoogleLoginButton } from './ProviderLogin';
import { Link } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import Button from '../Button';
import Form from './../Form';
import { UIModalCloseButton } from 'components/modals/UIModal';
import { LoginBanner } from 'icons';

export default function Register({ goToLogin, onClose }) {
	const { login } = useContext(UserContext);

	const onRegister = async (formData, e) => {
		const { email, password, fullName, termsAccepted } = Object.fromEntries(formData);

		if (!email || !password || !fullName) {
			return toast.error('Te rog să introduci toate datele.');
		}

		if (fullName.split(' ').length < 2) {
			return toast.error('Te rog să introduci numele complet.');
		}

		if (!termsAccepted) {
			return toast.error('Trebuie să fii de acord cu Termenii și Condițiile.');
		}

		const res = await postJson('/register', {
			email: email.toLowerCase(),
			password,
			fullName,
			termsAccepted: !!termsAccepted,
		});

		if (res.error) {
			toast.error(res.error);
		} else {
			login(res);
			onClose(res);
			window.ttq.track('CompleteRegistration', { email });
		}
	};

	return (
		<div>
			<div className="register-banner">
				<LoginBanner />
				<UIModalCloseButton className="!p-2.5" theme="light" onClick={onClose} />
			</div>

			<div className="mt-3 px-5 pb-7 md:px-8 md:pb-8">
				<h2 className="text-center font-semibold text-[24px]">Creează cont nou</h2>

				<div className="text-center mb-5">
					<span>Ai deja cont?</span>
					<span className="ml-1 link select-none" onClick={goToLogin}>
						Conectează-te aici!
					</span>
				</div>

				<Form onSubmit={onRegister}>
					<InputText
						type="text"
						placeHolder="Nume și Prenume"
						name="fullName"
						containerClassName="!mb-2"
					/>
					<InputText type="email" placeHolder="E-mail" name="email" containerClassName="!mb-2" />
					<InputText
						type="password"
						placeHolder="Parolă"
						name="password"
						containerClassName="!mb-2"
					/>

					<div>
						<input id="agree" type="checkbox" name="termsAccepted" />
						<label htmlFor="agree" className="ml-1.5">
							Sunt de acord cu{' '}
							<Link to="/terms-of-service" className="link">
								Termeni și condiții
							</Link>
						</label>
					</div>
					<Button type="submit" containerClassName="light w-full mt-4" text="Înregistrare" />
				</Form>

				<div className="text-center my-4 text-secondary">sau conectează-te cu</div>

				<GoogleLoginButton onClose={onClose} className="mb-1.5" />
				<FacebookLoginButton onClose={onClose} className="mb-1.5" />

				{/* <TikTokLoginButton onClose={onClose} /> */}
			</div>
		</div>
	);
}
