import { useState } from 'react';
import { formatPriceChange } from 'utils/format';
import { readPartialInt } from 'utils/utils';
import { InputText } from './Input';

export default function PriceChangeInput({ name, defaultValue, onChange, containerClassName = '' }) {
	const [value, setValue] = useState(defaultValue);

	const handleChange = (e) => {
		const int = readPartialInt(e.target.value);
		if (!Number.isNaN(int)) {
			setValue(int);
			if (onChange) {
				onChange(int);
			}
		}
	};

	return (
		<div className="flex items-center">
			<InputText
				name={name}
				placeHolder="+0"
				containerClassName={`max-w-[90px] !mb-0 ${containerClassName}`}
				className="text-center"
				value={formatPriceChange(value)}
				onChange={handleChange}
			/>
			<span className="ml-2">Lei</span>
		</div>
	);
}
