import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import UserMenu from './UserMenu';
import useGrayBackground from '../../hooks/useGrayBackground';
import useScrollToTop from 'hooks/useScrollToTop';

export default function User() {
	useGrayBackground();
	const [title, setTitle] = useState('');
	const location = useLocation();

	useScrollToTop([location]);

	return (
		<div className="container !px-2.5 my-5 md:my-9">
			<p className="lg:ml-56 text-2xl text-dark-secondary tracking-wide">{title || 'Profil'}</p>

			<div className="flex items-start mt-3">
				{/* User menu */}
				<div className="hidden lg:block lg:mr-4 shrink-0 sticky top-20">
					<UserMenu />
				</div>

				{/* Page content */}
				<div className="flex-1">
					<Outlet context={[setTitle]} />
				</div>
			</div>
		</div>
	);
}
