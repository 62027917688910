import { BsChevronDown } from 'react-icons/bs';
import {
	RiUserLine,
	RiShoppingBasketLine,
	RiLogoutCircleRLine,
	RiSettings3Line,
	RiListCheck2,
	RiStarLine,
	RiDashboardLine,
} from 'react-icons/ri';
import {
	BeamingFaceWithSmileyEyes,
	GrinningFaceWithSmileyEyes,
	PartyingFace,
	SmilingFaceWithHeartEyes,
	SmilingFaceWithSunglasses,
} from '../../icons';
import { formatUserPanelName } from 'utils/format';
import { useContext, useState } from 'react';
import ReactGA from 'react-ga4';
import Avatar from '../Avatar';
import isArtist from 'utils/isArtist';
import UserContext from 'context/UserContext';
import isAdmin from 'utils/isAdmin';

const faces = [
	<GrinningFaceWithSmileyEyes key="emoji1" />,
	<BeamingFaceWithSmileyEyes key="emoji2" />,
	<SmilingFaceWithHeartEyes key="emoji3" />,
	<SmilingFaceWithSunglasses key="emoji5" />,
	<PartyingFace key="emoji6" />,
];

export default function UserDropdown({ openLoginPanel, openUserPanel, closePanels, navigateTo }) {
	const { user, logout } = useContext(UserContext);
	const [face, setFace] = useState(0);

	const onMyAccountClick = () => {
		if (user) {
			ReactGA.event({ action: 'Open account menu', category: 'login' });
			openUserPanel();
		} else {
			ReactGA.event({ action: 'Open login panel', category: 'login' });
			openLoginPanel();
		}
	};

	const onUserLogout = () => {
		ReactGA.event({ action: 'Logout', category: 'login' });
		closePanels();
		logout();
	};

	const changeFace = () => {
		if (!user) {
			let rnd = Math.floor(Math.random() * faces.length);
			while (rnd === face) rnd = Math.floor(Math.random() * faces.length);
			setFace(rnd);
		}
	};

	return (
		<li className="ml-2 sm:ml-3.5 bg - white" onMouseEnter={changeFace}>
			<div
				className="z-10 flex items-center animated-fill p-1 popover-parent"
				onClick={onMyAccountClick}
			>
				<Avatar name={user?.displayName} photo={user?.photo} className="w-9 h-9" face={faces[face]} />
				<div className="hidden lg:flex lg:items-center">
					<span className="mx-1.5">Contul meu</span>
					<BsChevronDown size={10} color="#222" className="mt-1 mr-0.5" />
				</div>
			</div>

			{/* User dropdown */}
			{user && (
				<div id="user-dropdown" className="popover right-0">
					<div className="p-3 text-xl break-words">
						Hey, {formatUserPanelName(user.displayName)}👋
					</div>
					{isAdmin(user) && (
						<div className="user-item" onClick={() => navigateTo('/admin')}>
							<RiDashboardLine size={22} className="mr-2 text-blue" />
							<span>Admin panel</span>
						</div>
					)}

					<div className="user-item" onClick={() => navigateTo('/user/profile')}>
						<RiUserLine size={22} className="mr-2 text-blue" />
						<span>Profil</span>
					</div>

					{isArtist(user) && (
						<div className="user-item" onClick={() => navigateTo('/user/products')}>
							<RiListCheck2 size={22} className="mr-2 text-blue" />
							<span>Produse</span>
						</div>
					)}

					<div className="user-item" onClick={() => navigateTo('/user/orders')}>
						<RiShoppingBasketLine size={22} className="mr-2 text-blue" />
						<span>Comenzi</span>
					</div>

					<div className="user-item" onClick={() => navigateTo('/user/reviews')}>
						<RiStarLine size={22} className="mr-2 text-blue" />
						<span>Recenzii</span>
					</div>

					<div className="user-item" onClick={() => navigateTo('/user/settings')}>
						<RiSettings3Line size={22} className="mr-2 text-blue" />
						<span>Setări cont</span>
					</div>

					<hr className="border-eee my-1" />

					<div className="user-item" onClick={onUserLogout}>
						<RiLogoutCircleRLine size={22} className="mr-2 text-secondary" />
						<span>Deconectare</span>
					</div>
				</div>
			)}
		</li>
	);
}
