import { memo, useMemo, useState } from 'react';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import { ProductCardSkeleton } from '../../utils/skeletons';
import ProductCard from '../ProductCard';
import { getCategoryIcon } from 'utils/utils';

function TheSwiper({ products, setSwiper, isMobile }) {
	return (
		<Swiper
			freeMode={true}
			modules={[FreeMode]}
			slidesPerView={'auto'}
			onSwiper={setSwiper}
			spaceBetween={isMobile ? 8 : 15}
		>
			{products ? (
				products.map((p) => (
					<SwiperSlide key={p.slug}>
						<ProductCard product={p} />
					</SwiperSlide>
				))
			) : (
				<>
					<SwiperSlide>
						<ProductCardSkeleton />
					</SwiperSlide>
					<SwiperSlide>
						<ProductCardSkeleton />
					</SwiperSlide>
					<SwiperSlide>
						<ProductCardSkeleton />
					</SwiperSlide>
					<SwiperSlide>
						<ProductCardSkeleton />
					</SwiperSlide>
					<SwiperSlide>
						<ProductCardSkeleton />
					</SwiperSlide>
				</>
			)}
		</Swiper>
	);
}

const MemoSwiper = memo(TheSwiper);

export default function ProductsSwiper({ name, products, className = '', linkToMore, isMobile }) {
	const [swiper, setSwiper] = useState(null);
	const Icon = useMemo(() => getCategoryIcon(name), [name]);

	if (!products?.length) {
		return null;
	}

	const slideNext = () => {
		swiper.slideNext();
		swiper.slideNext();
	};

	const slidePrev = () => {
		swiper.slidePrev();
		swiper.slidePrev();
	};

	return (
		<section className={className}>
			<div className="flex justify-between mb-4 items-center">
				<div className="flex items-center">
					{Icon && <Icon className="w-6 mr-2" />}
					<p className="text-lg font-semibold">{name}</p>
					<Link
						to={linkToMore || `/products/category/${name}`}
						className="link mx-3 whitespace-nowrap"
					>
						vezi tot
					</Link>
				</div>

				<div className="flex text-secondary">
					<div className="swiper-btn mr-1 sm:mr-2" onClick={slidePrev}>
						<RiArrowLeftSLine size={24} />
					</div>

					<div className="swiper-btn" onClick={slideNext}>
						<RiArrowRightSLine size={24} />
					</div>
				</div>
			</div>

			<div className="product-cards-swiper">
				<MemoSwiper products={products} setSwiper={setSwiper} isMobile={isMobile} />
			</div>
		</section>
	);
}
