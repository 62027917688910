import { formatPriceChange } from 'utils/format';
import { InputSelect } from './Input';

export default function VariationSelect({
	variation,
	selectedOption,
	onSelect = () => undefined,
	containerClassName = '',
}) {
	const errorClassName = variation.error ? 'error' : '';
	return (
		<InputSelect
			id={`variation-${variation._id}`}
			placeHolder={variation.title}
			className={errorClassName}
			containerClassName={`flex-shrink min-w-0 ${containerClassName}`}
			optionsContainerClassName="w-full"
			options={variation.options}
			optionRenderer={(option, selected, onSelect) => (
				<div
					key={option.value}
					className="select-option flex justify-between"
					onClick={(e) => onSelect(e, option)}
					title={option.value}
				>
					<div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{option.value}</div>
					{!!option.priceChange && (
						<span className="ml-2 text-secondary">
							{formatPriceChange(option.priceChange)} Lei
						</span>
					)}
				</div>
			)}
			selectedOption={selectedOption || ''}
			onSelect={onSelect}
		/>
	);
}
