import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg';

export default function Logo({ compact = true }) {
	return (
		<Link to="/" className="mr-5">
			<img src={logo} alt="Custom Studio" width="190" height="36" />
		</Link>
	);
}
