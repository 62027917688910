import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EmptyCart } from 'icons';
import { formatNumber, formatShippingCost } from 'utils/format';
import useGrayBackground from '../hooks/useGrayBackground';
import SubOrder from '../components/SubOrder';
import WhiteContainer from '../components/WhiteContainer';
import useScrollToTop from '../hooks/useScrollToTop';
import Button from '../components/Button';
import UserContext from './../context/UserContext';
import LoadingSpinner from './../components/LoadingSpinner';
import usePageTitle from 'hooks/usePageTitle';

export default function Cart(props) {
	useGrayBackground();
	useScrollToTop();
	usePageTitle('Coșul meu - Custom Studio');

	const navigate = useNavigate();
	const { loadCart, cart, isLoggedIn, checkingSession } = useContext(UserContext);
	const [isFetching, setIsFetching] = useState(true);

	// TODO: do it
	// const onApplyDiscountCode = async (formData, e) => {
	// 	const { discountCodeName } = Object.fromEntries(formData);
	// 	const res = await applyDiscountCode(discountCodeName);
	// 	if (res.error) {
	// 		toast.error(res.error);
	// 	} else {
	// 		e.target.reset();
	// 		toast.success(res.message);
	// 		// setCart(res.cart);
	// 	}
	// };

	const onContinue = () => {
		navigate('/checkout');
	};

	// load cart
	useEffect(() => {
		loadCart().then(() => setIsFetching(false));
	}, [isLoggedIn]);

	const renderCart = () => (
		<div className="fade-in">
			{!cart.subOrders.length ? (
				<div className="text-center fade-in">
					<EmptyCart className="w-3/4 max-w-md mx-auto mb-10" />
					<p>Coșul tău este gol.</p>
					<p>
						Revino după ce adaugi{' '}
						<Link to="/products" className="link">
							produsele
						</Link>{' '}
						dorite.
					</p>
				</div>
			) : (
				<div className="flex items-start mt-5 flex-col md:flex-row">
					{/* Sub Orders */}
					<div className="w-full">
						{cart.subOrders.map((subOrder) => (
							<SubOrder
								key={subOrder.artist._id}
								artistId={subOrder.artist._id}
								artistName={subOrder.artist.displayName}
								status={subOrder.status}
								productsPrice={subOrder.productsPrice}
								shippingCost={subOrder.shippingCost}
								discount={subOrder.discount}
								total={subOrder.total}
								cartItems={subOrder.cartItems}
								isInCart
							/>
						))}
					</div>

					{/* Order total */}
					{cart.subOrders.length > 0 && (
						<div className="md:ml-5 md:w-72 w-full shrink-0">
							<WhiteContainer className="p-3 sm:p-4 mb-5">
								<div className="text-xl mb-4 font-semibold">Sumar comandă</div>
								<div className="flex justify-between">
									<div>
										<p className="mb-1">Cost produse</p>
										<p className="mb-1">Cost livrare</p>
										{!!cart.discount && <div className="text-green">Cod reducere</div>}
									</div>
									<div className="items-end flex flex-col">
										<div>{formatNumber(cart.productsPrice)} Lei</div>
										<div>{formatShippingCost(cart.shippingCost)}</div>
										{!!cart.discount && (
											<div className="text-green">
												-{formatNumber(cart.discount)} Lei
											</div>
										)}
									</div>
								</div>

								<hr className="my-3 border-eee" />

								<div className="flex justify-between mb-3 text-lg font-semibold">
									<div>Total</div>
									<div>{formatNumber(cart.total)} Lei</div>
								</div>

								<Button
									text="Continuă"
									containerClassName="dark w-full"
									onClick={onContinue}
								/>
							</WhiteContainer>

							{/* <WhiteContainer className="p-4">
								<Form onSubmit={onApplyDiscountCode}>
									<div className="mb-3">Ai un cod de reducere?</div>
									<InputText
										placeHolder="Cod reducere"
										name="discountCodeName"
										required
										containerClassName="!mb-2"
									/>
									<Button type="submit" text="Aplică" containerClassName="w-full light" />
								</Form>
							</WhiteContainer> */}
						</div>
					)}
				</div>
			)}
		</div>
	);

	return (
		<div className="container mb-4 mt-10 fade-in">
			<h3>Coș de cumpărături</h3>

			{checkingSession || isFetching || !cart.isLoaded ? (
				<div className="late-fade-in">
					<LoadingSpinner className="text-secondary mx-auto mt-12" size={30} />
				</div>
			) : (
				renderCart()
			)}
		</div>
	);
}
