import { useMemo } from 'react';
// import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { RiStarFill, RiStarHalfFill } from 'react-icons/ri';

function computeStars(rating) {
	rating = parseFloat(rating); // could come as string if formatted
	let fullStars = Math.floor(rating);
	let emptyStars = 5 - fullStars - 1;
	let halfStars = 0;

	rating = (rating - fullStars).toFixed(2);

	if (rating >= 0.71) {
		fullStars++;
	} else if (rating < 0.3) {
		emptyStars++;
	} else {
		halfStars++;
	}

	return [fullStars, halfStars, emptyStars];
}

export default function RatingStars({ rating = 0, size = 19, className = '', color = 'text-yellow' }) {
	const [fullStars, halfStars, emptyStars] = useMemo(() => computeStars(rating), [rating]);

	const starsChain = Array.from({ length: fullStars }, (e, i) => (
		<RiStarFill key={i} size={size} className={color} />
	));
	const emptyStarsChain = Array.from({ length: emptyStars }, (e, i) => (
		<RiStarFill key={`empty-${i}`} size={size} className={'text-[#e7e7e7]'} />
	));

	return (
		<div className={`flex ${className}`}>
			{starsChain}
			{!!halfStars && <RiStarHalfFill size={size} className={color} />}
			{emptyStarsChain}
		</div>
	);
}
