import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation, Thumbs, Pagination } from 'swiper/modules';
import ProductHeart from 'components/ProductHeart';
import ResponsiveImage from 'components/ResponsiveImage';

export default function ProductImageSlider({ productId, alt, images }) {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	return (
		<div className="relative">
			<ProductHeart productId={productId} className="mt-2 mr-2" />

			<Swiper
				style={{
					'--swiper-navigation-color': '#fff',
				}}
				loop={true}
				spaceBetween={15}
				navigation={true}
				pagination={true}
				thumbs={{ swiper: thumbsSwiper }}
				modules={[Navigation, Thumbs, Pagination]}
				className="product-swiper"
			>
				{images.map((img, index) => (
					<SwiperSlide key={index} className="rounded-md overflow-hidden">
						<ResponsiveImage src={img} alt={alt} />
					</SwiperSlide>
				))}
			</Swiper>
			<Swiper onSwiper={setThumbsSwiper} slidesPerView={'auto'} className="product-thumb-swiper">
				{images.map((img, index) => (
					<SwiperSlide key={index}>
						<img src={img} alt={alt} className="bg-gray" />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}
