import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useRequireLogin() {
	const navigate = useNavigate();
	const redirectAfterLogin = useRef();
	const [showLoginPanel, setShowLoginPanel] = useState(false);

	const open = (redirect) => {
		redirectAfterLogin.current = redirect;
		setShowLoginPanel(true);
	};

	const close = (user) => {
		if (user?.username && redirectAfterLogin?.current) {
			navigate(redirectAfterLogin.current, { replace: true });
			redirectAfterLogin.current = null;
		}
		setShowLoginPanel(false);
	};

	useEffect(() => {}, [showLoginPanel]);

	return [showLoginPanel, open, close];
}
