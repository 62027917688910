import UIModal, { UIModalCloseButton } from './UIModal';

export default function PersonalizationModal({
	onRequestClose,
	selectedOptions,
	personalizationText,
	personalizationImages,
}) {
	return (
		<UIModal name="personalization-text" className="p-4 w-full max-w-md" onRequestClose={onRequestClose}>
			<UIModalCloseButton className="!text-[#888] bg-gray" onClick={onRequestClose} />
			<h3 className="font-semibold mb-3">Cerințe client</h3>
			{selectedOptions.length > 0 &&
				selectedOptions.map((option) => (
					<div key={option._id} className="flex flex-wrap mb-2">
						<div className="shrink-0 font-semibold mr-1">{option.variationTitle}:</div>
						<div className="whitespace-pre-line break-words">{option.value}</div>
					</div>
				))}

			{personalizationText && (
				<div className="flex flex-wrap">
					<div className="shrink-0 font-semibold mr-1">Personalizare:</div>
					<div className="whitespace-pre-line break-words">{personalizationText}</div>
				</div>
			)}

			{personalizationImages && personalizationImages.length > 0 && (
				<div>
					<p className="font-semibold">Imagini:</p>
					<div className="flex flex-wrap mt-2">
						{personalizationImages.map((img, index) => (
							<div key={index} className="mr-1.5 mb-1.5">
								<img
									// can be an object containing the file and the base64 image, or a string
									src={img.data || img}
									className="product-personalization-image-preview cursor-pointer select-none bg-gray"
									onClick={() => {
										if (img.data) {
											const image = new Image();
											image.src = img.data;
											const w = window.open('about:blank');
											w.document.write(image.outerHTML);
										} else {
											window.open(img);
										}
									}}
								/>
							</div>
						))}
					</div>
				</div>
			)}
		</UIModal>
	);
}
