import { get, postJson } from './_methods';

export function getChats() {
	return get('/user/chats');
}

export function blockUser(activeChat, report) {
	return postJson('/user/block-user-chat', {
		chatId: activeChat._id,
		userId: activeChat.contact._id,
		report,
	});
}

export function unblockUser(activeChat) {
	return postJson('/user/unblock-user-chat', {
		chatId: activeChat._id,
		userId: activeChat.contact._id,
	});
}
