import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CategoryCard from '../components/CategoryCard';
import SubCategoryCard from '../components/SubCategoryCard';
import Button from '../components/Button';
import UserContext from 'context/UserContext';
import { MobilePaint } from 'icons';
import isArtist from 'utils/isArtist';
import useIsMobile from 'hooks/useIsMobile';
import PopularArtists from 'components/home/PopularArtists';
import PopularProducts from 'components/home/PopularProducts';
import JoinDiscord from 'components/home/JoinDiscord';
import HowItWorks from 'components/home/HowItWorks';
import LoginPanel from 'components/login/LoginPanel';
import useRequireLogin from 'hooks/useRequireLogin';
import useScrollTo from 'hooks/useScrollTo';
import HomeTitleText from 'components/home/HomeTitleText';
import Decoratiuni from '../images/Decor.jpg';
import pentruEl from '../images/pentruEl.jpg';
import pentruEa from '../images/pentruEa.jpg';
import deNunta from '../images/deNunta.jpg';
import Aniversare from '../images/Aniversare.jpg';
import Bebelusi from '../images/bebelusi.jpg';
import { RiStore2Line } from 'react-icons/ri';
import { Category } from 'custom-studio-constants';

const CreativeGirlAnimation = React.lazy(() => import('../components/CreativeGirlAnimation'));

export default function Home(props) {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const { user } = useContext(UserContext);
	const [showLoginPanel, openLoginPanel, closeLoginPanel] = useRequireLogin();
	const scrollToHowItWorks = useScrollTo('how-it-works', { offset: 90 });

	const onBecomeArtist = (e) => {
		if (!user) {
			openLoginPanel('/open-shop');
		} else if (!isArtist(user)) {
			navigate('/open-shop');
		}
	};

	useEffect(() => {
		const href = window.location.href;
		if (href.includes('#how-it-works')) {
			scrollToHowItWorks();
		}
	}, []);

	return (
		<>
			{showLoginPanel && <LoginPanel onRequestClose={closeLoginPanel} />}

			<div className="container">
				<MobilePaint className="absolute top-5 -z-10 left-0 right-0 w-full md:hidden" />

				<div className="flex pt-12 md:pt-10 mb-10 md:mb-20">
					<div className="lg:w-1/2 flex flex-col justify-between">
						<HomeTitleText />

						<div
							id="btn-group"
							className="flex flex-col sm:flex-row justify-center md:justify-start items-center mt-6 md:mt-8"
						>
							{!isArtist(user) && (
								<Button
									containerClassName="primary sm:mr-4"
									text="Deschide magazin"
									onClick={onBecomeArtist}
									icon={<RiStore2Line className="mr-2" />}
								/>
							)}
							<div
								onClick={scrollToHowItWorks}
								className="border-2 border-dark-gray rounded-md items-center flex md:px-6 text-darker-gray hover:bg-gray transition-all cursor-pointer justify-center p-2 mt-4 sm:mt-0"
							>
								Cum funcționează?
							</div>
						</div>
					</div>

					<div className="hidden md:flex justify-center lg:w-1/2 lg:text-center">
						<div id="creative-girl-placeholder" /> {/* Auto hidden */}
						{!isMobile && (
							<React.Suspense>
								<div id="creative-girl-container">
									<CreativeGirlAnimation />
								</div>
							</React.Suspense>
						)}
					</div>
				</div>

				<div className="grid-cols-2 md:grid-cols-3 xl:grid-cols-5 grid mb-9 xl:mb-14 gap-x-5 md:gap-x-10 gap-y-5">
					{Object.values(Category).map((category) => (
						<CategoryCard key={category} category={category} />
					))}
				</div>
				<div className="flex items-center mb-4">
					<h3 className="font-semibold mr-2">Colecții pentru tine</h3>
					<div className=" bg-blue text-white px-2 py-0.5 rounded-sm text-xs tracking-wide">
						NOU
					</div>
				</div>
				<div className="grid-cols-2 md:grid-cols-3 xl:grid-cols-5 grid justify-center sm:justify-between pt-2 flex-wrap items-center">
					<SubCategoryCard src={Decoratiuni} subcategory="Decorațiuni alese" />
					<SubCategoryCard src={pentruEl} subcategory="Cadouri pentru el" />
					<SubCategoryCard src={pentruEa} subcategory="Cadouri pentru ea" />
					<SubCategoryCard src={Aniversare} subcategory="Cadouri aniversare" />
					<SubCategoryCard src={deNunta} subcategory="Cadouri de nuntă" />
					<SubCategoryCard src={Bebelusi} subcategory="Cadouri bebeluși" className="xl:hidden" />
				</div>

				<PopularArtists />
				<PopularProducts isMobile={isMobile} />
				<HowItWorks onBecomeArtist={onBecomeArtist} />

				<div className="text-center flex justify-center flex-col mt-10">
					<div>
						<h3 className="font-semibold">Ai nevoie de ajutor?</h3>
					</div>
					<div className="flex justify-center mt-5 mb-3 flex-col md:flex-row">
						<Link to="/contact">
							<Button text="Contactează-ne" containerClassName="primary m-3 hover:scale-105" />
						</Link>
						<Link to="/faq">
							<Button
								text="Întrebări frecvente"
								containerClassName="primary-light m-3 hover:scale-105"
							/>
						</Link>
					</div>
				</div>
			</div>

			{/* Discord */}
			<JoinDiscord isMobile={isMobile} />
		</>
	);
}
