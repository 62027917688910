import { get, post, postJson, _deleteJson } from './_methods';

export function checkLoginStatus() {
	return get('/user/login-status');
}

export function getProfile(userIdOrUsername) {
	return get(`/users/${userIdOrUsername}/profile`);
}

export function getUserProducts(userId) {
	return get(`/users/${userId}/products`);
}

export function getUserPublicProducts(userId) {
	return get(`/users/${userId}/public-products`);
}

export function getArtistReviews(artistId) {
	return get(`/users/${artistId}/received-reviews`);
}

export function getGivenReviews(userId) {
	return get(`/users/${userId}/given-reviews`);
}

export function getCart() {
	return get('/user/cart');
}

export function getGivenOrders(userId) {
	return get(`/users/${userId}/given-orders`);
}

export function getReceivedOrders(userId) {
	return get(`/users/${userId}/received-orders`);
}

// { displayName, avatar, about, instagram, tikTok, deleteAvatar }
export function updateProfile(formData) {
	return post('/user/profile', formData);
}

// { fullName, cui?, iban? }
export function updatePersonalData(personalData) {
	return postJson('/user/personal-data', personalData);
}

// { name, percentage?, fixed?, daysValid }
export function createDiscountCode(discountCode) {
	return postJson('/user/discount-code', { discountCode });
}

export function deleteDiscountCode(discountCodeId) {
	return _deleteJson('/user/discount-code', { discountCodeId });
}

export function addToCart(productId, selectedOptions, personalizationText, personalizationImages) {
	const data = new FormData();
	data.append('productId', productId);
	data.append('selectedOptions', JSON.stringify(selectedOptions));
	data.append('personalizationText', personalizationText);
	personalizationImages.forEach((img) => data.append('personalizationImages', img.file, img.id));
	return post('/user/add-to-cart', data);
}

export function updateCartItemQuantity(itemId, quantity) {
	return postJson('/user/update-cart-item-quantity', { itemId, quantity });
}

export function applyDiscountCode(discountCodeName) {
	return postJson('/user/cart/apply-discount-code', { discountCodeName });
}

export function updateEmail(email, password) {
	return postJson('/user/email', { email, password });
}

export function updatePassword(oldPassword, newPassword) {
	return postJson('/user/password', { oldPassword, newPassword });
}

export function forgotPassword(email, token) {
	return postJson('/forgot-password', { email, token });
}

export function resetPassword(password, userId, token) {
	return postJson('/reset-password', { password, userId, token });
}

// { city, county, address, phone }
export function addAddress(address) {
	return postJson('/user/address', { address });
}

// { fullName, cui, birthDay, birthMonth, birthYear, iban, termsAccepted }
export function becomeArtist(data) {
	return postJson('/user/become-artist', data);
}

export function addCheatCode(code) {
	return postJson('/user/add-cheat-code', { code });
}

export function requestCashOnDelivery(cashOnDeliveryExtraCost) {
	return postJson('/user/cash-on-delivery', { cashOnDeliveryExtraCost });
}

export function updateCashOnDeliveryExtraCost(cashOnDeliveryExtraCost) {
	return postJson('/user/cash-on-delivery-extra-cost', { cashOnDeliveryExtraCost });
}

export function addProductsToLikedProducts(products) {
	return postJson(`/user/add-liked-products`, { products });
}

export function sendContactMail(name, email, message, token) {
	return postJson('/contact', { name, email, message, token });
}

export function logoutUser() {
	return post('/logout');
}
