export default function useFilter(
	paramName,
	searchParams,
	setSearchParams,
	defaultValue = '',
	replace = false
) {
	const filter = searchParams.get(paramName) || defaultValue;

	const updateFilter = (newFilter) => {
		setSearchParams(
			(currentParams) => {
				if (newFilter) {
					currentParams.set(paramName, newFilter);
				} else {
					if (currentParams.has(paramName)) currentParams.delete(paramName);
				}

				return currentParams;
			},
			{ replace }
		);
	};

	return [filter, updateFilter];
}
