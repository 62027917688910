import { addAddress } from 'api/userApi';
import UserContext from 'context/UserContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { InputText } from '../Input';
import Button from '../Button';
import Form from '../Form';
import UIModal, { UIModalCloseButton } from './UIModal';

export default function AddAddressModal({ className = '', onRequestClose }) {
	const { user, setUser } = useContext(UserContext);

	const onSubmit = async (formData) => {
		const address = Object.fromEntries(formData);

		const res = await addAddress(address);
		if (res.error) {
			toast.error(res.error);
		} else {
			onRequestClose();
			toast.success(res.message);
			setUser({ ...user, address });
		}
	};

	return (
		<UIModal
			name="add-address"
			onRequestClose={onRequestClose}
			className={`w-full max-w-2xl p-6 sm:p-12 ${className}`}
		>
			<UIModalCloseButton theme="gray" onClick={onRequestClose} />

			<Form onSubmit={onSubmit}>
				<h3 className="font-semibold">Adresa mea</h3>
				<hr className="border-eee mt-2 mb-5" />
				<div className="flex flex-wrap">
					<InputText
						name="city"
						label="Localitate"
						defaultValue={user.address?.city}
						containerClassName="mr-1 sm:mr-2 flex-1"
						required
					/>
					<InputText
						name="county"
						label="Județ / Sector"
						defaultValue={user.address?.county}
						containerClassName="ml-1 sm:ml-2 flex-1"
						required
					/>
				</div>

				<InputText
					name="address"
					label="Adresă"
					defaultValue={user.address?.address}
					containerClassName="mr-2 flex-1"
					placeHolder="Stradă, număr, etc."
					required
				/>

				<InputText
					name="phone"
					type="tel"
					defaultValue={user.address?.phone}
					label="Telefon"
					containerClassName="max-w-[200px]"
					required
				/>

				<Button type="submit" text="Salvează" containerClassName="primary mt-2" />
			</Form>
		</UIModal>
	);
}
