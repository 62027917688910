import { get, post, postJson } from './_methods';

export function getOrder(number) {
	return get(`/orders/${number}`);
}

// Creates an order from the user's cart
export function order({ formData, paymentMethod, cart }) {
	formData.append('paymentMethod', paymentMethod);

	// cart should only be sent if user is not logged in
	if (cart) {
		cart.subOrders.forEach((subOrder) =>
			subOrder.cartItems.forEach((item) =>
				item.personalizationImages?.forEach((img) =>
					formData.append('personalizationImages', img.file, `${item.id}-${img.id}`)
				)
			)
		);
		formData.append(
			'cart',
			JSON.stringify(cart, (key, value) => {
				if (key === 'personalizationImages') return undefined;
				return value;
			})
		);
	}

	return post('/orders', formData);
}

export function changeSubOrderStatus(orderId, status, artistId, courier, awb) {
	return postJson(`/orders/${orderId}/status`, { status, artistId, courier, awb });
}
