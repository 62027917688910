import Button from 'components/Button';
import StatusIcon from 'components/StatusIcon';
import WhiteContainer from 'components/WhiteContainer';
import useGrayBackground from 'hooks/useGrayBackground';
import useScrollToTop from 'hooks/useScrollToTop';
import UserContext from '../context/UserContext';
import { Status } from 'custom-studio-constants';
import { useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export default function OrderPlaced() {
	const { user } = useContext(UserContext);
	const [searchParams] = useSearchParams();
	const cancelled = searchParams.get('cancelled') === 'true';
	const status = cancelled ? Status.Pending : Status.Approved;

	useGrayBackground();
	useScrollToTop();

	useEffect(() => {
		if (!cancelled) {
			window.ttq.track('CompletePayment', { currency: 'RON' });
		}
	}, []);

	return (
		<WhiteContainer className="p-6 sm:py-6 sm:px-8 w-full max-w-2xl text-center mx-auto mt-12 fade-in">
			<div className="mx-auto bg-light-green p-1 rounded-full inline-block">
				<StatusIcon status={status} size={130} />
			</div>

			{cancelled ? (
				<>
					<h3 className="mt-2 font-semibold">Plata eșuată.</h3>
					<p className="mt-2 mb-8">Comanda aceasta va fi anulată, dar poți plasa una nouă.</p>
				</>
			) : (
				<>
					<h3 className="mt-2 font-semibold">Comanda a fost plasată!</h3>

					<p className="mt-4 mb-8">
						Vei primi un email imediat ce comanda este confirmată de artist.
					</p>
				</>
			)}

			{user && (
				<Link to="/user/orders">
					<Button containerClassName="primary" text="Comenzile mele" />
				</Link>
			)}
		</WhiteContainer>
	);
}
