import { useState } from 'react';
import { Link } from 'react-router-dom';

const ARTISTS = 'ARTSITS';
const BUYERS = 'BUYERS';

export default function HowItWorks({ onBecomeArtist }) {
	const [forWho, setForWho] = useState(ARTISTS);

	return (
		<section id="how-it-works" className="mt-12 mb-6 md:mb-12">
			<h3 className="mb-8 font-semibold">Cum funcționează?</h3>

			<div className="flex justify-between max-w-[280px]">
				<div className="how-it-works-tab" onClick={() => setForWho(ARTISTS)}>
					Pentru creatori
					{forWho === ARTISTS && <div className="active-tab-indicator" />}
				</div>
				<div className="how-it-works-tab" onClick={() => setForWho(BUYERS)}>
					Pentru cumpărători
					{forWho === BUYERS && <div className="active-tab-indicator" />}
				</div>
			</div>

			{forWho === ARTISTS && <ForArtists onBecomeArtist={onBecomeArtist} />}
			{forWho === BUYERS && <ForBuyers />}
		</section>
	);
}

function ForArtists({ onBecomeArtist }) {
	return (
		<div className="flex flex-col lg:flex-row mt-12">
			<div className="flex-1">
				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">1</div>
						<p className="hiw-step-title">Deschide-ți magazin gratuit</p>
					</div>
					<p className="hiw-step-subtitle">
						Urmează pașii de{' '}
						<button className="link" onClick={onBecomeArtist} name="become artist">
							aici
						</button>{' '}
						pentru a iți crea un cont și a deschide un magazin online.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">2</div>
						<p className="hiw-step-title">Completează-ți profilul</p>
					</div>
					<p className="hiw-step-subtitle">
						Spune-le clienților cine ești și cu ce te ocupi. Folosește pseudonimul preferat, iar
						dacă ai pagini de instagram sau tiktok, nu uita să le adaugi.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">3</div>
						<p className="hiw-step-title">Listează lucrările spre vânzare</p>
					</div>
					<p className="hiw-step-subtitle">
						Adaugă gratuit produse în portofoliul tău. Fii cât mai sugestiv în titlu și include
						cuvinte cheie precum “personalizat”, “lucrat manual” sau “pictat”. Oferă cât mai multe
						detalii în descriere și adaugă imagini clare și relevante.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">4</div>
						<p className="hiw-step-title">Discută cu clienții pe chat</p>
					</div>
					<p className="hiw-step-subtitle">
						Clienții pot apela la serviciile tale de personalizare scriindu-ți direct pe chat-ul
						integrat în platformă. După ce vă decideți asupra detaliilor, le poți trimite un
						produs privat (custom), pe care doar ei îl pot comanda.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">5</div>
						<p className="hiw-step-title">Acceptă comenzile</p>
					</div>
					<p className="hiw-step-subtitle">
						Te vom înștiința pe email atunci când vei primi o comandă, iar tu o poți confirma sau
						anula. Odată ce lucrarea este finalizată, o poți livra prin orice metodă vrei. Vezi{' '}
						<Link to="/shipping-and-refund" className="link">
							Politica de livrare și retur
						</Link>
						.
					</p>
				</div>
			</div>

			<div className="flex-1 flex justify-center items-start mt-8 lg:mt-0">
				<div className="hiw-benefits-container">
					<p className="text-lg text-center font-semibold mb-5">Avantaje</p>
					<div className="hiw-benefit">💸 Accepți plăți online</div>
					<div className="hiw-benefit">🌟 Îți creezi un profil de 5 stele</div>
					<div className="hiw-benefit">🎨 Listezi gratuit produse</div>
					<div className="hiw-benefit">🗣️ Comunici cu clienții pe chat</div>
					<div className="hiw-benefit">📨 Trimiți oferte personalizate</div>
					<div className="hiw-benefit">🔍 Apari în căutarile pe Google</div>
				</div>
			</div>
		</div>
	);
}

function ForBuyers() {
	return (
		<div className="flex flex-col lg:flex-row mt-12">
			<div className="flex-1">
				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">1</div>
						<p className="hiw-step-title">Alege produsul sau magazinul favorit</p>
					</div>
					<p className="hiw-step-subtitle">
						Alege dintr-o gamă variată de produse personalizate sau de creatori pasionați de
						handmade.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">2</div>
						<p className="hiw-step-title">Personalizează</p>
					</div>
					<p className="hiw-step-subtitle">
						Anumite creații pot fi personalizate din secțiunea &quot;Personalizează&quot; de pe
						pagina produsului. Vrei un model cu totul diferit? Contactează magazinul direct pe
						site și comunică-i cerințele tale. Pe baza acestora, vânzătorul îți poate face o
						ofertă prin crearea unui produs pe care doar tu îl vei putea accesa.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">3</div>
						<p className="hiw-step-title">Adaugă in coș</p>
					</div>
					<p className="hiw-step-subtitle">
						Înregistrează-te rapid (folosind contul tău de Google sau Facebook) și adaugă
						produsele în coșul de cumpărături.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">4</div>
						<p className="hiw-step-title">Completează datele de livrare</p>
					</div>
					<p className="hiw-step-subtitle">
						Completează datele de livrare si numărul de telefon. Acestea vor fi transmise către
						curier odată cu expedierea coletului.
					</p>
				</div>

				<div className="hiw-step">
					<div className="flex items-center">
						<div className="hiw-step-bubble">5</div>
						<p className="hiw-step-title">Plătește cu cardul</p>
					</div>
					<p className="hiw-step-subtitle">
						Plata se efectuează cu cardul din motive de siguranță atât pentru client, cât și
						pentru vânzător. Plata ajunge in contul magazinului doar după confirmarea primirii
						coletului.
					</p>
					<p className="hiw-step-subtitle !mt-2">
						Prin această modalitate de plată, putem asigura vânzătorul că va primi suma aferentă
						unei lucrări, dar și clientul ca își poate redobândi banii în cazul în care coletul
						primit nu corespunde comenzii. În acest caz, te rugăm sa ne contactezi.
					</p>
					<p className="hiw-step-subtitle !mt-2">
						Pentru mai multe informații te rugăm sa verifici{' '}
						<Link to="/shipping-and-refund" className="link">
							Politica de livrare și retur
						</Link>
						.
					</p>
				</div>
			</div>

			<div className="flex-1 flex justify-center items-start mt-8 lg:mt-0">
				<div className="hiw-benefits-container !w-[360px]">
					<p className="text-lg text-center font-semibold mb-5">Beneficii</p>
					<div className="hiw-benefit">💸 Plătești online, rapid și sigur</div>
					<div className="hiw-benefit">🌟 Alegi creatorul potrivit pentru ideea ta</div>
					<div className="hiw-benefit">📨 Discuți cu vânzătorul pe chat</div>
					<div className="hiw-benefit">🎨 Personalizezi așa cum vrei</div>
					<div className="hiw-benefit">🔃 Beneficiezi de drept de retur</div>
				</div>
			</div>
		</div>
	);
}
