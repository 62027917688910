const shorts = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec'];

function addFrontZero(int) {
	if (int < 10) int = `0${int}`;
	return int;
}

export function formatDate(timestamp, withTime = false) {
	const date = new Date(timestamp);
	const month = date.getMonth();
	const day = date.getDate();
	const year = date.getFullYear();

	let str = `${addFrontZero(day)} ${shorts[month]} ${year}`;

	if (withTime) {
		const time = new Date(timestamp).toTimeString().substring(0, 5);
		str += `, ${time}`;
	}

	return str;
}

export function formatRating(rating) {
	if (!rating) return 0;
	let r = Number(rating).toFixed(2);
	if (r[r.length - 1] === '0') r = r.slice(0, -1);
	return r;
}

export function formatReceivedReviews(receivedReviews) {
	if (receivedReviews === 1) return '1 recenzie';
	return `${receivedReviews} recenzii`;
}

export function formatNumber(price, decimals = 2, forceDecimals = false) {
	const value = parseFloat(price.toFixed(decimals)).toLocaleString('ro');
	if (forceDecimals) {
		const decimals = value.split(',')[1];
		if (!decimals) return `${value},00`;
		if (decimals.length === 1) return `${value}0`;
	}
	return value;
}

export function formatShippingCost(cost) {
	if (cost === 0) {
		return 'Gratuit';
	}
	return `${formatNumber(cost)} Lei`;
}

export function formatUserPanelName(displayName) {
	let formatted = displayName.split(' ')[0].split('_')[0].split('.')[0].split('-')[0];
	if (formatted.length > 11) {
		formatted = formatted.substring(0, 8) + '...';
	}
	return formatted;
}

// Adds a + sign in front of a positive number
export function formatPriceChange(value) {
	if (!Number.isInteger(value)) return value;
	if (value >= 0) return '+' + value;
	return value;
}

export function formatPercentage(value) {
	if (!value) return '--';
	return `${value * 100}%`;
}
