import { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { RiAddLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { getUserProducts } from '../../api/userApi';
import { ProductCardSkeleton } from '../../utils/skeletons';
import UserContext from '../../context/UserContext';
import { getProductStatus } from '../../utils/status';
import useOutletTitle from '../../hooks/useOutletTitle';
import ProductCard from '../../components/ProductCard';

export default function UserProducts(props) {
	useOutletTitle('Produsele mele');
	const { user } = useContext(UserContext);
	const [products, setProducts] = useState(null);

	useEffect(() => {
		async function fetchProducts() {
			const res = await getUserProducts(user._id);
			if (res.error) {
				toast.error(res.error);
			} else {
				setProducts(res.reverse());
			}
		}
		fetchProducts();
	}, []);

	return (
		<div className="flex justify-center flex-col">
			<Link to="add" className="md:hidden">
				<div className="add-product-card mb-5 py-3">
					<RiAddLine size={24} />
					<p>Adaugă produs</p>
				</div>
			</Link>

			<div className="products-container">
				<Link to="add" className="hidden md:w-[230px] h-[325px] md:block mr-3 mb-4">
					<div className="add-product-card h-full">
						<RiAddLine size={24} />
						<p>Adaugă produs</p>
					</div>
				</Link>

				{products ? (
					products.map((p) => (
						<ProductCard
							key={p.title}
							product={p}
							className="mb-4 md:mr-3"
							status={getProductStatus(p)}
						/>
					))
				) : (
					<ProductCardSkeleton className="mb-4 md:mr-3 late-fade-in" />
				)}
			</div>
		</div>
	);
}
