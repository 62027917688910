import { toast } from 'react-toastify';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { formatNumber, formatRating, formatReceivedReviews } from '../../utils/format';
import { getArtistReviews } from '../../api/userApi';
import {
	RiShoppingCartFill,
	RiDeleteBin2Fill,
	RiTruckFill,
	RiEdit2Fill,
	RiEyeOffFill,
	RiEyeFill,
	RiStarFill,
	RiRefund2Line,
	RiSecurePaymentLine,
	RiHandCoinLine,
	RiHeartFill,
} from 'react-icons/ri';
import { AiOutlineSafety } from 'react-icons/ai';
import {
	deleteProduct,
	getProduct,
	getProductReviews,
	getProductsRecommandation,
	setProductPubliclyListed,
} from '../../api/productApi';
import ReactGA from 'react-ga4';
import useScrollToTop from '../../hooks/useScrollToTop';
import Collapsible from '../../components/Collapsible';
import Button from '../../components/Button';
import RatingStars from '../../components/RatingStars';
import ReviewsContainer from '../../components/ReviewsContainer';
import LoadingSpinner from '../../components/LoadingSpinner';
import ProductsSwiper from 'components/home/ProductsSwiper';
import UserContext from '../../context/UserContext';
import AddReviewModal from '../../components/modals/AddReviewModal';
import isAdmin from 'utils/isAdmin';
import LoginPanel from 'components/login/LoginPanel';
import usePageTitle from 'hooks/usePageTitle';
import VariationSelect from 'components/VariationSelect';
import ArtistPanel, { ContactArtistButton } from './ArtistPanel';
import ProductImageSlider from './ProductImageSlider';
import ProductAdminPanel from './ProductAdminPanel';
import useScrollTo from 'hooks/useScrollTo';
import useIsMobile from 'hooks/useIsMobile';
import ProductDeniedAlert from './ProductDeniedAlert';
import PersonalizationPanel from './PersonalizationPanel';
import ProductInCartModal from 'components/modals/ProductInCartModal';
import ProductHighlight from './ProductHighlight';

const ADDING_TO_CART = 'ADDING_TO_CART';
const LISTING_PRODUCT = 'LISTING_PRODUCT';
const DELETING_PRODUCT = 'DELETING_PRODUCT';

const deleteProductWarning =
	'Ești sigur că vrei să ștergi produsul?' +
	(process.env.NODE_ENV !== 'production'
		? ' Nu șterge produse reale in development. Le vei sterge imaginile si in prod!'
		: '');

/**
 * This wrapper resets the component state when the product changes
 */
export default function ProductPage() {
	const { slug } = useParams();

	useEffect(() => {
		window.ttq.track('ViewContent', { description: slug, content_id: slug });
	}, [slug]);

	return <Product key={slug} />;
}

function Product() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const reviewAfterLogin = useRef();
	const isMobile = useIsMobile();
	const scrollToReviews = useScrollTo(`reviews-container-${isMobile ? 'mobile' : 'desktop'}`, {
		offset: 90,
	});

	const { slug } = useParams();
	const { user, addToCart } = useContext(UserContext);
	const [product, setProduct] = useState(null);
	const [variations, setVariations] = useState(null);
	const [selectedOptionsPriceChange, setSelectedOptionsPriceChange] = useState(0);
	const [showLoginPanel, setShowLoginPanel] = useState(false);
	const [showReviewModal, setShowReviewModal] = useState(false);
	const [personalizationText, setPersonalizationText] = useState('');
	const [personalizationImages, setPersonalizationImages] = useState([]);
	const [artistProducts, setArtistProducts] = useState(null);
	const [similarProducts, setSimilarProducts] = useState(null);
	const [loadingState, setLoadingState] = useState(null);
	const [showProductInCartModal, setShowProductInCartModal] = useState(false);

	const originalPrice = useMemo(() => {
		if (!product || !product.originalPrice) return 0;
		return product.originalPrice + selectedOptionsPriceChange;
	}, [product, selectedOptionsPriceChange]);

	const finalPrice = useMemo(() => {
		if (!product) return 0;
		return product.price + selectedOptionsPriceChange;
	}, [product, selectedOptionsPriceChange]);

	const rating = useMemo(() => {
		if (!product) return 0;
		return formatRating(product.averageRating);
	}, [product]);

	const receivedReviews = useMemo(() => {
		if (!product) return 0;
		return formatReceivedReviews(product.receivedReviews);
	}, [product]);

	usePageTitle(product?.title);
	useScrollToTop([slug]);

	const reviewsContainerData = useMemo(() => {
		const reviews = product && [
			{
				label: 'Recenzii produs',
				reviewsCount: product.receivedReviews,
				getReviews: getProductReviews.bind(null, product._id),
			},
			{
				label: 'Recenzii artist',
				reviewsCount: product.artist.receivedReviews,
				getReviews: getArtistReviews.bind(null, product.artist._id),
			},
		];
		if (reviews && reviews[0].reviewsCount < reviews[1].reviewsCount) {
			reviews.reverse();
		}
		return reviews;
	}, [product]);

	const onEditProduct = () => {
		navigate(`/user/products/${product.slug}/edit`, { state: { product } });
	};

	const onDeleteProduct = async () => {
		if (loadingState) return;

		if (window.confirm(deleteProductWarning)) {
			setLoadingState(DELETING_PRODUCT);
			const res = await deleteProduct(product._id);

			if (res.error) {
				toast.error(res.error);
			} else {
				navigate('/user/products');
				toast.success(res.message);
			}

			setLoadingState(null);
		}
	};

	const onTogglePubliclyListed = async () => {
		if (loadingState) return;

		if (window.confirm('Ești sigur?')) {
			setLoadingState(LISTING_PRODUCT);
			const publiclyListed = !product.publiclyListed;
			const res = await setProductPubliclyListed(product._id, publiclyListed);

			if (res.error) {
				toast.error(res.error);
			} else {
				const newProduct = { ...product, publiclyListed };
				if (!publiclyListed) {
					delete newProduct.customFor;
				}

				toast.success(res.message);
				setProduct(newProduct);
			}

			setLoadingState(null);
		}
	};

	const canAddReview = () => {
		return user?.canReview?.includes(product?._id);
	};

	const selectVariationOption = (variation, option) => {
		setVariations(
			variations.map((v) => {
				if (v._id === variation._id) {
					return { ...v, selectedOption: option, error: false };
				}
				return v;
			})
		);
	};

	const onAddToCart = async () => {
		if (loadingState) return;

		// verify all options have been selected
		if (variations.some((v) => !v.selectedOption)) {
			ReactGA.event({ action: 'Add to cart with unselected options', category: 'Cart' });
			setVariations(
				variations.map((v) => {
					if (!v.selectedOption) {
						return { ...v, error: true };
					}
					return v;
				})
			);
			return toast.error('Alege din opțiuni.');
		}
		if (product.mustAddPersonalizationText && !personalizationText) {
			return toast.error('Adaugă detaliile de personalizare.');
		}
		if (product.mustAddPersonalizationImage && !personalizationImages.length) {
			return toast.error('Adaugă imaginea de personalizare.');
		}

		const selectedOptions = variations.map((v) => ({
			variationId: v._id,
			optionId: v.selectedOption?._id,
		}));

		ReactGA.event({ action: 'Add to cart', category: 'Cart' });
		window.ttq.track('AddToCart', {
			value: finalPrice,
			currency: 'RON',
			email: user?.email,
			content_id: slug,
		});

		setLoadingState(ADDING_TO_CART);
		await addToCart(product, selectedOptions, personalizationImages, personalizationText.trim());
		setLoadingState(null);

		setVariations([...product.variations]);
		setPersonalizationText('');
		setPersonalizationImages([]);
		setShowProductInCartModal(true);
	};

	const getRecommandations = async (product) => {
		if (product.approved) {
			const res = await getProductsRecommandation(product.category, product.artist._id);
			if (res.error) {
				toast.error(res.error);
			} else {
				setArtistProducts(
					res.artistProducts.filter((artistProduct) => artistProduct._id !== product._id)
				);
				setSimilarProducts(res.similarProducts);
			}
		}
	};

	// fetch product
	useEffect(() => {
		async function fetchProduct() {
			const product = await getProduct(slug);
			if (product.error) {
				toast.error(product.error);
				navigate('/products');
			} else {
				setProduct(product);
				setVariations(product.variations);

				setTimeout(() => {
					// TODO: remove timeout when swiper is more lightweight and doesn't cause jitter when opening collapsibles
					getRecommandations(product);
				}, 200);
			}
		}
		fetchProduct();
	}, [slug]);

	// perform actions after login if necessary
	useEffect(() => {
		if (!showLoginPanel && !user) {
			reviewAfterLogin.current = false;
		}
		if (!showLoginPanel && user) {
			if (reviewAfterLogin.current) {
				reviewAfterLogin.current = false;
				setShowReviewModal(true);
			}
		}
	}, [user, showLoginPanel]);

	useEffect(() => {
		if (variations) {
			let priceChange = 0;
			for (const variation of variations) {
				priceChange += variation.selectedOption?.priceChange || 0;
			}
			setSelectedOptionsPriceChange(priceChange);
		}
	}, [variations]);

	// open review panel if search param `reviewToken` is present
	useEffect(() => {
		if (searchParams.get('reviewToken')) {
			setShowReviewModal(true);
		}
	}, []);

	if (!product) {
		return <LoadingSpinner size={24} className="mt-10 text-secondary mx-auto" />;
	}

	return (
		<div className="container mt-2 mb-24 p-4 fade-in">
			{/* Login panel */}
			{showLoginPanel && <LoginPanel onRequestClose={() => setShowLoginPanel(false)} />}

			{/* Review modal */}
			{showReviewModal && (
				<AddReviewModal onRequestClose={() => setShowReviewModal(false)} product={product} />
			)}

			{/* Product added to cart modal */}
			{showProductInCartModal && (
				<ProductInCartModal
					product={product}
					onRequestClose={() => setShowProductInCartModal(false)}
				/>
			)}

			{product.denied && <ProductDeniedAlert reasonForDenial={product.reasonForDenial} />}

			<div className="md:flex justify-between mb-4">
				{/* Left side */}
				<div className="md:w-1/2 mb-6 relative">
					{isAdmin(user) && <ProductHighlight product={product} />}
					<ProductImageSlider productId={product._id} images={product.images} alt={product.title} />

					{canAddReview() && (
						<Button
							containerClassName="mt-9 primary-light w-full !py-3"
							onClick={() => setShowReviewModal(true)}
							icon={<RiStarFill size={19} className="mr-2" />}
							text="Adaugă recenzie"
						/>
					)}

					<ReviewsContainer
						id="reviews-container-desktop"
						className="hidden md:block mt-8"
						data={reviewsContainerData}
					/>
				</div>

				{/* Right side */}
				<div className="md:w-1/2 md:px-8 xl:px-20">
					{isAdmin(user) && (
						<ProductAdminPanel
							product={product}
							setProduct={setProduct}
							onEditProduct={onEditProduct}
						/>
					)}

					{!isMobile && <ArtistPanel className="mb-6" artist={product.artist} />}

					{product.customFor && (
						<div className="warning-light w-fit rounded-md px-2 py-1">
							Custom pentru:{' '}
							<Link to={`/profile/${product.customFor._id}`} className="link">
								{product.customFor.displayName}
							</Link>
						</div>
					)}

					<div className="text-3xl">{product.title}</div>

					<div className="flex items-center">
						{rating > 0 && (
							<div className="flex items-center mt-1" onClick={scrollToReviews}>
								<RatingStars rating={rating} size={20} />
								<span className="ml-2">{rating}</span>
								<span className="ml-2 text-secondary mr-4">({receivedReviews})</span>
							</div>
						)}

						{user && user._id === product.artist._id && (
							<>
								<RiHeartFill className="text-red" />
								<span className="ml-1 text-red mr-4">{product.likes}</span>
							</>
						)}

						{user && user._id === product.artist._id && (
							<>
								<RiEyeFill className="text-secondary" />
								<span className="ml-1 text-secondary">{product.views}</span>
							</>
						)}
					</div>

					<div className="flex items-end mt-5 mb-6">
						{/* <p className="font-semibold"> */}
						<span className="text-4xl font-semibold">{formatNumber(finalPrice)}</span>
						<span className="text-lg mb-1 ml-1"> Lei</span>
						{/* </p> */}
						{product.originalPrice && (
							<>
								<p className="text-red line-through ml-4 text-lg mb-1">
									{formatNumber(originalPrice)} Lei
								</p>
								<span className="text-red ml-4 text-sm mb-1.5">
									{Math.ceil(((originalPrice - finalPrice) / originalPrice) * 100)}%
									REDUCERE
								</span>
							</>
						)}
					</div>

					{/* Variations */}
					<div className="mb-5">
						{variations.map((variation) => (
							<VariationSelect
								key={variation._id}
								variation={variation}
								containerClassName="!mb-2"
								onSelect={(option) => selectVariationOption(variation, option)}
								selectedOption={variation.selectedOption?.value}
							/>
						))}
					</div>

					{/* Personalization input */}
					{product.allowsPersonalization && (
						<PersonalizationPanel
							instructions={product.personalizationText}
							text={personalizationText}
							setText={setPersonalizationText}
							images={personalizationImages}
							setImages={setPersonalizationImages}
						/>
					)}

					{(isAdmin(user) || product.artist._id === user?._id) && (
						<div className="flex mt-4">
							<Button
								containerClassName="warning-light"
								text={product.publiclyListed ? 'Ascunde' : 'Listează public'}
								icon={
									product.publiclyListed ? (
										<RiEyeOffFill className="mr-2" size={18} />
									) : (
										<RiEyeFill className="mr-2" size={18} />
									)
								}
								onClick={onTogglePubliclyListed}
								loading={loadingState === LISTING_PRODUCT}
							/>

							<Button
								containerClassName="danger-light ml-4"
								text="Șterge"
								icon={<RiDeleteBin2Fill className="mr-2" size={18} />}
								onClick={onDeleteProduct}
								loading={loadingState === DELETING_PRODUCT}
							/>
						</div>
					)}

					<div className="mt-3">
						{product.artist._id === user?._id ? (
							<Button
								containerClassName="w-full primary font-semibold !py-4"
								text="Editează"
								icon={<RiEdit2Fill size={19} className="mr-2" />}
								onClick={onEditProduct}
							/>
						) : product.stock > 0 ? (
							<Button
								type="submit"
								containerClassName="w-full dark font-semibold !py-4"
								text="Adaugă în coș"
								icon={<RiShoppingCartFill size={19} className="mr-2" />}
								onClick={onAddToCart}
								loading={loadingState === ADDING_TO_CART}
							/>
						) : (
							<Button
								containerClassName="w-full danger-light font-semibold !py-4"
								text="Stoc epuizat"
								icon={<RiShoppingCartFill size={19} className="mr-2" />}
							/>
						)}
					</div>

					<div className="mt-5">
						<Collapsible title="Livrare" contentClassName="!py-3" openByDefault={!isMobile}>
							<div className="flex justify-between items-start flex-wrap">
								<div className="mb-1 xl:mb-0">
									<p className="mb-1">
										Mod de livrare:{' '}
										<span className="text-secondary">{product.shippingDetails}</span>
									</p>
									<p className="">
										Livrare estimată:{' '}
										<span className="text-secondary">
											{product.estimatedDelivery.minDays} -{' '}
											{product.estimatedDelivery.maxDays} zile
										</span>
									</p>
								</div>

								<div className="flex items-center text-secondary">
									<RiTruckFill size={24} className="" />
									<div className="ml-2">
										{product.shippingCost === 0
											? 'Livrare gratuită'
											: `${product.shippingCost} Lei`}
									</div>
								</div>
							</div>
						</Collapsible>

						<Collapsible
							title="Detalii"
							contentClassName="whitespace-pre-line"
							openByDefault={!isMobile}
							isProductDetails
						>
							{product.description}
						</Collapsible>
						<Collapsible
							title="Beneficii"
							contentClassName="text-md p-3"
							openByDefault={!isMobile}
						>
							<div className="flex items-center mb-2">
								<RiRefund2Line size={22} className="text-darkest-gray mr-2" />
								<span className="text-darkest-gray">
									Retur 14 zile la produsele nepersonalizate
								</span>
							</div>
							<div className="flex items-center mb-2">
								<AiOutlineSafety size={22} className="text-darkest-gray mr-2" />
								<span className="text-darkest-gray">Protecția cumpărătorului </span>
							</div>
							<div className="flex items-center mb-2">
								<RiSecurePaymentLine size={22} className="text-darkest-gray mr-2" />
								<span className="text-darkest-gray">Plată securizată online</span>
							</div>
							{product.acceptCashOnDelivery && (
								<div className="flex items-center">
									<RiHandCoinLine size={22} className="text-darkest-gray mr-2" />
									<span className="text-darkest-gray">Plată numerar la livrare</span>
								</div>
							)}
						</Collapsible>
					</div>

					{isMobile && <ArtistPanel className="mt-10" artist={product.artist} />}

					<ContactArtistButton artist={product.artist} />

					<ReviewsContainer
						id="reviews-container-mobile"
						className="mt-8 md:hidden"
						data={reviewsContainerData}
					/>
				</div>
			</div>

			{artistProducts?.length > 2 && (
				<ProductsSwiper
					products={artistProducts}
					className="mt-12"
					name={`De la ${product.artist.displayName}`}
					linkToMore={`/artist/${product.artist.username}`}
					isMobile={isMobile}
				/>
			)}

			{similarProducts?.length > 2 && (
				<ProductsSwiper
					products={similarProducts}
					className="mt-12"
					name="Din aceeași categorie"
					linkToMore={`/products/category/${product.category}`}
					isMobile={isMobile}
				/>
			)}
		</div>
	);
}
