import UIModal from './UIModal';
import Button from 'components/Button';
import { Link, useNavigate } from 'react-router-dom';

export default function ProductInCartModal({ onRequestClose, product }) {
	const navigate = useNavigate();

	return (
		<UIModal
			name="product-in-cart"
			className="p-4 w-full max-w-md"
			containerClassName="px-2"
			onRequestClose={onRequestClose}
			closeOnContainerClick={true}
		>
			<p className="font-semibold text-xl">Produsul a fost adăugat în coș</p>

			<hr className="mt-3 mb-5 border-eee" />

			<div className="flex items-center">
				<Link to={`/products/${product.slug}`} className="w-16 h-16 shrink-0 mr-3 bg-eee rounded-sm">
					<img src={product.thumbnail} className="w-full rounded-sm" alt={product.title} />
				</Link>
				<div className="w-full">
					<Link
						to={`/products/${product.slug}`}
						className="mb-1 line-clamp-2 text-md md:text-base w-fit"
					>
						{product.title}
					</Link>
				</div>
			</div>

			<hr className="mt-5 mb-4 border-eee" />

			<div className="flex justify-between">
				<Button containerClassName="light" text="Înapoi" onClick={onRequestClose} />
				<Button containerClassName="dark" text="Vezi detalii coș" onClick={() => navigate('/cart')} />
			</div>
		</UIModal>
	);
}
