import { useEffect, useMemo, useState } from 'react';

const decodeParams = (params, paramName) => {
	return params.get(paramName)?.split('+') || [];
};

const encodeFilters = (filters) => {
	return filters.join('+');
};

export default function useFilters(paramName, searchParams, setSearchParams) {
	const initialParams = useMemo(() => decodeParams(searchParams, paramName), [paramName]);
	const [filters, setFilters] = useState(initialParams);

	const updateFilters = (newFilters) => {
		setSearchParams((currentParams) => {
			if (newFilters.length) {
				currentParams.set(paramName, encodeFilters(newFilters));
			} else {
				if (currentParams.has(paramName)) {
					currentParams.delete(paramName);
				}
			}

			return currentParams;
		});
	};

	const addFilter = (f) => {
		if (!filters.includes(f)) {
			updateFilters([...filters, f]);
		}
	};

	const removeFilter = (f) => {
		if (filters.includes(f)) {
			updateFilters(filters.filter((x) => x !== f));
		}
	};

	const toggleFilter = (f) => {
		if (filters.includes(f)) {
			updateFilters(filters.filter((x) => x !== f)); // remove
		} else {
			updateFilters([...filters, f]); // add
		}
	};

	const clearFilters = () => {
		updateFilters([]);
	};

	const rawFilters = searchParams.get(paramName);
	useEffect(() => {
		setFilters(decodeParams(searchParams, paramName));
	}, [rawFilters]);

	return { filters, addFilter, removeFilter, toggleFilter, clearFilters };
}
