import Button from 'components/Button';
import ReactGA from 'react-ga4';
import LoadingSpinner from 'components/LoadingSpinner';
import UIModal from 'components/modals/UIModal';
import { InputTextArea } from 'components/Input';
import {
	MAX_PHOTO_SIZE,
	MAX_PHOTO_MB_SIZE,
	MAX_PERSONALIZATION_IMAGES_IN_CART,
} from 'custom-studio-constants';
import { useState } from 'react';
import { RiDeleteBin2Line, RiImageAddFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';

export default function PersonalizationPanel({ instructions, text, setText, images, setImages }) {
	const [previewImage, setPreviewImage] = useState(null);
	const [loadingCounter, setLoadingCounter] = useState(0);

	const onAddImages = (e) => {
		const files = e.target.files;
		let counter = images.length;

		if (!files) return;
		if (counter + files.length > MAX_PERSONALIZATION_IMAGES_IN_CART) {
			toast.warning(`Sunt permise maxim ${MAX_PERSONALIZATION_IMAGES_IN_CART} imagini!`);
		}

		ReactGA.event({ action: 'Add personalization images', category: 'Cart' });

		for (const file of files) {
			if (++counter > MAX_PERSONALIZATION_IMAGES_IN_CART) {
				break;
			}
			if (file.size > MAX_PHOTO_SIZE) {
				toast.error(`O imagine depășește ${MAX_PHOTO_MB_SIZE} MB.`);
				continue;
			}
			if (!file.type.startsWith('image/')) {
				toast.error('Sunt permise doar imagini.');
				continue;
			}

			try {
				const fileReader = new FileReader();
				fileReader.onloadend = (e) => {
					setImages((prev) => [
						...prev,
						{
							file,
							id: `${file.name}-${Date.now()}`,
							data: e.target.result,
						},
					]);
					setLoadingCounter((prev) => prev - 1);
				};
				setLoadingCounter((prev) => prev + 1);
				fileReader.readAsDataURL(file);
			} catch (err) {
				console.error(err);
				toast.error('O imagine selectată este invalidă.');
				captureException(err);
			}
		}
	};

	const onRemoveImage = (id) => {
		ReactGA.event({ action: 'Remove personalization images', category: 'Cart' });
		setImages((prev) => prev.filter((img) => img.id !== id));
	};

	return (
		<div className="relative">
			<p className="mb-2 font-semibold">Personalizează</p>
			<p className="whitespace-pre-line text-secondary mb-2 text-md">{instructions}</p>
			<InputTextArea
				autoResizeHeight={true}
				minRows={2}
				className="custom-scroll"
				containerClassName="!mb-0"
				name="personalizationText"
				placeHolder="Scrie aici detaliile dorite..."
				value={text}
				onChange={(e) => setText(e.target.value)}
			/>

			<input
				id="add-personalization-images"
				type="file"
				accept=".png,.jpg,.jpeg"
				hidden
				multiple={MAX_PERSONALIZATION_IMAGES_IN_CART > 1}
				value=""
				readOnly
				onChange={onAddImages}
			/>

			<label
				htmlFor="add-personalization-images"
				className="absolute right-0 top-0.5 flex items-center cursor-pointer link"
			>
				{loadingCounter === 0 ? (
					<>
						<RiImageAddFill className="mr-1.5" />
						<span className="text-md">Adaugă o imagine</span>
					</>
				) : (
					<LoadingSpinner className="text-blue" />
				)}
			</label>

			<div className="flex flex-wrap">
				{images.map((img) => (
					<div
						id={img.id}
						key={img.id}
						className="mr-1.5 mb-1.5 cursor-pointer select-none"
						onClick={() => setPreviewImage(img)}
					>
						<img src={img.data} className="product-personalization-image-preview" />
					</div>
				))}
			</div>

			{previewImage && (
				<UIModal
					name="image-preview"
					className="image-picker-preview-modal"
					onRequestClose={() => setPreviewImage(null)}
					closeOnContainerClick
				>
					<img src={previewImage.data} className="contain" />

					<Button
						containerClassName="image-preview-delete-button"
						text="Șterge"
						icon={<RiDeleteBin2Line className="mr-2" size={20} />}
						onClick={() => {
							onRemoveImage(previewImage.id);
							setPreviewImage(null);
						}}
					/>
				</UIModal>
			)}
		</div>
	);
}
