import { captureMessage } from '@sentry/react';

export default function Label({ text, className = '', type }) {
	if (!type) {
		console.error('Label must have a type');
		captureMessage('Label must have a type');
		type = 'info';
	}

	let theme = '';
	if (type === 'success') theme = 'success-light';
	else if (type === 'danger') theme = 'danger-light';
	else if (type === 'warning') theme = 'warning-light';
	else if (type === 'alert') theme = 'bg-yellow text-white';
	else if (type === 'primary') theme = 'primary-light';
	else if (type === 'info') theme = 'info-light';
	else if (type === 'light') theme = 'light';
	else if (type === 'processing') theme = 'bg-light-cyan text-cyan';

	return (
		<span
			className={`cursor-default inline-block ${theme} px-3 py-1 rounded-md text-sm font-semibold w-fit ${className}`}
		>
			{text}
		</span>
	);
}
