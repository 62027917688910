import { Link } from 'react-router-dom';

export default function SubCategoryCard({ src, subcategory, className = '' }) {
	return (
		<Link
			to={`/products/subcategory/${subcategory}`}
			className={`home-subcategory-card flex flex-col ${className}`}
		>
			<div>
				<img src={src} className="home-subcategory-image" alt={subcategory} />
			</div>
			<div className="font-semibold text-center xl:text-lg flex justify-center py-4">{subcategory}</div>
		</Link>
	);
}
