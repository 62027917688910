import { useEffect, useState } from 'react';

export default function useTidioChatApi() {
	const [tidioChatApi, setTidioChatApi] = useState(null);

	useEffect(() => {
		function onTidioChatApiReady() {
			setTidioChatApi(window.tidioChatApi);
		}

		if (window.tidioChatApi) {
			window.tidioChatApi.on('ready', onTidioChatApiReady);
		} else {
			document.addEventListener('tidioChat-ready', onTidioChatApiReady);
			return () => document.removeEventListener('tidioChat-ready', onTidioChatApiReady);
		}
	}, []);

	return tidioChatApi;
}
