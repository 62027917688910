import useSWRImmutable from 'swr/immutable';
import ArtistCard from '../ArtistCard';
import { get } from 'api/_methods';
import { useMemo, useState } from 'react';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArtistCardSkeleton } from '../../utils/skeletons';

export default function PopularArtists() {
	const [swiper, setSwiper] = useState(null);
	const { data } = useSWRImmutable('/popular-artists', get);

	const artists = useMemo(() => {
		if (!data || data.error) return undefined;
		return data;
	}, [data]);

	const renderArtists = () => {
		if (!artists) {
			return renderLoadingArtists(5);
		}
		return artists.map((a) => (
			<SwiperSlide key={a._id}>
				<ArtistCard artist={a} />
			</SwiperSlide>
		));
	};

	return (
		<section className="mt-8">
			<div className="flex justify-between mb-4 items-center">
				<h3 className="font-semibold">Magazine populare</h3>
				<div className="flex text-secondary">
					<div className="swiper-btn mr-2" onClick={() => swiper.slidePrev()}>
						<RiArrowLeftSLine size={24} />
					</div>

					<div className="swiper-btn" onClick={() => swiper.slideNext()}>
						<RiArrowRightSLine size={24} />
					</div>
				</div>
			</div>

			<Swiper
				id="home-artists-swiper"
				className="py-2 justify-center"
				slidesPerView={'auto'}
				onSwiper={setSwiper}
			>
				{renderArtists()}
			</Swiper>
		</section>
	);
}

function renderLoadingArtists(count) {
	const arr = [];

	for (let i = 0; i < count; ++i) {
		arr.push(
			<SwiperSlide className="mr-4 w-fit" key={i}>
				<ArtistCardSkeleton />
			</SwiperSlide>
		);
	}

	return arr;
}
