import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { RiStarFill } from 'react-icons/ri';
import { getProductStatusType } from '../utils/status';
import { formatNumber, formatRating } from '../utils/format';
import Label from './Label';
import ResponsiveImage from './ResponsiveImage';

function ProductCard({ product, status, className = '' }) {
	const rating = useMemo(() => formatRating(product.averageRating), [product]);
	const price = useMemo(() => formatNumber(product.price), [product]);
	const originalPrice = useMemo(
		() => product.originalPrice && formatNumber(product.originalPrice),
		[product]
	);

	return (
		<Link to={`/products/${product.slug}`} className={`product-card ${className}`}>
			<ResponsiveImage
				className="sm:rounded-md"
				src={product.thumbnail}
				alt={product.title}
				loading="lazy"
			/>

			<div className="px-2 pb-1 sm:px-0 sm:pb-0 overflow-hidden whitespace-nowrap">
				<p className="product-title">{product.title}</p>

				{status ? (
					<Label text={status} type={getProductStatusType(status)} />
				) : (
					<p className="text-light-secondary text-sm">{product.artist.displayName}</p>
				)}

				<div className="flex items-end justify-between">
					<div className="flex">
						<RiStarFill size={19} className={rating ? 'text-yellow' : 'text-[#ddd]'} />
						{rating !== 0 && <div className="ml-1 text-md"> {rating}</div>}
					</div>
					<div className="flex items-end">
						{originalPrice && (
							<div className="text-red line-through mr-2 text-sm">{originalPrice} Lei</div>
						)}
						<div className="text-blue font-semibold">{price} Lei</div>
					</div>
				</div>
			</div>
		</Link>
	);
}

export default memo(ProductCard);
