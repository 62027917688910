import { CourierLink, OrderStatus, PaymentMethod } from 'custom-studio-constants';
import OrderStatusConfirmationModal, { OrderConfirmationType } from './StatusChangeConfirmationModal';
import { formatNumber, formatShippingCost } from 'utils/format';
import { get, post } from '../api/_methods';
import { useContext, useEffect, useMemo, useState } from 'react';

import AskForInvoiceAlert from './AskForInvoiceAlert';
import Button from './Button';
import CartItem from './CartItem';
import Label from './Label';
import { Link } from 'react-router-dom';
import UserContext from '../context/UserContext';
import WhiteContainer from './WhiteContainer';
import { captureMessage } from '@sentry/react';
import { getOrderStatusType } from 'utils/status';
import isAdmin from '../utils/isAdmin';
import { pick } from '../utils/utils';
import { toast } from 'react-toastify';
import useSWRImmutable from 'swr/immutable';

export default function SubOrder({
	_id,
	orderId, // not available when in cart
	status,
	productsPrice,
	shippingCost,
	discount,
	total,
	artist: artistId,
	artistName,
	clientId,
	clientName,
	cartItems,
	reviewToken,
	courier,
	awb,
	payedOutToSeller,
	paymentMethod,
	isInCart = false, // the user is viewing it in his cart
	isArtistView = false, // the seller is viewing it
}) {
	const { user } = useContext(UserContext);
	const [confirmationType, setConfirmationType] = useState(null);
	const { data: artistData } = useSWRImmutable(isAdmin(user) ? `/admin/users/${artistId}` : null, get);

	const linkToProfile = isArtistView ? `/profile/${clientId}` : `/artist/${artistId}`;

	const shouldAskForInvoice = useMemo(
		() => (artistData?.cui || (isArtistView && user.cui)) && !payedOutToSeller,
		[artistData, user, isArtistView, payedOutToSeller]
	);

	const markAsPayedOut = async () => {
		const res = await post(`/admin/orders/${orderId}/${_id}/payedOut`);
		if (res.error) {
			toast.error(res.error);
		} else {
			window.location.reload();
			toast.success(res.message);
		}
	};

	const renderActions = () => (
		<div className="flex justify-between flex-row-reverse -mt-1 mb-10">
			{confirmationType && (
				<OrderStatusConfirmationModal
					type={confirmationType}
					onRequestClose={() => setConfirmationType(null)}
					orderId={orderId}
					artistId={artistId}
					shouldAskForInvoice={shouldAskForInvoice}
					subOrderTotal={total}
				/>
			)}

			{status === OrderStatus.AwaitingConfirmation && (
				<Button
					text="Acceptă comanda"
					containerClassName="primary"
					onClick={() => setConfirmationType(OrderConfirmationType.AcceptOrder)}
				/>
			)}
			{status === OrderStatus.Processing && (
				<Button
					text="Comanda a fost trimisă"
					containerClassName="primary"
					onClick={() => setConfirmationType(OrderConfirmationType.ShipOrder)}
				/>
			)}
			{status === OrderStatus.Shipped && (
				<Button
					text="Comanda a fost finalizată"
					containerClassName="success"
					onClick={() => setConfirmationType(OrderConfirmationType.FinishOrder)}
				/>
			)}
			{status !== OrderStatus.Cancelled && status !== OrderStatus.AwaitingPayment && (
				<Button
					text="Anulează"
					containerClassName="bg-white text-red"
					onClick={() => setConfirmationType(OrderConfirmationType.CancelOrder)}
				/>
			)}
		</div>
	);

	useEffect(() => {
		if (isArtistView && isInCart) {
			console.error("isArtistView and isInCart shouldn't be both true!");
			captureMessage("isArtistView and isInCart shouldn't be both true!");
		}
	}, [isArtistView, isInCart]);

	return (
		<>
			<WhiteContainer className="px-4 md:px-6 py-5 mb-5">
				{!isInCart && (
					<>
						<p className="flex justify-between items-center flex-wrap">
							<span className="mr-2">Status comandă:</span>
							<Label text={status} type={getOrderStatusType(status)} />
						</p>

						<hr className="border-eee my-4" />
					</>
				)}

				{isArtistView &&
					status === OrderStatus.Finalized &&
					paymentMethod === PaymentMethod.Online && (
						<>
							<p className="flex justify-between items-center flex-wrap">
								<span className="mr-2">Status plată către vânzător:</span>
								<div>
									{isAdmin(user) && !payedOutToSeller && (
										<Button
											size="small"
											text="Mark as payed"
											containerClassName="link"
											onClick={markAsPayedOut}
										/>
									)}
									<Label
										text={payedOutToSeller ? 'Plătită' : 'În așteptare'}
										type={payedOutToSeller ? 'success' : 'warning'}
									/>
								</div>
							</p>

							{shouldAskForInvoice && <AskForInvoiceAlert subOrderTotal={total} />}

							<hr className="border-eee my-4" />
						</>
					)}

				<div className="flex justify-between flex-wrap">
					<span className="mr-2">
						{isArtistView ? 'Produse cumpărate de:' : 'Produse vândute de:'}
					</span>
					<Link to={linkToProfile} className="link cursor-pointer">
						{isArtistView ? clientName : artistName}
					</Link>
				</div>

				<hr className="border-eee my-4" />

				{courier && awb && (
					<>
						<div className="flex justify-between flex-wrap">
							<span className="mr-2">Urmărire AWB:</span>
							<Link
								to={CourierLink[courier](awb)}
								className="link cursor-pointer break-all"
								target="_blank"
								referrerPolicy="noreferrer"
							>
								{awb}
							</Link>
						</div>

						<hr className="border-eee my-4" />
					</>
				)}

				<div>
					{cartItems.map((item) => (
						<CartItem
							key={item._id || item.id}
							isInCart={isInCart}
							item={item}
							reviewToken={isAdmin(user) && reviewToken}
						/>
					))}
				</div>

				<div className="md:flex md:justify-end">
					<div className="w-full md:max-w-[180px]">
						<div className="flex justify-between">
							<p className="mb-0.5">Cost produse:</p>
							<p className="mb-0.5">{formatNumber(productsPrice)} Lei</p>
						</div>
						<div className="flex justify-between">
							<p className="mb-0.5">Cost livrare:</p>
							<p className="mb-0.5">{formatShippingCost(shippingCost)}</p>
						</div>
						{!!discount && (
							<div className="flex justify-between">
								<p className="mb-0.5 text-green">Cod reducere:</p>
								<p className="mb-1 text-green">-{formatNumber(discount)} Lei</p>
							</div>
						)}
						<div className="flex justify-between">
							<p className="font-semibold">Total:</p>
							<p className="font-semibold">{formatNumber(total)} Lei</p>
						</div>
					</div>
				</div>
			</WhiteContainer>

			{!isInCart && isAdmin(user) && artistData && (
				<WhiteContainer className="p-4 mb-6">
					<h4 className="flex items-center">
						Date vânzător <Label type="info" text="Admin only" className="ml-2 text-xs" />
					</h4>
					<pre className="mt-4 p-3 rounded-md bg-light-gray overflow-x-auto">
						{JSON.stringify(
							pick(artistData, 'displayName cui iban address fullName email'),
							undefined,
							2
						)}
					</pre>
				</WhiteContainer>
			)}

			{isArtistView && renderActions()}
		</>
	);
}
