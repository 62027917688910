import computeRevenueForSeller from '../utils/computeRevenueForSeller';
import { formatNumber } from '../utils/format';

export default function AskForInvoiceAlert({ subOrderTotal }) {
	return (
		<div className="bg-light-orange text-orange px-2 py-2 rounded-md mt-2">
			⚠️ Nu uitați să ne trimiteți o factură de {formatNumber(computeRevenueForSeller(subOrderTotal))}{' '}
			lei pentru a transfera banii în contul dvs. Datele noastre de contact sunt la finalul paginii.
		</div>
	);
}
