import { useContext, useRef } from 'react';
import UserContext from '../../context/UserContext';
import {
	RiUserLine,
	RiUserFill,
	RiLogoutCircleRLine,
	RiSettings3Line,
	RiSettings3Fill,
	RiShoppingBasketLine,
	RiShoppingBasketFill,
	RiListCheck2,
	RiStarLine,
	RiStarFill,
} from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import WhiteContainer from '../../components/WhiteContainer';
import isArtist from 'utils/isArtist';

function MenuButton({ icon, text, onClick, isActive, className = 'text-primary' }) {
	return (
		<div
			className={`flex items-center py-3 px-2.5 rounded-md cursor-pointer hover:bg-light-gray ${className}`}
			onClick={onClick}
		>
			<div className={isActive ? 'text-blue' : 'text-dark-secondary'}>{icon}</div>
			<div className={`ml-2 ${isActive ? 'text-blue' : ''}`}>{text}</div>
		</div>
	);
}

export default function UserMenu() {
	const navMenuRef = useRef();
	const { user, logout } = useContext(UserContext);
	const iconSize = 22;

	return (
		<WhiteContainer forwardRef={navMenuRef} className="w-52 p-2">
			<NavLink to="profile">
				{({ isActive }) => (
					<MenuButton
						icon={isActive ? <RiUserFill size={iconSize} /> : <RiUserLine size={iconSize} />}
						text="Profil"
						isActive={isActive}
					/>
				)}
			</NavLink>

			{isArtist(user) && (
				<NavLink to="products">
					{({ isActive }) => (
						<MenuButton
							icon={<RiListCheck2 size={iconSize} />}
							text="Produse"
							isActive={isActive}
						/>
					)}
				</NavLink>
			)}

			<NavLink to="orders">
				{({ isActive }) => (
					<MenuButton
						icon={
							isActive ? (
								<RiShoppingBasketFill size={iconSize} />
							) : (
								<RiShoppingBasketLine size={iconSize} />
							)
						}
						text="Comenzi"
						isActive={isActive}
					/>
				)}
			</NavLink>

			<NavLink to="reviews">
				{({ isActive }) => (
					<MenuButton
						icon={
							isActive ? <RiStarFill size={iconSize - 1} /> : <RiStarLine size={iconSize - 1} />
						}
						text="Recenzii"
						isActive={isActive}
					/>
				)}
			</NavLink>

			<NavLink to="settings">
				{({ isActive }) => (
					<MenuButton
						icon={
							isActive ? (
								<RiSettings3Fill size={iconSize} />
							) : (
								<RiSettings3Line size={iconSize} />
							)
						}
						text="Setări cont"
						isActive={isActive}
					/>
				)}
			</NavLink>

			<hr className="my-1 border-eee" />

			<MenuButton
				icon={<RiLogoutCircleRLine size={iconSize} className="text-secondary" />}
				text="Deconectare"
				onClick={logout}
				className="!text-secondary"
			/>
		</WhiteContainer>
	);
}
