import Notifier from './Notifier';

class ChatNotifier {
	notificationLimit = 7000; // notify every 7 seconds
	lastNotificationTime = 0;

	notify({ body, icon }) {
		if (Date.now() - this.lastNotificationTime < this.notificationLimit) return;
		this.lastNotificationTime = Date.now();

		if (document.visibilityState === 'visible' || !Notifier.hasPermission()) {
			if (!this.notificationSound) {
				this.notificationSound = new Audio(
					'https://customstudio-resources.s3.eu-central-1.amazonaws.com/chat-notification.mp3'
				);
			}
			this.notificationSound.play();
		} else {
			Notifier.showNotification({
				title: 'Custom Studio',
				body,
				icon,
			});
		}
	}
}

export default new ChatNotifier();
