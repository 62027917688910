import { useEffect, useState, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	RiArrowDropLeftLine,
	RiArrowDropRightLine,
	RiDeleteBack2Line,
	RiMoreLine,
	RiSearchLine,
} from 'react-icons/ri';
import { ProductCardSkeleton } from 'utils/skeletons';
import { CheckBox, RadioBox } from 'components/Input';
import useSWRImmutable from 'swr/immutable';
import useFilter from 'hooks/useFilter';
import useScrollToTop from 'hooks/useScrollToTop';
import useGrayBackground from 'hooks/useGrayBackground';
import WhiteContainer from 'components/WhiteContainer';
import Collapsible from 'components/Collapsible';
import ProductCard from 'components/ProductCard';
import usePageTitle from 'hooks/usePageTitle';
import { get } from 'api/_methods';
import CategoryLink from 'components/CategoryLink';
import { removeDuplicates } from 'utils/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Category } from 'custom-studio-constants';

const PRODUCTS_PER_PAGE = 48; // should be divisible by 3 and 4 to fill every line on the page

const sortFunctions = {
	default: (a, b) => 0,
	views: (a, b) => b.views - a.views,
	sales: (a, b) => b.sales - a.sales,
	'rating-desc': (a, b) => b.averageRating - a.averageRating,
};

export default function SearchProducts() {
	const { key } = useLocation();
	return <_SearchProducts key={key} />;
}

function _SearchProducts() {
	useScrollToTop();
	useGrayBackground();
	const searchInputRef = useRef();
	const navigate = useNavigate();
	const { category, subcategory, searchQuery } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [inStockOnly, setInStockOnly] = useFilter('stockOnly', searchParams, setSearchParams);
	const [sortType, setSortType] = useFilter('sort', searchParams, setSearchParams, 'default');
	const [selectedSubcategories, setSelectedSubcategories] = useState(subcategory ? [subcategory] : []);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const totalPages = Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE) || 1;
	const page = parseInt(Math.min(Math.max(searchParams.get('page'), 1), totalPages)) || 1;
	const sortFn = sortFunctions[sortType];

	const { data } = useSWRImmutable(
		`/search-products?${searchQuery ? `search=${searchQuery}` : ''}${
			category ? `&category=${category}` : ''
		}`,
		get
	);

	const products = useMemo(() => {
		if (!data) return null;
		if (data.error) {
			toast.error(data.error);
			return null;
		}
		return data;
	}, [data]);

	const allSubcategories = useMemo(
		() => removeDuplicates(filteredProducts.map((p) => p.subcategories || []).flat()),
		[filteredProducts]
	);

	const onSort = (e) => {
		setSortType(e.target.value);
		setFilteredProducts([...filteredProducts].sort(sortFunctions[e.target.value]));
	};

	const onSearch = async () => {
		const search = searchInputRef.current.value.trim();

		if (search === searchQuery) return;
		if (!search && !searchQuery) return;

		if (search.length === 0) {
			navigate('/products');
		} else {
			window.ttq.track('Search', { query: search });
			navigate(`/products/search/${search}`);
		}
	};

	const onToggleSubcategory = (subcat) => {
		if (selectedSubcategories.includes(subcat)) {
			setSelectedSubcategories(selectedSubcategories.filter((ss) => ss !== subcat));
		} else {
			setSelectedSubcategories([...selectedSubcategories, subcat]);
		}
	};

	const onChangePage = (newPage) => {
		searchParams.set('page', newPage);
		setSearchParams(searchParams);
	};

	const getMiddlePages = () => {
		if (totalPages === 2) return [];
		if (totalPages === 3) return [2];
		if (totalPages === 4) return [2, 3];
		if (page <= 2) return [2, 3];
		if (page >= totalPages - 1) return [totalPages - 2, totalPages - 1];
		return [page - 1, page, page + 1];
	};

	// Dynamic page title
	usePageTitle(
		category ? `${category} - handmade și personalizate` : 'Cumpără obiecte handmade și personalizate'
	);

	// Update filtered products when the filters change
	useEffect(() => {
		if (!products) return;

		const newFilteredProducts = products.filter((p) => {
			if (inStockOnly && p.stock <= 0) return false;
			if (selectedSubcategories.length) {
				const includesAllSubcategories = selectedSubcategories.every((ss) =>
					p.subcategories?.includes(ss)
				);
				if (!includesAllSubcategories) return false;
			}
			return true;
		});

		setFilteredProducts(newFilteredProducts.sort(sortFn));
	}, [products, inStockOnly, selectedSubcategories]);

	const renderedCategoryLinks = (
		<>
			{Object.values(Category).map((category) => (
				<CategoryLink key={category} category={category} />
			))}

			<hr className="my-4 border-gray" />

			<CheckBox
				key={'in-stock'}
				label="În stoc"
				onChange={(e) => setInStockOnly(e.target.checked)}
				checked={!!inStockOnly}
				className="mt-1 ml-3"
			/>
		</>
	);

	const renderSortButtons = (group) => (
		<>
			<RadioBox
				label="Popularitate"
				className="mb-1"
				name={group}
				value="sales"
				checked={sortType === 'sales'}
				onCheck={onSort}
			/>
			<RadioBox
				label="Rating"
				name={group}
				value="rating-desc"
				checked={sortType === 'rating-desc'}
				onCheck={onSort}
			/>
		</>
	);

	const renderSubcategoriesHeader = () => {
		const isActive = (subcat) => selectedSubcategories.includes(subcat);
		return (
			<Swiper
				slidesPerView={'auto'}
				spaceBetween={8}
				className={`mb-4 ${!category && !searchQuery && 'md:py-2'}`}
			>
				{allSubcategories.map((subcat) => (
					<SwiperSlide key={subcat}>
						<div
							className={`subcategory-btn ${isActive(subcat) ? 'active' : ''}`}
							onClick={() => onToggleSubcategory(subcat)}
						>
							{subcat}
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		);
	};

	const renderSubcategories = () => {
		return allSubcategories.map((subcat) => (
			<CheckBox
				key={subcat}
				label={subcat}
				className="mb-1 text-secondary"
				onChange={() => onToggleSubcategory(subcat)}
				checked={selectedSubcategories.includes(subcat)}
			/>
		));
	};

	const renderedProducts = useMemo(() => {
		if (!products) {
			return (
				<>
					<ProductCardSkeleton className="mr-3 mb-4 late-fade-in" />
					<ProductCardSkeleton className="mr-3 mb-4 late-fade-in" />
					<ProductCardSkeleton className="mr-3 mb-4 late-fade-in" />
					<ProductCardSkeleton className="mr-3 mb-4 late-fade-in" />
				</>
			);
		}

		const startIndex = (page - 1) * PRODUCTS_PER_PAGE;
		const endIndex = startIndex + PRODUCTS_PER_PAGE;
		const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

		return paginatedProducts.map((p) => (
			<ProductCard key={p._id} product={p} className="mr-1 sm:mr-3 mb-4" />
		));
	}, [filteredProducts, products, page]);

	return (
		<div className="container !px-2.5 my-4 md:my-6">
			<div className="flex md:items-start flex-col md:flex-row">
				<div className="w-full md:w-56 shrink-0 top-20 mr-4">
					{/* Search */}
					<WhiteContainer className="pl-4 flex md:mb-4 pt-0.5">
						<input
							ref={searchInputRef}
							type="search"
							placeholder="Caută produse"
							className="w-full bg-white py-4 outline-none min-w-0"
							onKeyDown={(e) => {
								if (e.keyCode === 13) onSearch(e);
							}}
							defaultValue={searchQuery}
						/>
						<div
							className="flex items-center px-4 cursor-pointer hover:scale-110 transition-transform text-secondary"
							onClick={onSearch}
						>
							<RiSearchLine size={20} />
						</div>
					</WhiteContainer>
					{/* Desktop Filters */}
					<WhiteContainer className="hidden md:block px-2 py-4 mb-4 ">
						<p className="text-xl mb-3 ml-3">Categorii</p>
						{renderedCategoryLinks}
					</WhiteContainer>
					<WhiteContainer className="hidden md:block px-5 py-4 mb-4">
						<div className="text-xl mb-3">Ordonează</div>
						{renderSortButtons('desktop-sort')}
					</WhiteContainer>
					{allSubcategories.length > 0 && (
						<WhiteContainer className="hidden md:block px-5 py-4 ">
							<div className="text-xl mb-3">Subcategorii</div>
							{renderSubcategories()}
						</WhiteContainer>
					)}
				</div>

				{/* Mobile filters */}
				<div className="md:hidden">
					<Collapsible
						title="Categorii și filtre"
						containerClassName="mr-2 !border-0"
						headerClassName="!bg-white text-secondary font-semibold"
						contentClassName="bg-white pt-2 pb-4 px-1"
					>
						{renderedCategoryLinks}
						<hr className="my-4 border-gray" />
						<div className="px-3">
							<p className="font-semibold text-secondary mb-2">Ordonează</p>
							{renderSortButtons('mobile-sort')}
						</div>
					</Collapsible>
				</div>

				{/* List */}
				<div className="w-full">
					{searchQuery && (
						<div className="flex items-center py-3.5 md:mb-3.5">
							<h4>Căutare după: {searchQuery}</h4>
							<RiDeleteBack2Line
								className="ml-3 mt-1 cursor-pointer hover:text-red shrink-0"
								size={20}
								onClick={() => navigate('/products')}
							/>
						</div>
					)}

					{category && (
						<div className="flex items-center mb-2 md:mb-4 ml-2 md:h-[56.6px]">
							<p className="text-2xl">{category}</p>
							<span className="text-light-secondary ml-2 mt-1.5">
								{products && `(${filteredProducts.length} rezultate)`}
							</span>
						</div>
					)}

					{allSubcategories.length > 0 && renderSubcategoriesHeader()}

					<div className="products-grid mt-2 md:mt-0">{renderedProducts}</div>

					{totalPages > 1 && (
						<div className="my-10 flex items-center justify-center">
							<div
								className={`paginator-btn prev ${page === 1 ? 'disabled' : ''}`}
								onClick={() => onChangePage(page - 1)}
							>
								<RiArrowDropLeftLine size={24} />{' '}
							</div>

							<div
								className={`page-button ${page === 1 ? 'current-page' : ''}`}
								onClick={() => onChangePage(1)}
							>
								{1}
							</div>

							{totalPages > 2 && getMiddlePages()[0] !== 2 && (
								<RiMoreLine className="paginator-more" />
							)}

							{getMiddlePages().map((i) => (
								<div
									key={i}
									className={`page-button ${page === i ? 'current-page' : ''}`}
									onClick={() => onChangePage(i)}
								>
									{i}
								</div>
							))}

							{totalPages > 2 && getMiddlePages().pop() + 1 !== totalPages && (
								<RiMoreLine className="paginator-more" />
							)}

							<div
								className={`page-button ${page === totalPages ? 'current-page' : ''}`}
								onClick={() => onChangePage(totalPages)}
							>
								{totalPages}
							</div>

							<div
								className={`paginator-btn next ${page === totalPages ? 'disabled' : ''}`}
								onClick={() => onChangePage(page + 1)}
							>
								<RiArrowDropRightLine size={24} />{' '}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
