import { LOCAL_SERVER_PORT } from 'custom-studio-constants';

const PROD_API = 'https://api.customstudio.ro';
const LOCAL_API = `http://localhost:${LOCAL_SERVER_PORT}`;
const TEST_API = 'https://test-api.customstudio.ro';

function getAPI() {
	const host = window.location.hostname;
	if (host === 'localhost') return LOCAL_API;
	if (host === 'test.customstudio.ro') return TEST_API;
	if (host === 'customstudio.ro') return PROD_API;
}

export const API = getAPI();
export const RECAPTCHA_KEY = '6Lda_bUhAAAAAJn1ofyznBoKKLXRa50_dkNbuuij';

export const PRODUCT_STATUSES = ['Respins', 'În așteptare', 'Custom', 'Ascuns', 'Stoc epuizat', 'Activ'];

export const DISCORD = 'https://discord.gg/SM6AJjgcme';
export const COMPANY = 'Custom Creative Art S.R.L.';
export const CUI = '46305739';
export const SITE = 'www.customstudio.ro';
export const EMAIL = 'contact@customstudio.ro';
