import { toast } from 'react-toastify';
import { useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { InputTextArea } from '../Input';
import Button from '../Button';
import Form from '../Form';
import ImagePicker from '../ImagePicker';
import UIModal, { UIModalCloseButton } from './UIModal';
import { MAX_REVIEW_IMAGES } from 'custom-studio-constants';
import { addReview } from '../../api/reviewApi';
import { useSearchParams } from 'react-router-dom';

export default function AddReviewModal({ product, onRequestClose }) {
	const [searchParams] = useSearchParams();
	const [givenStars, setGivenStars] = useState(0);
	const [images, setImages] = useState([]);

	const arr = [1, 2, 3, 4, 5];

	const onAddReview = async (formData) => {
		if (images.length > MAX_REVIEW_IMAGES) {
			return toast.error(`Sunt permise maxim ${MAX_REVIEW_IMAGES} imagini.`);
		}

		if (givenStars === 0) {
			return toast.error('Te rog să acorzi o notă făcând click pe o stea.');
		}

		formData.set('reviewToken', searchParams.get('reviewToken'));
		formData.set('rating', givenStars);
		formData.set('productId', product._id);
		formData.set('images', '');

		for (const image of images) {
			formData.append('images', image.file);
		}

		const res = await addReview(formData);
		if (res.error) {
			toast.error(res.error);
		} else {
			toast.success(res.message);
			onRequestClose();
		}
	};

	return (
		<UIModal
			name="add-review"
			className="w-full max-w-2xl p-5 pt-10 sm:p-12"
			onRequestClose={onRequestClose}
			containerClassName="px-2"
			closeOnContainerClick={true}
		>
			<UIModalCloseButton className="!text-[#888] bg-gray !p-2" size={20} onClick={onRequestClose} />

			<div className="flex items-center">
				<img
					src={product.thumbnail}
					className="w-16 h-16 sm:w-20 sm:h-20 rounded-md mr-4"
					alt={product.title}
				/>
				<p>{product.title}</p>
			</div>

			<hr className="border-eee my-5 sm:my-8" />

			<p className="font-semibold">Acordă-i o notă</p>
			<div className="flex mt-2">
				{arr.map((i) =>
					i <= givenStars ? (
						<AiFillStar
							key={`starempty-${i}`}
							size={44}
							className="cursor-pointer text-yellow"
							onClick={() => setGivenStars(i)}
						/>
					) : (
						<AiFillStar
							key={`starfill-${i}`}
							size={44}
							className="cursor-pointer text-[#e7e7e7]"
							onClick={() => setGivenStars(i)}
						/>
					)
				)}
			</div>

			<Form onSubmit={onAddReview}>
				<InputTextArea
					containerClassName="mt-6"
					className="mt-0.5"
					label="Spune-le și celorlalți părerea ta"
					rows={5}
					placeHolder={`Opțional: cum a fost interacțiunea cu vânzătorul?\nCât de mulțumit ești de produs?`}
					name="text"
				/>

				<ImagePicker images={images} setImages={setImages} className="mb-4" />

				<Button type="submit" text="Adaugă recenzia" containerClassName="primary" />
			</Form>
		</UIModal>
	);
}
