export default function useScrollTo(id, { offset = 0, left = 0, behavior = 'smooth' } = {}) {
	return () => {
		const element = document.getElementById(id);
		if (!element) return;

		window.scroll({
			top: document.getElementById(id).offsetTop - offset,
			left,
			behavior,
		});
	};
}
