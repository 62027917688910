import { Link } from 'react-router-dom';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import Avatar from './Avatar';
import RatingStars from './RatingStars';
import { formatDate } from '../utils/format';
import Label from './Label';
import { useState } from 'react';
import UIModal, { UIModalCloseButton } from './modals/UIModal';

function getLabelType(review) {
	if (review.approved) return 'success';
	if (review.denied) return 'danger';
	return 'info';
}

function getLabelText(review) {
	if (review.approved) return 'Activ';
	if (review.denied) return 'Respins';
	return 'În așteptare';
}

export function Review({ review, showStatus, className = '' }) {
	const [previewImage, setPreviewImage] = useState(null);

	return (
		<div className={`flex flex-col ml-2 mb-6 ${className}`}>
			<Link to={`/profile/${review.from._id}`} className="flex items-center mb-2">
				<Avatar name={review.from.displayName} photo={review.from.photo} className="w-11 h-11" />

				<div className="ml-3">
					<div className="flex items-end ">
						<p className="font-semibold">{review.from.displayName}</p>
						<span className="ml-3 text-sm text-secondary">{formatDate(review.createdAt)}</span>
					</div>

					<div className="flex items-center mt-1">
						<RatingStars rating={review.rating} size={17} />

						<div className="relative">
							<RiCheckboxCircleFill
								className="verified-review-mark ml-2 text-green"
								size={17}
							/>
							<div className="verified-review-tooltip">
								<p>Achiziție verificată</p>
							</div>
						</div>

						{showStatus && !review.approved && (
							<Label type={getLabelType(review)} text={getLabelText(review)} className="ml-2" />
						)}
					</div>
				</div>
			</Link>

			{review.product.title && (
				<div className="text-secondary text-sm md:text-base overflow-hidden overflow-ellipsis whitespace-nowrap">
					Pentru:{' '}
					<Link to={`/products/${review.product.slug}`} className="underline">
						{review.product.title}
					</Link>
				</div>
			)}

			<p className="mt-1 whitespace-pre-line">{review.text}</p>

			<div className="flex flex-wrap mt-3">
				{review.images.map((img, index) => (
					<div key={index} className="rounded-sm bg-eee mr-2 mb-2 select-none">
						<img
							src={img}
							className="w-16 h-16 rounded-sm cursor-pointer object-cover"
							onClick={() => setPreviewImage(img)}
							loading="lazy"
							alt="imagine din recenzie"
						/>
					</div>
				))}
			</div>

			{previewImage && (
				<UIModal
					name="review-image-preview"
					onRequestClose={() => setPreviewImage(null)}
					closeOnContainerClick={true}
				>
					<UIModalCloseButton onClick={() => setPreviewImage(null)} theme="dark" />
					<img src={previewImage} className="modal-image-preview" alt="poză din recenzie" />
				</UIModal>
			)}
		</div>
	);
}
