import HomeRoadmap from './HomeRoadmap';
import { Link } from 'react-router-dom';
import { RiArrowRightLine } from 'react-icons/ri';

export default function HomeTitleText() {
	return (
		<div>
			<h1 className="home-title">Ne-am lansat! 🚀</h1>

			<HomeRoadmap />

			<p className="home-subtitle font-semibold">
				Suntem o platformă online dedicată creatorilor de handmade și pasionaților de personalizare.
				Creează-ți propriul magazin online cu 0 costuri și listează articole handmade spre vânzare.
				Noi ne ocupăm de promovare, iar tu de artă.
				<Link to="/about-us" className="link mt-2 ml-2 whitespace-nowrap">
					Despre noi <RiArrowRightLine className="inline-block align-middle mb-0.5" />
				</Link>
			</p>
		</div>
	);
}
