import { useEffect, useState } from 'react';

export default function useIsMobile(breakpoint = 768) {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

	// Determine when we're on a mobile screen
	useEffect(() => {
		const onResize = () => setIsMobile(window.innerWidth <= breakpoint);
		window.addEventListener('resize', onResize);
		onResize();
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return isMobile;
}
