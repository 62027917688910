import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ARTIST, ADMIN } from 'custom-studio-constants';
import Home from './routes/Home';
import ProductPage from './routes/product/Product';
import UserProducts from './routes/user/UserProducts';
import SearchProducts from './routes/SearchProducts';
import AddProduct from './routes/user/AddProduct';
import SearchArtists from './routes/SearchArtists';
import OwnProfile from './routes/user/OwnProfile';
import Cart from './routes/Cart';
import UserDashboard from './routes/user';
import ProtectedRoute from './routes/ProtectedRoute';
import PublicProfile from './routes/PublicProfile';
import EditProfile from './routes/user/EditProfile';
import OpenShop from 'routes/OpenShop/index';
import CheckOut from 'routes/Checkout';
import FAQ from 'routes/FAQ';
import Navbar from './components/navbar/Navbar';
import Footer from './components/Footer';
import OrderPlaced from 'routes/OrderPlaced';
import LoadingSpinner from 'components/LoadingSpinner';

// Lazy components
const AdminDashboard = React.lazy(() => import('./routes/admin'));
const AdminUsers = React.lazy(() => import('./routes/admin/AdminUsers'));
const AdminOrders = React.lazy(() => import('./routes/admin/AdminOrders'));
const AdminReviews = React.lazy(() => import('./routes/admin/AdminReviews'));
const AdminProducts = React.lazy(() => import('./routes/admin/AdminProducts'));
const AdminChats = React.lazy(() => import('./routes/admin/AdminChats'));
const AdminChatView = React.lazy(() => import('./routes/admin/AdminChatView'));
const UnpurchasedCarts = React.lazy(() => import('./routes/admin/UnpurchasedCarts'));
const AboutUs = React.lazy(() => import('./routes/AboutUs'));
const Contact = React.lazy(() => import('./routes/Contact'));
const Gdpr = React.lazy(() => import('./routes/info/GDPR'));
const ForgotPassword = React.lazy(() => import('./routes/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./routes/ResetPassword'));
const CookiesPolicy = React.lazy(() => import('./routes/info/CookiesPolicy'));
const PaymentTypes = React.lazy(() => import('./routes/info/PaymentTypes'));
const FbDataDeletion = React.lazy(() => import('./routes/info/FbDataDeletion'));
const ShippingAndRefundPolicy = React.lazy(() => import('./routes/info/ShippingAndRefundPolicy'));
const TermsOfService = React.lazy(() => import('./routes/info/TermsOfService'));
const Chat = React.lazy(() => import('./routes/chat/Chat'));
const NotFound = React.lazy(() => import('./routes/404'));
const Invoice = React.lazy(() => import('./routes/user/Invoice'));
const UserOrders = React.lazy(() => import('./routes/user/UserOrders'));
const UserOrder = React.lazy(() => import('./routes/user/UserOrder'));
const UserReviews = React.lazy(() => import('./routes/user/UserReviews'));
const UserSettings = React.lazy(() => import('./routes/user/UserSettings'));

function App() {
	return (
		<>
			<div id="view">
				<Navbar />

				<Suspense
					fallback={<LoadingSpinner className="mx-auto mt-12" size={30} scrollToTop={true} />}
				>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/products" element={<SearchProducts />} />
						<Route path="/products/category/:category" element={<SearchProducts />} />
						<Route path="/products/subcategory/:subcategory" element={<SearchProducts />} />
						<Route path="/products/search/:searchQuery" element={<SearchProducts />} />
						<Route path="/products/:slug" element={<ProductPage />} />
						<Route path="/artists" element={<SearchArtists />} />
						<Route path="/profile/:idOrUsername" element={<PublicProfile />} />
						<Route path="/artist/:idOrUsername" element={<PublicProfile />} />
						<Route path="/shop/:idOrUsername" element={<PublicProfile />} />
						<Route path="/cart" element={<Cart />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/invoice" element={<Invoice />} />
						<Route
							path="/open-shop"
							element={
								<ProtectedRoute>
									<OpenShop />
								</ProtectedRoute>
							}
						/>
						<Route path="/checkout" element={<CheckOut />} />
						<Route path="/order-placed" element={<OrderPlaced />} />
						<Route
							path="/chat"
							element={
								<ProtectedRoute>
									<Chat />
								</ProtectedRoute>
							}
						/>

						{/* User routes */}
						<Route
							path="/user"
							element={
								<ProtectedRoute>
									<UserDashboard />
								</ProtectedRoute>
							}
						>
							<Route index element={<OwnProfile />} />
							<Route path="profile" element={<OwnProfile />} />
							<Route path="profile/edit" element={<EditProfile />} />
							<Route
								path="products"
								element={
									<ProtectedRoute role={ARTIST}>
										<UserProducts />
									</ProtectedRoute>
								}
							/>
							<Route
								path="products/:slug/edit"
								element={
									<ProtectedRoute role={ARTIST}>
										<AddProduct />
									</ProtectedRoute>
								}
							/>
							<Route
								path="products/add"
								element={
									<ProtectedRoute role={ARTIST}>
										<AddProduct />
									</ProtectedRoute>
								}
							/>
							<Route path="settings" element={<UserSettings />} />
							<Route path="orders" element={<UserOrders />} />
							<Route path="orders/:number" element={<UserOrder />} />
							<Route path="reviews" element={<UserReviews />} />
						</Route>

						<Route
							path="/admin"
							element={
								<ProtectedRoute role={ADMIN}>
									<AdminDashboard />
								</ProtectedRoute>
							}
						>
							<Route index element={<AdminProducts />} />
							<Route path="products" element={<AdminProducts />} />
							<Route path="users" element={<AdminUsers />} />
							<Route path="orders" element={<AdminOrders />} />
							<Route path="orders/:number" element={<UserOrder />} />
							<Route path="reviews" element={<AdminReviews />} />
							<Route path="chats" element={<AdminChats />} />
							<Route path="chats/:id" element={<AdminChatView />} />
							<Route path="unpurchased-carts" element={<UnpurchasedCarts />} />
						</Route>

						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password/:userId/:token" element={<ResetPassword />} />
						<Route path="/gdpr" element={<Gdpr />} />
						<Route path="/terms-of-service" element={<TermsOfService />} />
						<Route path="/shipping-and-refund" element={<ShippingAndRefundPolicy />} />
						<Route path="/cookies" element={<CookiesPolicy />} />
						<Route path="/payment-types" element={<PaymentTypes />} />
						<Route path="/fb-data-deletion" element={<FbDataDeletion />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>

				<ToastContainer
					position="bottom-center"
					autoClose={2500}
					newestOnTop={false}
					closeOnClick
					draggable
					pauseOnHover
					theme="colored"
				/>
			</div>

			<Footer />
		</>
	);
}

export default App;
