import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { RiAddFill, RiAlertFill, RiInformationFill } from 'react-icons/ri';
import {
	Category,
	MAX_PHOTO_MB_SIZE,
	MAX_PRODUCT_IMAGES,
	MAX_PRODUCT_TITLE_LENGTH,
	MIN_PRODUCT_IMAGES,
	MIN_PRODUCT_TITLE_LENGTH,
	Status,
} from 'custom-studio-constants';
import { InputText, InputTextArea, CheckBox, InputSelect, RadioBox } from 'components/Input';
import { addProduct, editProduct } from 'api/productApi';
import Button from 'components/Button';
import WhiteContainer from 'components/WhiteContainer';
import useOutletTitle from 'hooks/useOutletTitle';
import ImagePicker from 'components/ImagePicker';
import Form from 'components/Form';
import VariationModal from 'components/modals/VariationModal';
import VariationSelect from 'components/VariationSelect';
import isAdmin from 'utils/isAdmin';
import UserContext from 'context/UserContext';

const processDefaultImages = (images) =>
	images.map((img) => ({
		file: new File([], img.replace(/\//g, '[%]')), // makes the form preserve the full url
		id: img,
		data: img,
	}));

export default function AddProduct() {
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [product] = useState(location.state?.product);
	const [customFor] = useState(location.state?.customFor);
	const [images, setImages] = useState(processDefaultImages(product?.images || []));
	const [containImages, setContainImages] = useState(false);
	const [variations, setVariations] = useState(product?.variations || []);
	const [showVariation, setShowVariation] = useState(null);
	const [price, setPrice] = useState('');
	const [addDiscount, setAddDiscount] = useState(!!product?.originalPrice || false);
	const [discount, setDiscount] = useState('');
	const [shippingCost, setShippingCost] = useState(product?.shippingCost || 0);
	const [selectedCategory, setSelectedCategory] = useState(product?.category);
	const [allowsPersonalization, setAllowsPersonalization] = useState(product?.allowsPersonalization);
	const [mustAddPersonalizationText, setMustAddPersonalizationText] = useState(
		product?.mustAddPersonalizationText
	);
	const [mustAddPersonalizationImage, setMustAddPersonalizationImage] = useState(
		product?.mustAddPersonalizationImage
	);

	const isEdit = !!product;
	const showShippingCostWarning = shippingCost > 15;
	useOutletTitle(`Adaugă produs${customFor ? ' privat' : ''}`);

	const onFreeShippingCheck = (e) => {
		if (e.target.checked) {
			setShippingCost(0);
		}
	};

	const deleteVariation = (variation) => {
		setVariations((prev) => prev.filter((v) => v._id !== variation._id));
	};

	const handleSaveVariation = (newVariation) => {
		if (variations.find((v) => v.title === newVariation.title && v._id !== newVariation._id)) {
			return toast.error('Ai deja această variantă. O poți edita.');
		}
		if (variations.find((v) => v._id === newVariation._id)) {
			setVariations(
				variations.map((v) => {
					if (v._id === newVariation._id) return newVariation;
					return v;
				})
			);
		} else {
			setVariations([...variations, newVariation]);
		}
	};

	const handleSaveProduct = async (formData) => {
		const { title, statementAccepted } = Object.fromEntries(formData);

		// individuals must agree with this checkbox
		if (!user.cui && !statementAccepted) {
			toast.error('Trebuie să bifați declarația pe propria răspundere');
			return;
		}

		if (images.length < MIN_PRODUCT_IMAGES) {
			return toast.error(`Sunt necesare minim ${MIN_PRODUCT_IMAGES} imagini.`);
		}
		if (images.length > MAX_PRODUCT_IMAGES) {
			return toast.error(`Sunt permise maxim ${MAX_PRODUCT_IMAGES} imagini.`);
		}
		if (!title) {
			return toast.error('Lipsă titlu.');
		}
		if (title.length < MIN_PRODUCT_TITLE_LENGTH) {
			return toast.error('Titlul este prea scurt.');
		}
		if (title.length > MAX_PRODUCT_TITLE_LENGTH) {
			return toast.error(`Titlul depășește ${MAX_PRODUCT_TITLE_LENGTH} caractere.`);
		}

		formData.set('variations', JSON.stringify(variations));
		formData.set('images', '');
		images.forEach((image) => formData.append('images', image.file));

		const res = await (isEdit ? editProduct(product._id, formData) : addProduct(formData));
		if (res.error) {
			toast.error(res.error);
		} else {
			toast.success(res.message);
			navigate(-1);
		}
	};

	useEffect(() => {
		if (product?.originalPrice) {
			setDiscount(product.originalPrice - product.price);
			setPrice(product.originalPrice);
		} else if (product) {
			setPrice(product.price);
		}
	}, [product]);

	return (
		<WhiteContainer className="p-4 md:p-6">
			{customFor && (
				<div className="py-3 px-4 rounded-md mb-6 bg-light-orange text-orange">
					<p>
						Acest produs este făcut la comandă pentru:{' '}
						<Link to={`/profile/${customFor._id}`} className="link">
							{customFor.displayName}
						</Link>
					</p>
					<p>Nimeni altcineva nu-l va putea accesa!</p>
				</div>
			)}

			<Form onSubmit={handleSaveProduct}>
				<input type="text" name="customFor" value={customFor?._id} hidden readOnly />

				{isAdmin(user) && isEdit && (
					<div className="flex flex-wrap">
						<InputSelect
							id="select-category"
							label="Categorie"
							labelClassName="text-xl"
							name="category"
							containerClassName="!mb-8 max-w-[150px]"
							placeHolder="Alege categoria"
							options={Object.values(Category)}
							selectedOption={selectedCategory}
							onSelect={(option) => setSelectedCategory(option)}
						/>
						<InputText
							label="Subcategorii"
							name="subcategories"
							defaultValue={product?.subcategories.join(', ')}
							labelClassName="text-xl"
							containerClassName="!mb-8 flex-1 ml-8"
						/>
						<InputText
							label="Views"
							name="views"
							defaultValue={product?.views}
							labelClassName="text-xl"
							containerClassName="!mb-8 max-w-[75px] ml-8"
						/>
					</div>
				)}

				<div className="mb-8">
					<p className="font-semibold text-xl">Imagini</p>
					<span className="text-secondary text-md">
						Adaugă până la {MAX_PRODUCT_IMAGES} poze de maxim {MAX_PHOTO_MB_SIZE} MB. Imaginile
						vor fi tăiate astfel încât să aibă formă pătrată. Prima imagine va fi și cea de
						prezentare. Poți trage pentru a le ordona.
					</span>
					<CheckBox
						label="Păstrează imaginile întregi"
						name="containImages"
						className="text-secondary mt-2"
						onChange={(e) => setContainImages(e.target.checked)}
					/>
					<ImagePicker
						className="mt-4"
						images={images}
						setImages={setImages}
						contain={containImages}
					/>
				</div>

				<div className="max-w-md w-full">
					<p className="font-semibold text-xl">Titlu</p>
					<p className="text-secondary text-md mb-2">Cât mai explicit. Include cuvinte cheie.</p>
					<InputText
						name="title"
						containerClassName="!mb-8"
						placeHolder={`Între ${MIN_PRODUCT_TITLE_LENGTH} și ${MAX_PRODUCT_TITLE_LENGTH} de caractere.`}
						defaultValue={product?.title}
					/>
				</div>

				<InputTextArea
					name="description"
					placeHolder={`⏩ Detalii: tehnică, materiale, dimensiuni, culori... \n⏩ Instrucțiuni de personalizare. \n⏩ Timp de procesare. \n⏩ Încurajează comunicarea pe chat.`}
					className="rounded-full"
					labelClassName="text-xl"
					containerClassName="!mb-8"
					label="Detalii"
					rows={8}
					defaultValue={product?.description}
				/>

				<div className="mb-8">
					<p className="font-semibold text-xl">Variante</p>
					<p className="text-secondary text-md mb-2">
						Adaugă variante și opțiuni din care clienții să poată aleagă (exemplu: mărime,
						culoare...).
					</p>

					{variations.map((variation) => (
						<div key={variation._id} className="flex items-center mb-2">
							<VariationSelect variation={variation} containerClassName="!mb-0 max-w-xs" />
							<Button
								text="șterge"
								containerClassName="danger-light ml-1 sm:ml-2 !px-2 !py-1 text-md"
								onClick={() => deleteVariation(variation)}
							/>
							<Button
								text="modifică"
								containerClassName="primary-light ml-1 sm:ml-2 !px-2 !py-1 text-md"
								onClick={() => setShowVariation(variation)}
							/>
						</div>
					))}

					{variations.length < 5 && (
						<Button
							containerClassName="light"
							text="Adaugă variantă"
							icon={<RiAddFill size={20} className="mr-1" />}
							onClick={() => setShowVariation({})}
						/>
					)}

					{showVariation && (
						<VariationModal
							variation={showVariation}
							onRequestClose={() => setShowVariation(null)}
							onSave={handleSaveVariation}
						/>
					)}
				</div>

				<div className="mb-8">
					<p className="font-semibold text-xl mb-1.5">Personalizare</p>
					<CheckBox
						label="Acceptă personalizare"
						className="mb-2"
						name="allowsPersonalization"
						onChange={(e) => setAllowsPersonalization(e.target.checked)}
						defaultChecked={allowsPersonalization}
					/>
					{allowsPersonalization && (
						<>
							<CheckBox
								label="Text de personalizare obligatoriu"
								className="mb-2"
								name="mustAddPersonalizationText"
								onChange={(e) => setMustAddPersonalizationText(e.target.checked)}
								defaultChecked={mustAddPersonalizationText}
							/>
							<CheckBox
								label="Imagine de personalizare obligatorie"
								className="mb-2"
								name="mustAddPersonalizationImage"
								onChange={(e) => setMustAddPersonalizationImage(e.target.checked)}
								defaultChecked={mustAddPersonalizationImage}
							/>
						</>
					)}
					{allowsPersonalization && (
						<InputTextArea
							name="personalizationText"
							label="Instrucțiuni pentru cumpărător:"
							placeHolder="Exemplu: Personalizează cu numele tău"
							className="rounded-full"
							labelClassName="font-normal"
							containerClassName="mt-3 max-w-md"
							defaultValue={product?.personalizationText}
							rows={2}
						/>
					)}
				</div>

				<div className="mb-8">
					<p className="font-semibold text-xl">Cantitate</p>
					<p className="text-secondary text-md mb-2">
						Câte articole poți vinde? <br />
						Cantitatea va scădea cu fiecare produs cumpărat. Odată ajunsă la 0, produsul nu va mai
						putea fi comandat.
					</p>
					<InputText
						type="number"
						name="stock"
						containerClassName="max-w-[70px]"
						className="text-center text-md"
						defaultValue={product ? product.stock : 1}
					/>
				</div>

				<div className="mb-8">
					<p className="font-semibold text-xl">Preț</p>
					<span className="text-secondary text-md">Care este prețul produsului?</span>
					<CheckBox
						label="Adaugă reducere"
						className="mb-3"
						checked={addDiscount}
						onChange={(e) => setAddDiscount(e.target.checked)}
					/>
					<div className="mt-2">
						<div className="flex items-center">
							<InputText
								type="number"
								name="price"
								placeHolder="Preț"
								containerClassName="max-w-[70px] !mb-0"
								className="text-center"
								value={price}
								onChange={(e) => setPrice(e.target.value)}
							/>
							<span className="text-secondary ml-2">Lei</span>
						</div>
					</div>
					{addDiscount && (
						<>
							<div className="mt-2">
								<div className="flex items-center">
									<InputText
										type="number"
										name="discount"
										placeHolder="Reducere"
										containerClassName="max-w-[100px] !mb-0"
										className="text-center"
										value={discount}
										onChange={(e) => setDiscount(e.target.value)}
										max={price}
										min={0}
									/>
									<span className="text-secondary ml-2">Lei</span>
								</div>
							</div>
							<div className="mt-4">Preț final: {price - discount} Lei</div>
							<div className="mt-2">Procent: {Math.round((discount / price) * 100)}%</div>
						</>
					)}
				</div>

				<div className="mb-8">
					<p className="font-semibold text-xl">Cost livrare</p>
					<p className="text-secondary text-md mb-2">
						Depinde de tipul de transport pe care îl folosești (curier, poștă, ridicare personală,
						etc.)
					</p>
					<CheckBox
						label="Livrare gratuită"
						className="mb-3"
						checked={shippingCost === 0 || shippingCost === '0'}
						onChange={onFreeShippingCheck}
					/>

					<div className="flex items-center">
						<InputText
							type="number"
							name="shippingCost"
							placeHolder="Cost"
							containerClassName="max-w-[70px] !mb-0"
							className="text-center"
							value={shippingCost}
							onChange={(e) => setShippingCost(e.target.value)}
						/>
						<span className="text-secondary ml-2">Lei</span>
					</div>

					{showShippingCostWarning && (
						<div className="py-3 px-4 warning-light rounded-md flex items-center mt-4">
							<RiAlertFill className="mr-3 shrink-0" size={19} />
							<div>
								Recomandăm includerea costului de livrare în prețul produsului, astfel încât
								acesta să nu depășească 15 lei. Puteți folosi platforma{' '}
								<a
									href="https://www.woot.ro"
									target="_blank"
									rel="noreferrer"
									className="link"
								>
									www.woot.ro
								</a>{' '}
								pentru a putea compara costurile între firmele de curierat și pentru a
								beneficia de prețuri reduse după înregistrare.
							</div>
						</div>
					)}
				</div>

				<div className="mb-8 max-w-md">
					<p className="font-semibold text-xl">Mod de livrare</p>
					<p className="text-secondary text-md mb-2">Alege o singură opțiune.</p>
					<RadioBox
						name="shippingDetails"
						label="Curier rapid"
						value="Curier rapid"
						required
						defaultChecked={product ? product.shippingDetails === 'Curier rapid' : true}
					/>
					<RadioBox
						name="shippingDetails"
						label="Poștă"
						value="Poștă"
						required
						defaultChecked={product?.shippingDetails === 'Poștă'}
					/>
					<RadioBox
						name="shippingDetails"
						label="Predare personală"
						value="Predare personală"
						required
						defaultChecked={product?.shippingDetails === 'Predare personală'}
					/>
				</div>

				<div className="mb-8">
					<p className=" font-semibold text-xl">Livrare estimată</p>
					<p className="text-secondary text-md mb-2">
						Spune-le clienților cât trebuie să aștepte pentru a primi produsul. Include atât
						timpul necesar pentru confecționarea produsului cât și durata estimată de livrare
						oferită de serviciul de curierat.
					</p>
					<div className="flex items-center">
						<InputText
							type="number"
							name="minDeliveryDays"
							placeHolder="Min"
							containerClassName="max-w-[70px] !mb-0"
							className="text-center"
							defaultValue={product?.estimatedDelivery.minDays}
						/>
						<span className="mx-4">-</span>
						<InputText
							type="number"
							name="maxDeliveryDays"
							placeHolder="Max"
							containerClassName="max-w-[70px] !mb-0"
							className="text-center"
							defaultValue={product?.estimatedDelivery.maxDays}
						/>
						<span className="text-secondary ml-2">zile</span>
					</div>
				</div>

				{user.cashOnDeliveryStatus === Status.Approved && (
					<div className="mb-8">
						<p className=" font-semibold text-xl">Plata la livrare</p>
						<p className="text-secondary text-md mb-2">
							Accepți plata la livrare pentru acest produs?
						</p>
						<CheckBox
							defaultChecked={product?.acceptCashOnDelivery}
							name="acceptCashOnDelivery"
							label="Acceptă plata la livrare"
						/>
					</div>
				)}

				{!user.cui && (
					<div className="mb-8">
						<p className=" font-semibold text-xl">Declarație pe propria răspundere</p>
						<div>
							<input
								id="statement-checkbox"
								type="checkbox"
								name="statementAccepted"
								defaultChecked={isEdit}
							/>
							<label htmlFor="statement-checkbox" className="ml-1.5">
								Declar pe propria răspundere că acest bun îmi aparține și provine din
								patrimoniul personal.
							</label>
							<div className="tooltip-trigger inline-block">
								<div className="tooltip-text">
									Deoarece sunteți înregistrat ca persoană fizică, orice articol vândut
									trebuie să vă aparțină.
								</div>
								<RiInformationFill size={18} className="text-[#ccc] ml-2 align-middle" />
							</div>
						</div>
					</div>
				)}

				{product?.approved && (
					<div className="py-3 px-4 warning-light rounded-md flex items-center mb-3">
						<RiAlertFill className="mr-2 shrink-0" size={19} />
						Odată editat, produsul va intra în procesul de verificare. Acesta nu va mai fi listat
						până la aprobare!
					</div>
				)}

				{isEdit ? (
					<Button type="submit" text="Salvează" containerClassName="primary" />
				) : (
					<Button
						type="submit"
						text="Adaugă produsul"
						containerClassName="primary"
						icon={<RiAddFill size={22} className="mr-1" />}
					/>
				)}
			</Form>
		</WhiteContainer>
	);
}
