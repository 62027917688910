import { useMemo } from 'react';
import { get } from 'api/_methods';
import useSWRImmutable from 'swr/immutable';
import ProductsSwiper from './ProductsSwiper';

export default function PopularProducts({ isMobile }) {
	const { data } = useSWRImmutable('/popular-products', get);

	const productsMap = useMemo(() => {
		if (!data || data.error) return undefined;
		return data;
	}, [data]);

	if (!productsMap) return;

	return (
		<div>
			{Object.keys(productsMap).map((category, index) => (
				<ProductsSwiper
					key={category}
					products={productsMap[category]}
					className={index === 0 ? '' : 'mt-8'}
					name={category}
					isMobile={isMobile}
				/>
			))}
		</div>
	);
}
