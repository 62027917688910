export default function ProductDeniedAlert({ reasonForDenial }) {
	return (
		<div className="rounded-md danger-light py-3 px-4 mb-5 border-light-red-intense border-2">
			<p className="text-lg font-semibold">Produsul a fost respins!</p>
			<p>
				<b>Motiv:</b> {reasonForDenial}
			</p>
		</div>
	);
}
