import { useContext, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QuestionMark } from 'icons';
import { InputSearch } from 'components/Input';
import ReactDOMServer from 'react-dom/server';
import Collapsible from 'components/Collapsible';
import UserContext from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import useScrollToTop from 'hooks/useScrollToTop';
import isArtist from 'utils/isArtist';
import normalizeStringLanguage from 'utils/normalizeStringLanguage';
import useFilter from 'hooks/useFilter';
import LoginPanel from 'components/login/LoginPanel';
import useRequireLogin from 'hooks/useRequireLogin';
import usePageTitle from 'hooks/usePageTitle';

export default function FAQ() {
	useScrollToTop();
	usePageTitle('Întrebări frecvente - Custom Studio');

	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchString, setSearchString] = useFilter('search', searchParams, setSearchParams, '', true);
	const [showLoginPanel, openLoginPanel, closeLoginPanel] = useRequireLogin();
	const delayedSearchString = useDebounce(searchString);

	const onBecomeArtist = (e) => {
		if (!user) {
			openLoginPanel('/open-shop');
		} else if (!isArtist(user)) {
			navigate('/open-shop');
		}
	};

	// No <Link /> components allowed in here
	const allQuestions = {
		buyers: [
			{
				title: 'Cum se face plata?',
				text: (
					<>
						<p>
							Plata se efectuează cu cardul din motive de siguranță atât pentru client, cât și
							pentru artist. Plata ajunge in contul artistului doar dupa confirmarea primirii
							coletului.
						</p>
						<p className="mt-2">
							Prin această modalitate de plată, putem asigura artistul că va primi suma aferentă
							unei lucrări, dar și clientul ca își poate redobândi banii în cazul în care
							coletul primit nu corespunde comenzii. În acest caz, te rugăm sa ne contactezi.
							Pentru mai multe informații te rugăm sa verifici{' '}
							<a
								role="button"
								onClick={() => navigate('/shipping-and-refund')}
								className="link"
							>
								Politica de livrare și retur
							</a>
							.
						</p>
						<p className="mt-2">
							Plata se efectuează prin procesatorul de plăți{' '}
							<a
								href="https://stripe.com/en-ro"
								className="text-blue hover:underline"
								target="_blank"
								rel="noreferrer"
							>
								Stripe
							</a>
							. Nu reținem datele cardului.
						</p>
					</>
				),
			},
			{
				title: 'Cum contactez un magazin?',
				text: (
					<>
						<p>Poți trimite un mesaj artistului direct prin platforma noastră, astfel:</p>
						<ul className="list-disc">
							<li className="ml-6">
								apăsând butonul &quot;Trimite mesaj&quot; de pe pagina magazinului
							</li>
							<li className="ml-6">
								apăsând butonul &quot;Contactează vânzătorul&quot; de pe pagina unui produs
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Cum pot face o cerere de produs?',
				text: 'Dacă îți plac lucrările unui artist, îi poți cere o lucrare unică, făcută la comandă după cerințele proprii. Tot ce trebuie să faci este să îl contactezi prin chat-ul integrat în platforma noastră. Pe baza cerințelor tale, artistul îți poate face o ofertă prin crearea unui produs pe care doar tu îl vei putea accesa.',
			},
			{
				title: 'Ce sunt produsele personalizate?',
				text: (
					<>
						<p>
							Anumite produse pot fi personalizate din secțiunea &quot;Personalizează&quot; de
							pe pagina produsului.
						</p>
						<p className="mt-2">
							De exemplu, poate consta într-un mesaj ales de tine gravat pe suprafața lucrării.
							Poți contacta oricând artistul pentru a discuta cerințe avansate, iar acesta îți
							poate trimite o ofertă personalizată.
						</p>{' '}
					</>
				),
			},
			{
				title: 'Cum las o recenzie?',
				text: 'Odată ce artistul expediază comanda, ai opțiunea de a scrie un review prin butonul "Adaugă o recenzie" de pe pagina produsului.',
			},
			{
				title: 'Ce este durata estimată de livrare?',
				text: 'Aceasta este specificată de artist pentru fiecare produs și ia în considerare atât timpul de procesare/creare a lucrării cât și timpul de livrare. Poate diferi în funcție de cantitatea comandată și se aplică/începe odată ce artistul acceptă comanda.',
			},
			{
				title: 'Cum anulez comanda?',
				text: (
					<>
						<p>
							Poți anula comanda în primele 72 de ore de când plata a fost procesată
							contactându-ne{' '}
							<a role="button" onClick={() => navigate('/contact')} className="link">
								aici
							</a>{' '}
							cu numărul comenzii. După acest interval de timp, doar artiștii pot anula comanda
							și restitui banii clienților. Dacă dorești anularea, vorbește direct cu artistul
							tău folosind chat-ul integrat al platformei.
						</p>
					</>
				),
			},
			{
				title: 'Cum returnez un produs?',
				text: (
					<>
						<p>
							Pentru retur, vorbește direct cu artistul. Ai la dispoziție 14 zile pentru a
							returna produsele. Dacă acestea au fost personalizate sau făcute la comandă, și nu
							ești mulțumit de calitatea lucrării sau aceasta nu corespunde descrierii, poți
							face o sesizare în 48 de ore de la primirea livrării contactându-ne{' '}
							<a role="button" onClick={() => navigate('/contact')} className="link">
								aici
							</a>
							.
						</p>
					</>
				),
			},
			{
				title: 'Comanda nu a fost confirmată. Ce pot face?',
				text: 'Magazinul are opțiunea de a refuza comanda și de a restitui banii clienților. Pe de altă parte, dacă au trecut 72 de ore și comanda ta nu a fost confirmată, o poți anula chiar tu.',
			},
			{
				title: 'Produsul nu a ajuns. Ce pot face?',
				text: (
					<p>
						Cel mai ușor este să îi ceri artistului un cod de urmărire a comenzii. Alternativ,
						dacă durata estimată de livrare a fost depășită, poți face o sesizare contactându-ne{' '}
						<a role="button" onClick={() => navigate('/contact')} className="link">
							aici
						</a>
						.
					</p>
				),
			},
		],
		artists: [
			{
				title: 'Care este pagina mea publică de profil?',
				text: (
					<p>
						Îți poți vizualiza profilul public din Contul meu -&gt; Profil -&gt; Vizualizează. Le
						poți trimite clienților și prietenilor link-ul către pagina ta, care va avea formatul:{' '}
						<i>customstudio.ro/artist/numele_tău</i>
					</p>
				),
				private: true,
			},
			{
				title: 'Cum se calculează costul livrării?',
				text: 'Costul livrării este alegerea ta și depinde de metoda de livrare folosită de tine. În cazul în care o comandă conține mai multe produse, fiecare cu un cost de livrare diferit, costul final va fi maximul dintre toate costurile. Cantitatea nu influențează costul de livrare.',
				private: false,
			},
			{
				title: 'Când trebuie să accept returul?',
				text: 'Clienții au 14 zile drept de retur, de la primirea coletului. Acest drept nu se aplică produselor personalizate sau făcute la comandă (după cerințele unice ale clientului).',
				private: false,
			},
			{
				title: 'Cum rambursez clientul în caz de retur?',
				text: 'Anularea comenzii va porni automat procesul de rambursare a clientului.',
				private: false,
			},
			{
				title: 'Când primesc banii în urma unei comenzi?',
				text: 'La 14 zile după primirea coletului de către client pentru produsele care permit retur. În cazul în care produsul a fost personalizat, transferul in contul artistului este efectuat la 48 de ore de la primirea coletului de către client.',
				private: false,
			},
			{
				title: 'Ce se întâmplă dacă nu accept comanda?',
				text: 'Poți refuza comanda anulând-o. Dacă nu o accepți în 72 de ore și nici nu o anulezi, comanda va fi anulată automat iar contul tău va fi sancționat.',
				private: false,
			},
			{
				title: 'Cum deschid un magazin?',
				text: (
					<p>
						Simplu și gratuit!{' '}
						<span className="link" onClick={openLoginPanel}>
							Creează-ți un cont
						</span>
						, apoi accesează pagina de{' '}
						<span className="link" onClick={onBecomeArtist}>
							deschide magazin
						</span>{' '}
						și completează datele necesare.
					</p>
				),
			},
			{
				title: 'Ce pot vinde pe Custom Studio?',
				text: 'Obiecte lucrate manual (handmade), obiecte personalizate sau servicii de personalizare.',
			},
			{
				title: 'Cum se face livrarea?',
				text: 'Metoda de livrare este în totalitate alegerea ta. Noi recomandăm livrarea prin curier, aceasta fiind rapidă și sigură.  Nu uita să ai întotdeauna dovada expedierii unei comenzi și să trimiți clientului codul de urmărire(AWB).',
			},
			{
				title: 'Care sunt taxele și comisioanele?',
				text: (
					<p>
						Când modalitatea de plată este ramburs la livrare, nu percepem niciun comision. Cu
						toate acestea, atunci când clienții aleg să plătească online cu cardul, procesatorul
						de plăți reține automat un comision de 1,5% + 1leu din valoarea totală a comenzii.
					</p>
				),
			},
			{
				title: 'Cum adaug un produs privat?',
				text: 'Dacă primești o cerere pentru o lucrare unică, poți crea un produs privat, vizibil doar clientului tău, făcând click pe butonul "Trimite ofertă" din chat sau din pagina de profil a clientului. ',
			},
			{
				title: 'Când pot primi o recenzie?',
				text: 'Clientul poate lăsa o recenzie imediat ce comanda este marcată ca și "expediată".',
			},
			{
				title: 'Cum șterg sau ascund un produs?',
				text: 'Poți face produsul invizibil pentru orice utilizator făcând click pe butonul "Ascunde" din pagina produsului, sau îl poți șterge definitiv.',
			},
			// {
			// 	title: 'Ce este un cheat code?',
			// 	text: (
			// 		<p>
			// 			Un cod promoțional prin care poți primi anumite beneficii. Se câștigă în concursuri și
			// 			campanii Custom Studio, majoritatea desfășurate pe server-ul nostru de{' '}
			// 			<span onClick={() => window.open(DISCORD, '_blank')} className="link">
			// 				discord
			// 			</span>
			// 			. Poate fi adăugat din{' '}
			// 			<span className="link" onClick={onGoToSettings}>
			// 				setările contului
			// 			</span>
			// 			.
			// 		</p>
			// 	),
			// },
		],
	};

	const renderQuestions = (qs) => {
		const search = delayedSearchString.trim();

		if (search.length >= 2) {
			qs = qs.filter((q) => {
				const title = normalizeStringLanguage(q.title);
				const text = normalizeStringLanguage(
					typeof q.text === 'object' ? ReactDOMServer.renderToStaticMarkup(q.text) : q.text
				);
				const searchableText = `${title} ${text}`.toLowerCase();
				const searchTokens = search.split(' ');
				for (const token of searchTokens) {
					if (searchableText.includes(token)) return true;
				}
				return false;
			});
		}

		return qs.map((q) => {
			if (q.private && !isArtist(user)) return null;
			return (
				<Collapsible key={q.title} title={q.title} containerClassName="fade-in">
					{q.text}
				</Collapsible>
			);
		});
	};

	const renderedBuyersQuestions = useMemo(
		() => renderQuestions(allQuestions.buyers),
		[user, delayedSearchString]
	);
	const renderedArtistsQuestions = useMemo(
		() => renderQuestions(allQuestions.artists),
		[user, delayedSearchString]
	);

	return (
		<div className="container my-12">
			<div className="flex justify-center">
				<QuestionMark />
				<h2 className="font-semibold ml-4 text-center">Întrebări frecvente</h2>
			</div>

			<InputSearch
				containerClassName="max-w-[420px] mx-auto mt-6 !mb-8"
				placeHolder={'Caută...'}
				value={searchString}
				onChange={(e) => setSearchString(e.target.value)}
			/>

			<div className="flex justify-center text-center text-secondary">
				<p>
					Află cum poți vinde sau cumpăra pe Custom Studio{' '}
					<a role="button" onClick={() => navigate('/#how-it-works')} className="link">
						aici
					</a>
					.
				</p>
			</div>

			<div className="flex flex-col md:flex-row">
				<div className="flex-1 md:pr-3 mt-8 ">
					<div className="max-w-[550px] mx-auto">
						<p className="font-semibold underline mb-4 text-blue text-lg text-center">
							Pentru cumpărători
						</p>
						{renderedBuyersQuestions}
						{renderedBuyersQuestions.length === 0 && (
							<p className="text-md text-secondary italic text-center">Niciun rezultat.</p>
						)}
					</div>
				</div>

				<div className="flex-1 md:pl-3 mt-8">
					<div className="max-w-[550px] mx-auto">
						<p className="font-semibold underline mb-4 text-blue text-lg text-center">
							Pentru vânzători
						</p>
						{renderedArtistsQuestions}
						{renderedArtistsQuestions.length === 0 && (
							<p className="text-md text-secondary italic text-center">Niciun rezultat.</p>
						)}
					</div>
				</div>
			</div>

			{showLoginPanel && <LoginPanel onRequestClose={closeLoginPanel} />}
		</div>
	);
}
