import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import {
	isInstagramUsername,
	MAX_ABOUT_ME_LENGTH,
	MAX_PHOTO_MB_SIZE,
	MAX_PHOTO_SIZE,
} from 'custom-studio-constants';

import { FaPaintBrush } from 'react-icons/fa';
import WhiteContainer from '../../components/WhiteContainer';
import useOutletTitle from '../../hooks/useOutletTitle';
import UserContext from '../../context/UserContext';
import Avatar from '../../components/Avatar';
import { InputText, InputTextArea } from '../../components/Input';
import Button from '../../components/Button';
import { updateProfile } from '../../api/userApi';
import Form from 'components/Form';
import isArtist from 'utils/isArtist';

function clearInputFile() {
	document.getElementById('change-avatar').value = '';
}

export default function EditProfile() {
	useOutletTitle('Editare Profil');
	const navigate = useNavigate();
	const { user, setUser } = useContext(UserContext);
	const [userPhoto, setUserPhoto] = useState(user.largePhoto);
	const [newAvatar, setNewAvatar] = useState(null);

	const onChangeAvatar = (e) => {
		if (!e.target.files[0]) return;
		try {
			const fileReader = new FileReader();
			fileReader.onloadend = (e) => setNewAvatar(e.target.result);
			fileReader.readAsDataURL(e.target.files[0]);
		} catch (err) {
			console.error(err);
			toast.error('Imaginea selectată este invalidă.');
			captureException(err);
		}
	};

	const onSave = async (formData) => {
		let { avatar, displayName, about, instagram, tiktok } = Object.fromEntries(formData);
		formData.append('deleteAvatar', !newAvatar && !userPhoto);

		if (isArtist(user) && !displayName) {
			return toast.error('Pseudonimul nu poate fi gol!');
		}

		if (avatar.name) {
			if (avatar.size > MAX_PHOTO_SIZE) {
				return toast.error(`Imaginea depășește ${MAX_PHOTO_MB_SIZE} MB.`);
			}
			if (!avatar.type.startsWith('image/')) {
				return toast.error('Sunt permise doar imagini.');
			}
		}

		if (about.length > MAX_ABOUT_ME_LENGTH) {
			return toast.error(`Secțiunea "Despre" depășește ${MAX_ABOUT_ME_LENGTH}.`);
		}

		if (instagram.endsWith('/')) {
			instagram = instagram.slice(0, -1);
			formData.set('instagram', instagram);
		}
		if (instagram && !isInstagramUsername(instagram)) {
			return toast.error('Instagram invalid!');
		}

		if (tiktok && !isInstagramUsername(tiktok)) {
			return toast.error('TikTok invalid!');
		}

		const res = await updateProfile(formData);

		if (res.error) {
			toast.error(res.error);
		} else {
			setUser(res);
			clearInputFile();
			toast.success('Profilul a fost editat cu succes!', { autoClose: 2000 });
			navigate(-1);
		}
	};

	const onDeletePhoto = () => {
		setNewAvatar(null);
		setUserPhoto(null);
		clearInputFile();
	};

	return (
		<WhiteContainer className="p-4 md:p-6 overflow-hidden">
			<Form onSubmit={onSave}>
				<div className="md:w-fit ">
					<div className="relative w-fit mx-auto">
						<Avatar
							className="w-[150px] h-[150px] mx-auto mb-1"
							name={user.displayName}
							photo={newAvatar || userPhoto}
							htmlFor={'change-avatar'}
						/>
						<input
							id="change-avatar"
							type="file"
							hidden
							accept=".png,.jpg,.jpeg"
							name="avatar"
							onChange={onChangeAvatar}
						/>
						<label className="edit-avatar-label" htmlFor="change-avatar">
							<div className="edit-avatar-bubble">
								<FaPaintBrush className="edit-avatar-icon" />
							</div>
						</label>
					</div>

					<div
						className="cursor-pointer hover:underline text-center text-secondary mt-2"
						onClick={onDeletePhoto}
					>
						șterge
					</div>
				</div>

				{isArtist(user) && (
					<InputText
						label="Pseudonim"
						defaultValue={user.displayName}
						className="max-w-xs"
						containerClassName="mt-8"
						autoCapitalize="true"
						name="displayName"
						placeHolder="Pseudonim"
					/>
				)}

				<InputTextArea
					label="Despre mine"
					defaultValue={user.about}
					rows={8}
					placeholder=""
					autoCapitalize="true"
					name="about"
					containerClassName="mt-8"
				/>

				<div className="mt-8">
					<p className="font-semibold">Instagram</p>
					<div className="flex items-center">
						<p className="mr-1">www.instagram.com/</p>
						<InputText
							className="max-w-[160px] !px-1.5"
							containerClassName="mb-0"
							name="instagram"
							placeHolder="username"
							defaultValue={user.instagram}
						/>
					</div>
				</div>

				<div className="mt-8 mb-8">
					<p className="font-semibold">TikTok</p>
					<div className="flex items-center">
						<p className="mr-1">www.tiktok.com/@</p>
						<InputText
							className="max-w-[160px] !px-1.5"
							containerClassName="mb-0"
							name="tiktok"
							placeHolder="username"
							defaultValue={user.tiktok}
						/>
					</div>
				</div>

				<Button type="submit" text="Salvează" containerClassName="primary" />
			</Form>
		</WhiteContainer>
	);
}
