import { get, post, postJson, _delete } from './_methods';

export function getProduct(slug) {
	return get(`/products/${slug}`);
}

export function getProductsRecommandation(category, artistId) {
	return get(`/products/recommandation/${category}/${artistId}`);
}

export function getProductReviews(productId) {
	return get(`/products/${productId}/reviews`);
}

export function addProduct(formData) {
	return post('/products', formData);
}

export function editProduct(productId, formData) {
	return post(`/products/${productId}`, formData);
}

export function deleteProduct(productId) {
	return _delete(`/products/${productId}`);
}

export function setProductPubliclyListed(productId, publiclyListed) {
	return postJson(`/products/${productId}/list`, { publiclyListed });
}

export function likeProduct(productId, like) {
	return postJson(`/products/${productId}/like`, { like });
}

export function highlightProduct(productId, highlight) {
	return postJson(`/products/${productId}/highlight`, { highlight });
}
