import { InputText } from 'components/Input';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { hasDuplicates } from 'utils/utils';
import UIModal, { UIModalCloseButton } from './UIModal';
import Button from 'components/Button';
import PriceChangeInput from 'components/PriceChangeInput';

const colors = ['Fuchsia', 'Crem', 'Negru', 'Alb', 'Roșu', 'Violet', 'Lila', 'Argintiu', 'Roz'];
const get2RandomColors = () => {
	const a = Math.floor(Math.random() * colors.length);
	let b = Math.floor(Math.random() * colors.length);
	while (b === a) {
		b = Math.floor(Math.random() * colors.length);
	}
	return [colors[a], colors[b]];
};

const extendOptions = (options) => {
	options = [...options];
	const validOptions = options.filter((o) => o.value).length;
	const shouldAddOptionField =
		options.length < 20 && (validOptions === options.length || options.length < 2);
	const shouldRemoveOptionField = options.length > 2 && options.length - validOptions >= 2;

	if (shouldAddOptionField) {
		options.push({ _id: Math.random(), value: '', priceChange: '' });
	} else if (shouldRemoveOptionField) {
		options.pop();
	}

	return options;
};

export default function VariationModal({ variation, onSave, onRequestClose }) {
	const [_id] = useState(variation?._id || Math.random());
	const [randomColors] = useState(get2RandomColors());
	const [title, setTitle] = useState(variation?.title || '');
	const [options, setOptions] = useState(
		extendOptions(
			variation?.options || [
				{ _id: 0, value: '', priceChange: '' },
				{ _id: 1, value: '', priceChange: '' },
			]
		)
	);

	const updateOption = (id, patch) => {
		setOptions((prev) => {
			const newOptions = prev.map((option) => {
				if (option._id === id) {
					return { ...option, ...patch };
				}
				return option;
			});
			return extendOptions(newOptions);
		});
	};

	const handlePriceChange = (option, value) => {
		updateOption(option._id, { priceChange: value });
	};

	const handleSave = () => {
		if (!title) {
			return toast.error('Adaugă un titlu.');
		}

		const validOptions = options
			.filter((o) => o.value)
			.map((o) => {
				o.priceChange = parseInt(o.priceChange) || 0;
				return o;
			});

		if (validOptions.length === 0) {
			return toast.error('Adaugă o opțiune.');
		}

		if (hasDuplicates(validOptions.map((o) => o.value))) {
			return toast.error('Opțiunile se repetă.');
		}

		onSave({
			_id,
			title,
			options: validOptions,
		});
		onRequestClose();
	};

	return (
		<UIModal
			name="variation"
			onRequestClose={onRequestClose}
			className="p-6 w-full max-w-[390px] min-h-[510px] max-h-full overflow-y-auto custom-scroll flex flex-col"
		>
			<UIModalCloseButton onClick={onRequestClose} theme="gray" />
			<h3 className="text-center mb-6 text-2xl font-semibold">Variantă produs</h3>

			<InputText
				label="Nume"
				labelClassName="text-lg"
				placeHolder="Exemplu: Alege culoarea"
				containerClassName="mb-6"
				value={title}
				onChange={(e) => setTitle(e.target.value)}
			/>

			<div className="mb-4">
				<div className="flex justify-between items-end">
					<p className="text-lg font-semibold mb-2">Opțiuni</p>
					<p className="font-semibold text-sm mb-2">Cost suplimentar</p>
				</div>

				{options.map((option) => (
					<div key={option._id} className="flex items-center mt-2">
						<InputText
							placeHolder={
								(randomColors[option._id] && `Exemplu: ${randomColors[option._id]}`) || ''
							}
							containerClassName="w-full !mb-0"
							value={option.value}
							onChange={(e) => updateOption(option._id, { value: e.target.value })}
						/>
						<PriceChangeInput
							containerClassName="ml-2 !w-[80px]"
							className="text-right"
							onChange={(value) => handlePriceChange(option, value)}
							defaultValue={option.priceChange || undefined}
						/>
					</div>
				))}
			</div>

			<Button text="Salvează" containerClassName="primary w-full mt-auto" onClick={handleSave} />
		</UIModal>
	);
}
