import { Link } from 'react-router-dom';
import RatingStars from '../components/RatingStars';
import { formatRating } from '../utils/format';
import Avatar from './Avatar';

export default function ArtistCard({ artist, isArtist = true, className = '' }) {
	const rating = formatRating(artist.averageRating);

	return (
		<Link to={`/artist/${artist.username}`} className={`artist-card ${className}`}>
			<div className="mb-2">
				<Avatar name={artist.displayName} photo={artist.photo} className="w-[60px] h-[60px]" />
			</div>
			<div className="artist-card-name">{artist.displayName}</div>
			{isArtist && (
				<div className="flex justify-center items-center mt-1 mb-1.5 h-6">
					<RatingStars rating={rating} size={19} />
					{rating > 0 && (
						<div className="mt-0.5 ml-1.5 text-secondary text-md">
							{rating || ''} <span className="text-secondary">({artist.receivedReviews})</span>
						</div>
					)}
				</div>
			)}

			{isArtist && (
				<div className="flex justify-center items-start flex-wrap mt-1 flex-1">
					{artist.categories?.slice(0, 4).map((item) => {
						return (
							<div key={item} className="artist-card-category">
								{item}
							</div>
						);
					})}
				</div>
			)}
		</Link>
	);
}
