import useIsMobile from 'hooks/useIsMobile';
import { useRef, useState, useEffect } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

export default function Collapsible({
	title,
	children,
	isProductDetails,
	containerClassName = '',
	headerClassName = 'text-dark-secondary font-semibold',
	bodyClassName = '',
	contentClassName = '',
	openByDefault = false,
	autoAdjustHeight = false,
	onClose = () => {},
	onOpen = () => {},
}) {
	const containerRef = useRef();
	const contentRef = useRef();
	const bodyRef = useRef();
	const isMobile = useIsMobile();
	const [isOpen, setIsOpen] = useState(false);
	const [seeMoreButtons, setSeeMoreButtons] = useState(false);
	const [isFullHeight, setIsFullHeight] = useState(isMobile);

	const close = () => {
		if (!containerRef.current) return;
		bodyRef.current.style.maxHeight = 0;
		onClose();
	};

	const open = () => {
		if (!contentRef.current) return;

		if (isProductDetails) {
			if (!isFullHeight) {
				bodyRef.current.style.maxHeight = '300px';
			} else {
				bodyRef.current.style.maxHeight = `${contentRef.current.clientHeight + 30}px`;
			}
		} else {
			bodyRef.current.style.maxHeight = `${contentRef.current.clientHeight}px`;
		}

		onOpen();
	};

	const toggle = () => {
		if (isOpen) {
			close();
		} else {
			open();
		}
		setIsOpen(!isOpen);
	};

	// open by default
	useEffect(() => {
		if (openByDefault) {
			toggle();
		}
	}, [openByDefault]);

	// check if it's productDetails and content is higher than 300px
	useEffect(() => {
		if (isProductDetails && contentRef.current.clientHeight > 300) {
			setSeeMoreButtons(true);
		}
	}, []);

	useEffect(() => {
		if (autoAdjustHeight && isOpen) {
			open();
		}
	}, [children]);

	const handleSeeMoreClick = () => {
		if (!containerRef.current) return;
		bodyRef.current.style.maxHeight = `${contentRef.current.clientHeight + 30}px`;
		setIsFullHeight(true);
	};

	const handleSeeLessClick = () => {
		if (!containerRef.current) return;

		// Get the position of the container relative to the viewport
		const { top } = containerRef.current.getBoundingClientRect();

		// Set the max-height of the bodyRef element to 300px
		if (bodyRef.current) {
			bodyRef.current.style.maxHeight = '300px';
		}

		// Scroll the page to align the top of the container with the top of the viewport
		window.scrollTo({ top: window.scrollY + top - 100, behavior: 'smooth' });

		setIsFullHeight(false);
	};

	return (
		<>
			<div className="relative">
				<div ref={containerRef} className={`collapsible ${containerClassName}`}>
					<div className={`collapsible__header ${headerClassName}`} onClick={toggle}>
						<p>{title}</p>

						<RiArrowDownSLine
							size={20}
							className={`mt-0.5 ml-2 transition-transform duration-200 shrink-0 ${
								isOpen ? 'rotate-180' : ''
							}`}
						/>
					</div>

					<div ref={bodyRef} className={`collapsible__body relative ${bodyClassName}`}>
						<div className={`p-5 ${contentClassName}`} ref={contentRef}>
							<div>
								{children}
								{seeMoreButtons && (
									<div
										onClick={handleSeeLessClick}
										className={`${isFullHeight && isOpen ? 'block' : 'hidden'}`}
									>
										<div
											className="cursor-pointer mx-auto hover:bg-gray rounded-full w-fit py-2 px-4 transition-all mt-1"
											onClick={handleSeeLessClick}
										>
											<span className="font-semibold text-md">
												Vezi mai puține detalii
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
						{seeMoreButtons && (
							<div className="">
								{!isFullHeight && (
									<div
										className={` ${
											isFullHeight || !isOpen ? 'hidden' : 'block'
										} transition-all delay-500`}
									>
										<div className="see-more-cover"></div>
										<div
											className="cursor-pointer absolute bottom-1 ml-auto mr-auto text-center left-0 right-0 hover:bg-gray rounded-full w-fit py-2 px-4 transition-all"
											onClick={handleSeeMoreClick}
										>
											<span className="font-semibold text-md fade-in">
												Vezi mai multe detalii
											</span>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
