import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import './css/index.css';
import './css/search.css';
import './css/cards.css';
import './css/navbar.css';
import './css/modal.css';
import './css/chat.css';
import './css/userDashboard.css';
import './css/home.css';
import './css/login.css';
import './css/buttons.css';

import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import LogRocket from 'logrocket';
import ErrorBoundary from 'components/ErrorBoundary';
import App from './App';
import * as Sentry from '@sentry/react';
import { SWRConfig } from 'swr';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { ChatProvider } from './context/ChatContext';
import { get } from 'api/_methods';

if (window.location.pathname === '/auth/success' || window.location.pathname === '/auth/error') {
	// For google and facebook login popups
	window.close();
} else {
	// Init Sentry
	Sentry.init({
		dsn: 'https://92613722cf654d01b1e7ffb06ce5887a@o4504045580582912.ingest.sentry.io/4504045581959168',
		enabled: process.env.NODE_ENV === 'production',
	});

	// Init GA
	ReactGA.initialize('G-LXF3KZJLV6');

	// Init LogRocket
	if (process.env.NODE_ENV === 'production') {
		LogRocket.init('1gauix/custom-studio');
	}

	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(
		<ErrorBoundary>
			<UserProvider>
				<ChatProvider>
					<BrowserRouter>
						<SWRConfig value={{ fetcher: get }}>
							<App />
						</SWRConfig>
					</BrowserRouter>
				</ChatProvider>
			</UserProvider>
		</ErrorBoundary>
	);
}
