import { useEffect } from 'react';

export const defaultTitle = 'Custom Studio - Handmade și Personalizare';

export default function usePageTitle(title) {
	useEffect(() => {
		window.document.title = title || defaultTitle;

		return () => {
			window.document.title = defaultTitle;
		};
	}, [title]);
}
