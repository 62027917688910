import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getCategoryIcon } from 'utils/utils';

export default function CategoryCard({ category, className = '' }) {
	const Icon = useMemo(() => getCategoryIcon(category), [category]);

	return (
		<Link
			to={`/products/category/${category}`}
			className={`flex items-center border-2 border-light-blue rounded-md px-5 py-3 hover:bg-light-blue transition-all ${className}`}
		>
			<Icon className="w-8 mr-3" />
			<span className="xl:text-lg text-center flex justify-center">{category}</span>
		</Link>
	);
}
