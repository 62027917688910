import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Status } from 'custom-studio-constants';
import { updateUserCashOnDelivery } from 'api/adminApi';
import Button from 'components/Button';
import StatusIcon from 'components/StatusIcon';
import WhiteContainer from 'components/WhiteContainer';
import UserContext from 'context/UserContext';
import isAdmin from 'utils/isAdmin';

export default function AdminUserActions({ profile }) {
	const { user } = useContext(UserContext);
	const [userProfile, setUserProfile] = useState(profile);

	const approveCashOnDelivery = async () => {
		if (window.confirm('Sigur?')) {
			const res = await updateUserCashOnDelivery(userProfile._id, Status.Approved);

			if (res.error) {
				toast.error(res.error);
			} else {
				setUserProfile({ ...userProfile, cashOnDeliveryStatus: Status.Approved });
			}
		}
	};

	useEffect(() => {
		setUserProfile(profile);
	}, [profile]);

	if (!isAdmin(user) || !userProfile) return null;

	return (
		<WhiteContainer className="p-4 my-5 md:p-8">
			<p className="font-semibold text-sm uppercase text-secondary mb-3">Admin panel</p>
			<div className="flex items-center">
				<StatusIcon status={userProfile.cashOnDeliveryStatus} />
				<p className="ml-2">
					Plata la livrare{' '}
					<span className="text-secondary">(+{userProfile.cashOnDeliveryExtraCost} lei)</span>
				</p>

				{userProfile.cashOnDeliveryStatus === Status.Pending && (
					<Button
						size="small"
						containerClassName="warning-light ml-2"
						text="Aprobă"
						onClick={approveCashOnDelivery}
					/>
				)}
			</div>
		</WhiteContainer>
	);
}
