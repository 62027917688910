import { captureMessage } from '@sentry/react';
import { API } from '../constants';

const requestOptions = (isJson) => {
	const opts = {
		mode: 'cors',
		credentials: 'include',
		cache: 'no-cache',
	};
	if (isJson) {
		opts.headers = {
			'Content-Type': 'application/json',
		};
	}
	return opts;
};

// Always expecting json from server
async function parseResponse(response) {
	const contentType = response.headers.get('content-type');

	// if server sends a voluntary error, it will be in json format
	if (contentType?.includes('application/json')) {
		const res = await response.json();
		if (res.error) captureMessage(res.error);
		return res;
	}

	// if error and no json
	if (!response.ok) {
		return { error: 'Conexiune eșuată.' };
	}

	// in case the server send only a 200 response status
	return {};
}

export async function post(url, data, isJson = false) {
	try {
		const response = await fetch(`${API}${url}`, {
			...requestOptions(isJson),
			method: 'POST',
			body: data,
		});

		return parseResponse(response);
	} catch (err) {
		captureMessage(`POST error: ${err}`, { extra: { message: 'Conexiune eșuată' } });
		return { error: 'Conexiune eșuată.' };
	}
}

export function postJson(url, data) {
	return post(url, JSON.stringify(data), true);
}

export async function _delete(url, data, isJson = false) {
	try {
		const response = await fetch(`${API}${url}`, {
			...requestOptions(isJson),
			method: 'DELETE',
			body: data,
		});

		return parseResponse(response);
	} catch (err) {
		captureMessage(`DELETE error: ${err}`, { extra: { message: 'Conexiune eșuată' } });
		return { error: 'Conexiune eșuată.' };
	}
}

export function _deleteJson(url, data) {
	return _delete(url, JSON.stringify(data), true);
}

export async function get(url) {
	try {
		const response = await fetch(`${API}${url}`, {
			...requestOptions(),
			method: 'GET',
		});

		return parseResponse(response);
	} catch (err) {
		captureMessage(`GET error: ${err}`, { extra: { error: err, message: 'Conexiune eșuată' } });
		return { error: 'Conexiune eșuată. Reîncearcă.' };
	}
}
