import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { InputSearch } from 'components/Input';
import { RiCloseLine, RiInformationLine, RiSearchLine, RiStore2Line, RiUserLine } from 'react-icons/ri';
import Logo from './Logo';
import Avatar from 'components/Avatar';
import UIModal from 'components/modals/UIModal';
import UserContext from 'context/UserContext';
import CategoryLink from 'components/CategoryLink';
import { Category } from 'custom-studio-constants';

export default function SideMenu({ onRequestClose, openLoginPanel }) {
	const { user } = useContext(UserContext);
	const [search, setSearch] = useState('');
	const navigate = useNavigate();

	const handleSearch = () => {
		navigate(`/products/search/${search}`);
	};

	return (
		<UIModal name="side-menu-nav" id="side-menu" onRequestClose={onRequestClose} closeOnContainerClick>
			<div className="flex justify-between items-center mb-6">
				<Logo compact={false} />
				<RiCloseLine className="text-primary" size={24} onClick={onRequestClose} />
			</div>

			{/* My account */}
			{user ? (
				<div className="flex items-center" onClick={() => navigate('/user/profile')}>
					<Avatar name={user.displayName} photo={user.largePhoto} className="w-11 h-11" />
					<div className="ml-2">{user.displayName}</div>
				</div>
			) : (
				<div className="flex items-center" onClick={openLoginPanel}>
					<div className="flex items-center justify-center w-11 h-11 rounded-full bg-gray">
						<RiUserLine className="text-secondary" size={22} />
					</div>
					<div className="ml-2.5">Contul meu</div>
				</div>
			)}

			<hr className="border-gray my-4" />

			<InputSearch
				placeHolder="Începe o nouă căutare"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onSubmit={handleSearch}
			/>

			<PageLink to="/products" Icon={RiSearchLine}>
				Descoperă
			</PageLink>
			<PageLink to="/artists" Icon={RiStore2Line}>
				Magazine
			</PageLink>
			<PageLink to="faq" Icon={RiInformationLine}>
				Întrebări frecvente
			</PageLink>

			<hr className="border-gray my-4" />

			{Object.values(Category).map((category) => (
				<CategoryLink key={category} category={category} />
			))}
		</UIModal>
	);
}

function PageLink({ children, Icon, to }) {
	return (
		<Link to={to}>
			<div className="flex items-center my-4">
				<Icon size={20} className="mr-3" />
				{children}
			</div>
		</Link>
	);
}
