import React, { useContext, useEffect, useMemo, useState } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { API } from '../constants';
import { getChats } from '../api/chatApi';
import UserContext from './UserContext';
import ChatNotifier from 'utils/ChatNotifier';

// TODO: improve performance by merging live messages and chat messages
const ChatContext = React.createContext();
export default ChatContext;

const socket = io(API, {
	withCredentials: true,
	autoConnect: false,
	reconnection: false,
});

export function ChatProvider({ children }) {
	const { user, isLoggedIn } = useContext(UserContext);
	const [isConnected, setIsConnected] = useState(false);
	const [chatsLoaded, setChatsLoaded] = useState(false);
	const [chats, setChats] = useState([]);
	const [messages, setMessages] = useState([]);
	const [activeChatId, setActiveChatId] = useState(null);
	const [chatBan, setChatBan] = useState(false);

	const activeChat = useMemo(() => {
		return chats.find((chat) => chat._id === activeChatId);
	}, [activeChatId, chats]);

	const updateChat = (chatId, patch) => {
		setChats((prev) =>
			prev.map((chat) => {
				if (chat._id === chatId) {
					return { ...chat, ...patch };
				}
				return chat;
			})
		);
	};

	const addTempChat = (chat) => {
		setChats((prev) => {
			if (!prev.find((c) => c._id === chat._id)) {
				return [...prev, chat];
			}
			return prev;
		});
	};

	const hasUnseenMessages = (chat) => {
		if (!chat || chat.temporary) return false;
		if (chat.unseenMessages) return true;
		return messages.some((msg) => msg.chatId === chat._id && msg.to === user._id && !msg.seen);
	};

	const sendChatMessage = (msg, createNewChat = false) => {
		if (chatBan) return;
		let imageBuffer;

		msg._id = generateMessageId(msg);

		if (msg.text) {
			msg.text = msg.text.trim();
		} else if (msg.image) {
			imageBuffer = msg.image;
			delete msg.image;
		}

		if (createNewChat) {
			socket.emit('create_chat_with_message', msg, imageBuffer);
		} else {
			socket.emit('message', msg, imageBuffer);
		}

		// optimistically show the message
		// the server will set its own timestamp anyway, we can't trust the client's time
		msg.timestamp = Date.now();
		msg.loading = true;

		if (imageBuffer) {
			const fr = new FileReader();
			fr.readAsDataURL(new Blob([imageBuffer]));

			fr.onloadend = (data) => {
				msg.image = data.target.result;
				setMessages((prev) => [...prev, msg]);
			};
		} else {
			setMessages((prev) => [...prev, msg]);
		}
	};

	// Seen active chat
	const sendSeen = () => {
		socket.emit('update_last_seen', {
			to: activeChat.contact._id,
			chatId: activeChat._id,
		});

		if (activeChat.unseenMessages) {
			updateChat(activeChat._id, { unseenMessages: 0 });
		}
	};

	const loadChats = async () => {
		const chats = await getChats();
		if (chats.error) {
			toast.error(chats.error);
		} else {
			chats.forEach(processNewChat);
			if (activeChat) {
				const found = chats.find((chat) => chat._id === activeChat._id);
				if (found) {
					found.unseenMessages = 0;
					sendSeen();
				}
			}
			setChats(chats);
		}
		setChatsLoaded(true);
	};

	// Place here only events with no dependencies
	const connectChat = async () => {
		socket.removeAllListeners();
		socket.connect();

		socket.on('connect', () => {
			console.log('Chat connected');
			setIsConnected(true);
		});

		socket.on('connect_error', (err) => {
			console.error(`Error connecting to chat: ${err.message}`);
			captureException(err, {
				extra: { message: 'Error connecting to chat', user, isConnected, chatsLoaded },
			});
		});

		// the other user has seen my messages
		socket.on('update_last_seen', ({ chatId, lastSeenTimestamp }) => {
			setChats((prev) =>
				prev.map((chat) => {
					if (chat._id === chatId) {
						for (const msg of chat.messages) {
							if (msg.from === user._id && msg.timestamp <= lastSeenTimestamp) {
								msg.seen = true;
							}
						}
					}
					return { ...chat };
				})
			);
			setMessages((prev) =>
				prev.map((msg) => {
					if (
						msg.chatId === chatId &&
						msg.from === user._id &&
						msg.timestamp <= lastSeenTimestamp
					) {
						msg.seen = true;
					}
					return { ...msg };
				})
			);
		});

		socket.on('chat_block', ({ chatId }) => {
			updateChat(chatId, { blocked: true });
		});

		socket.on('toast_error', (data) => {
			toast.error(data);
		});

		socket.on('disconnect', (reason) => {
			console.log('Chat disconnected');
			setIsConnected(false);
			captureMessage(`Chat disconnected. Reason: ${reason}`);
		});
	};

	const reconnectChat = () => {
		connectChat();
		loadChats();
	};

	// place here events with dependencies
	useEffect(() => {
		if (!isConnected) return;

		socket.on('message', (newMsg) => {
			if (newMsg.to === user._id) {
				// the message is for me
				if (newMsg.chatId === activeChat?._id && !document.hidden) {
					// this chat is currently open and the document is visible
					sendSeen();
				} else {
					// this chat is not open -> add unseen messages
					setChats((prevChats) =>
						prevChats.map((chat) => {
							if (chat._id === newMsg.chatId) {
								ChatNotifier.notify({
									body: `${chat.contact.displayName}: ${newMsg.text}`,
									icon: chat.contact.photo,
								});
								return { ...chat, unseenMessages: chat.unseenMessages + 1 };
							}
							return chat;
						})
					);
				}
			}

			// if the message is from me, it means it's already in the chat, so replace it
			if (newMsg.from === user._id && messages.find((m) => m._id === newMsg._id)) {
				setMessages((prev) => prev.map((msg) => (msg._id === newMsg._id ? newMsg : msg)));
			} else {
				setMessages((prev) => [...prev, newMsg]);
			}
		});

		socket.on('global_ban', (data) => {
			setChatBan(true);
			setTimeout(() => setChatBan(null), data.banEnd - Date.now());

			if (activeChat) {
				setMessages((prev) => [
					...prev,
					{
						text: data.reason,
						timestamp: Date.now(),
						error: true,
						from: user._id,
						to: user._id,
						chatId: activeChat._id,
						id: Math.random(),
					},
				]);
			}
		});

		socket.on('new_chat', (newChat) => {
			processNewChat(newChat);
			setChats((prev) => [...prev.filter((chat) => !chat.temporary), newChat]);

			if (activeChat?.contact._id === newChat.contact._id) {
				// switch from the temporary chat to the newly created one
				setActiveChatId(newChat._id);
			} else if (newChat.messages[0].from === newChat.contact._id) {
				// this is a new incoming message for me
				ChatNotifier.notify({
					body: `${newChat.contact.displayName}: ${newChat.messages[0].text}`,
					icon: newChat.contact.photo,
				});
			}
		});

		socket.on('chat_unblock', ({ chatId }) => {
			setChats((prev) =>
				prev.map((chat) => ({ ...chat, blocked: chat._id === chatId ? false : chat.blocked }))
			);
		});

		return () => {
			socket.removeAllListeners('message');
			socket.removeAllListeners('global_ban');
			socket.removeAllListeners('new_chat');
			socket.removeAllListeners('chat_unblock');
		};
	}, [user, isConnected, activeChat, messages]);

	// When the activeChat changes, send last seen update
	useEffect(() => {
		if (hasUnseenMessages(activeChat)) {
			sendSeen();
		}
	}, [activeChatId]);

	// Update last seen when coming back to this tab
	useEffect(() => {
		function onVisibilityChange() {
			if (!document.hidden && hasUnseenMessages(activeChat)) {
				sendSeen();
			}
		}
		document.addEventListener('visibilitychange', onVisibilityChange);
		return () => document.removeEventListener('visibilitychange', onVisibilityChange);
	}, [activeChat]);

	// Retrieve chats and connect to chat when the user logs in.
	// Disconnect from chat when the user logs out.
	useEffect(() => {
		if (isLoggedIn) {
			loadChats();
			connectChat();
		} else {
			socket.disconnect();
			socket.removeAllListeners();
			setChats([]);
			setChatsLoaded(false);
			setMessages([]);
			setActiveChatId(null);
			setChatBan(false);
		}
	}, [isLoggedIn]);

	return (
		<ChatContext.Provider
			value={{
				reconnectChat,
				isConnected,
				chats,
				chatsLoaded,
				updateChat,
				addTempChat,
				messages,
				sendChatMessage,
				activeChat,
				setActiveChatId,
				chatBan,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
}

function generateMessageId(msg) {
	return `${Date.now()}-${msg.to}-${Math.random().toString(32).slice(2)}`;
}

function processNewChat(chat) {
	chat.contact = typeof chat.user1 === 'string' ? chat.user2 : chat.user1;

	const lastSeenByMe = typeof chat.user1 === 'string' ? chat.lastSeenByUser1 : chat.lastSeenByUser2;
	const lastSeenByContact = typeof chat.user1 === 'string' ? chat.lastSeenByUser2 : chat.lastSeenByUser1;
	const sentByMe = chat.messages.filter((msg) => msg.to === chat.contact._id);
	const sentByContact = chat.messages.filter((msg) => msg.from === chat.contact._id);

	chat.unseenMessages = sentByContact.filter((msg) => msg.timestamp > lastSeenByMe).length;

	// mark user messages as seen
	for (const msg of sentByMe) {
		if (msg.timestamp <= lastSeenByContact) {
			msg.seen = true;
		}
	}
}
