import { get, post, postJson, _delete } from './_methods';

export function approveProduct(productId, notifyArtist, category) {
	return postJson(`/admin/products/${productId}/approve`, { notifyArtist, category });
}

export function approveReview(reviewId) {
	return post(`/admin/reviews/${reviewId}/approve`);
}

export function denyReview(reviewId, reason) {
	return postJson(`/admin/reviews/${reviewId}/deny`, { reason });
}

export function denyProduct(productId, reasonForDenial) {
	return postJson(`/admin/products/${productId}/deny`, { reasonForDenial });
}

export function getAdminOrders() {
	return get('/admin/orders');
}

export function getAllUsers() {
	return get('/admin/users');
}

export function getUser(id) {
	return get(`/admin/user/${id}`);
}

export function getAllChats() {
	return get('/admin/chats');
}

export function updateUserCashOnDelivery(userId, status) {
	return postJson(`/admin/users/${userId}/cash-on-delivery`, { status });
}

export function sendUnpurchasedCartEmail(userId) {
	return postJson(`/admin/users/${userId}/send-unpurchased-cart-email`);
}

export function generateInvoice(orderNumber, data) {
	return postJson(`/admin/invoice/${orderNumber}`, data);
}

export function deleteOrder(orderId) {
	return _delete(`/admin/orders/${orderId}`);
}

export function deleteReview(reviewId) {
	return _delete(`/admin/reviews/${reviewId}`);
}

export function deleteChat(chatId) {
	return _delete(`/admin/chats/${chatId}`);
}

export function searchLocation(needle) {
	return post(`/admin/search-location/${needle}`);
}

export function searchStreet(city, county, needle) {
	return post(`/admin/search-street/${city}/${county}/${needle}`);
}

export function searchPostalCode(city, county, street) {
	return post(`/admin/search-postal-code/${city}/${county}/${street}`);
}

export function generateAWB(orderNumber, data) {
	console.log(data);
	return postJson(`/admin/generate-awb/${orderNumber}`, data);
}
