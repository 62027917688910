import { ADMIN } from 'custom-studio-constants';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import LoadingSpinner from './../components/LoadingSpinner';

export default function ProtectedRoute({ children, role }) {
	const { user, checkingSession } = useContext(UserContext);

	if (checkingSession) {
		return <LoadingSpinner size={24} className="mt-10 text-secondary mx-auto" />;
	}

	if (!user) {
		return <Navigate to="/" replace />;
	}

	// restrict access to allow one role or ADMIN
	if (role && user.role !== role && user.role !== ADMIN) {
		return <Navigate to="/" replace />;
	}

	return children;
}
