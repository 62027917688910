import { useContext, useState } from 'react';
import { CheckBox } from 'components/Input';
import { toast } from 'react-toastify';
import { Status } from 'custom-studio-constants';
import { requestCashOnDelivery } from 'api/userApi';
import UserContext from 'context/UserContext';
import UIModal from './UIModal';
import StatusIcon from 'components/StatusIcon';
import Form from 'components/Form';
import Button from 'components/Button';
import PriceChangeInput from 'components/PriceChangeInput';

export default function RequestCashPaymentModal({ onRequestClose }) {
	const { user, setUser } = useContext(UserContext);
	const [showSuccessScreen, setShowSuccessScreen] = useState(false);

	const handleSubmit = async (formData) => {
		for (let i = 1; i <= 3; ++i) {
			if (formData.get(`check-${i}`) !== 'on') {
				toast.error('Te rog să confirmi toate politicile.');
				return;
			}
		}

		const res = await requestCashOnDelivery(formData.get('cashOnDeliveryExtraCost') || 0);

		if (res.error) {
			toast.error(res.error);
		} else {
			setShowSuccessScreen(true);
			setUser({ ...user, cashOnDeliveryStatus: res.cashOnDeliveryStatus });
		}
	};

	if (!showSuccessScreen) {
		return (
			<UIModal
				name="request-cash-payment"
				containerClassName="!bg-gray"
				onRequestClose={onRequestClose}
				className="mt-auto mb-auto p-6 sm:py-6 sm:px-8 w-full max-w-2xl"
			>
				<h3 className="text-center text-blue">Confirmă politica de livrare și retur</h3>

				<p className="my-5 text-lg">
					Pentru a le asigura cumpărătorilor o cât mai bună experiență, poți oferi următoarele
					garanții:
				</p>

				<p className="font-semibold mb-1">Articole personalizate</p>
				<p className="text-md text-secondary">
					Articolele personalizate nu beneficiază de drept de retur. Cu toate acestea, dacă
					produsele se dovedesc a fi defectuoase sau nu corespund descrierii, ele trebuie reparate,
					sau înlocuite, sau banii trebuie restituiți clientului prin transfer bancar. Clientul are
					dreptul de a reclama produsul și de a cere returul în maxim 48 de ore de la primirea
					comenzii.
				</p>

				<p className="mt-8 font-semibold mb-1">Articole nepersonalizate</p>
				<p className="text-md text-secondary mb-6">
					În baza legislației UE, clientul are dreptul de a anula și returna comanda în termen de 14
					zile, indiferent de motiv. Banii se vor restitui prin transfer bancar, în urma returnării
					produsului și a verificării acestuia de către vânzător, sau chiar și de Custom Studio în
					cazul în care se dorește refuzarea returului. Costurile de transport aferente procesului
					de retur sunt suportate de client.
				</p>

				<Form onSubmit={handleSubmit}>
					<CheckBox
						name="check-1"
						className="text-md mb-2 leading-5"
						label="Mă încredințez să respect politicile listate mai sus."
					/>
					<CheckBox
						name="check-2"
						className="text-md mb-2 leading-5"
						label="Confirm că în urma unui retur acceptat, voi restitui banii clientului prin transfer bancar."
					/>
					<CheckBox
						name="check-3"
						className="text-md mb-2 leading-5"
						label="Produsele sau serviciile listate îmi aparțin și sunt în totalitate conform descrierii și imaginilor atașate. În caz contrar, Custom Studio va putea solicita remunerarea oricărui prejudiciu creat. "
					/>

					<div className="my-8 min-w-md shrink-0">
						<p className="font-semibold texst-lg">Cost suplimentar pentru plata la livrare</p>
						<p className="text-secondary text-md mb-2">
							Stabilește un cost suplimentar ce se adaugă automat la totalul comenzii{' '}
							<strong>doar atunci când clientul alege plata la livrare</strong>. Atenție, acesta
							nu este costul de transport!
						</p>

						<PriceChangeInput name="cashOnDeliveryExtraCost" />
					</div>

					<Button
						type="submit"
						containerClassName="primary block mx-auto mt-6"
						text="Trimite cererea"
					/>
				</Form>
			</UIModal>
		);
	}

	if (showSuccessScreen) {
		return (
			<UIModal
				name="request-cash-payment"
				containerClassName="!bg-gray"
				onRequestClose={onRequestClose}
				className="mt-auto mb-auto p-6 sm:py-6 sm:px-8 w-full max-w-xl"
			>
				<div className="text-center">
					<div className="mx-auto bg-light-green p-1 rounded-full inline-block">
						<StatusIcon status={Status.Approved} size={130} />
					</div>

					<h3 className="mt-2 font-semibold">Cererea a fost trimisă!</h3>
				</div>

				<p className="text-secondary mt-8">
					Mulțumim pentru timpul acordat! Vom reveni în cel mai scurt timp pe mail. Între timp,
					pentru a facilita procesul, asigură-te că îndeplinești următoarele cerințe:
				</p>
				<p className="text-secondary mt-4">
					• Ai o descriere a profilului.
					<br />• Ai conturi de social media adăugate.
					<br /> • Ai o poză cu tine sau logo-ul business-ului tău ca și avatar.
					<br /> • Ai adresa corectă adăugată.
				</p>

				<Button
					containerClassName="light block mx-auto mt-8"
					text="Înapoi la profil"
					onClick={onRequestClose}
				/>
			</UIModal>
		);
	}
}
