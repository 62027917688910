import { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { RiDeleteBack2Line, RiSearchLine } from 'react-icons/ri';
import { CheckBox, RadioBox } from '../components/Input';
import { ArtistCardSkeleton } from '../utils/skeletons';
import useSWR from 'swr';
import usePageTitle from 'hooks/usePageTitle';
import useScrollToTop from '../hooks/useScrollToTop';
import useGrayBackground from '../hooks/useGrayBackground';
import ArtistCard from '../components/ArtistCard';
import WhiteContainer from '../components/WhiteContainer';
import useFilter from 'hooks/useFilter';
import useFilters from '../hooks/useFilters';
import Collapsible from '../components/Collapsible';
import { Category } from 'custom-studio-constants';

const sortFunctions = {
	default: () => 0,
	sales: (a, b) => {
		if (b.sales > a.sales) return 1;
		if (b.sales === a.sales) {
			return b.categories.length - a.categories.length;
		}
		return -1;
	},
	'rating-desc': (a, b) => b.averageRating - a.averageRating,
};

export default function Artists() {
	useScrollToTop();
	useGrayBackground();
	usePageTitle('Magazine handmade');
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useFilter('search', searchParams, setSearchParams);
	const [sortType, setSortType] = useFilter('sort', searchParams, setSearchParams, 'default');
	const {
		filters: categories,
		toggleFilter: toggleCategory,
		clearFilters: clearCategories,
	} = useFilters('categories', searchParams, setSearchParams);
	const [search, setSearch] = useState(searchQuery);
	const [filteredArtists, setFilteredArtists] = useState([]);
	const sortFn = sortFunctions[sortType];
	const canSearch = search.trim().length > 2;

	const { data } = useSWR(`/search-artists?search=${searchQuery}`, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	});

	let artists = data;
	if (data?.error) {
		toast.error(data.error);
		artists = undefined;
	}

	const onSort = (e) => {
		setSortType(e.target.value);
		setFilteredArtists([...filteredArtists].sort(sortFunctions[e.target.value]));
	};

	const onSearch = async (e) => {
		e.target.blur();

		if (search === searchQuery) return;
		if (search.length === 0) return clearSearch();
		if (!canSearch) return;

		clearCategories();
		setSortType(null);
		setSearchQuery(search);
		setFilteredArtists([]);
	};

	const clearSearch = () => {
		setSearch('');
		clearCategories();
		setSortType(null);
		setSearchQuery('');
		setFilteredArtists([]);
	};

	// update filtered artists when the filters change
	useEffect(() => {
		if (!artists) return;
		if (categories.length === 0) {
			setFilteredArtists([...artists].sort(sortFn));
		} else {
			setFilteredArtists(
				artists.filter((a) => a.categories.some((c) => categories.includes(c))).sort(sortFn)
			);
		}
	}, [categories, artists]);

	const renderedCheckBoxes = Object.values(Category).map((cat) => (
		<CheckBox
			key={cat}
			label={cat}
			onChange={() => toggleCategory(cat)}
			checked={categories.includes(cat)}
			className="mt-1"
		/>
	));

	const renderRadioBoxes = (group) => (
		<>
			<RadioBox
				label="Popularitate"
				className="mt-1"
				name={group}
				value="sales"
				checked={sortType === 'sales'}
				onCheck={onSort}
			/>
			<RadioBox
				label="Rating"
				className="mt-1"
				name={group}
				value="rating-desc"
				checked={sortType === 'rating-desc'}
				onCheck={onSort}
			/>
		</>
	);

	const renderedArtists = useMemo(() => {
		if (!artists) {
			return (
				<>
					<ArtistCardSkeleton className="late-fade-in" />
					<ArtistCardSkeleton className="late-fade-in" />
					<ArtistCardSkeleton className="late-fade-in" />
					<ArtistCardSkeleton className="late-fade-in" />
				</>
			);
		}

		return filteredArtists.map((a) => <ArtistCard key={a._id} artist={a} />);
	}, [filteredArtists, artists]);

	return (
		<div className="container !px-2.5 my-4 md:my-6">
			<div className="flex md:items-start flex-col md:flex-row">
				<div className="w-full md:w-56 shrink-0 top-20 mr-4">
					{/* Search */}
					<WhiteContainer className="pl-4 flex md:mb-4 pt-0.5">
						<input
							type="search"
							placeholder="Caută magazine"
							className="w-full bg-white py-4 text-md outline-none min-w-0"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							onKeyDown={(e) => {
								if (e.keyCode === 13) onSearch(e);
							}}
						/>
						<div
							className="flex items-center px-4 cursor-pointer hover:scale-110 transition-transform text-secondary"
							onClick={onSearch}
						>
							<RiSearchLine size={20} />
						</div>
					</WhiteContainer>

					{/* Desktop Filters */}
					<WhiteContainer className="hidden md:block px-5 py-4 mb-4 text-secondary">
						<div className="text-xl mb-3 text-primary">Categorii</div>
						{renderedCheckBoxes}
					</WhiteContainer>

					<WhiteContainer className="hidden md:block px-5 py-4 text-secondary">
						<div className="text-xl mb-3 text-primary">Ordonează</div>
						{renderRadioBoxes('desktop-sort')}
					</WhiteContainer>
				</div>

				{/* Mobile filters */}
				<div className="flex items-start md:hidden">
					<Collapsible
						title="Categorii"
						containerClassName="flex-1 mr-1 !border-0"
						headerClassName="!bg-white text-secondary font-semibold"
						contentClassName="bg-white pt-2 pb-4"
					>
						{renderedCheckBoxes}
					</Collapsible>
					<Collapsible
						title="Ordonează"
						containerClassName="flex-1 !border-0"
						headerClassName="!bg-white text-secondary font-semibold"
						contentClassName="bg-white pt-2 pb-4"
						titleClassName="text-secondary"
					>
						{renderRadioBoxes('mobile-sort')}
					</Collapsible>
				</div>

				{/* List */}
				<div className="w-full">
					{searchQuery && (
						<div className="flex items-center py-3.5 md:mb-3.5 text-dark-secondary">
							<h4>Căutare după: {searchQuery}</h4>
							<RiDeleteBack2Line
								className="ml-3 mt-1 cursor-pointer hover:text-red shrink-0"
								size={20}
								onClick={clearSearch}
							/>
						</div>
					)}

					<div className="artists-container">{renderedArtists}</div>
				</div>
			</div>
		</div>
	);
}
