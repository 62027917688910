import { approveProduct, denyProduct } from 'api/adminApi';
import { CheckBox, InputSelect, InputTextArea } from 'components/Input';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import UIModal, { UIModalCloseButton } from 'components/modals/UIModal';
import Form from 'components/Form';
import { Category } from 'custom-studio-constants';

export default function ProductAdminPanel({ product, setProduct, onEditProduct }) {
	const [isLoading, setIsLoading] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showDenyModal, setShowDenyModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(product.category);
	const [notifyArtistOnApprove, setNotifyArtistOnApprove] = useState(false);

	const handleApprove = async () => {
		if (isLoading) return;
		setIsLoading(true);

		const res = await approveProduct(product._id, notifyArtistOnApprove, selectedCategory);

		if (res.error) {
			toast.error(res.error);
		} else {
			toast.success(res.message, { autoClose: 1000 });
			setProduct(res.product);
			setSelectedCategory(null);
			setNotifyArtistOnApprove(true);
			setShowApproveModal(false);
		}

		setIsLoading(false);
	};

	const handleDeny = async (formData) => {
		const { reasonForDenial } = Object.fromEntries(formData);
		const res = await denyProduct(product._id, reasonForDenial);

		if (res.error) {
			toast.error(res.error);
		} else {
			toast.success(res.message, { autoClose: 1000 });
			setProduct(res.product);
			setShowDenyModal(false);
		}
	};

	return (
		<div className="mb-8">
			<div className="light text-center rounded-md p-3.5 mb-2 font-semibold">Admin Actions</div>
			<div className="flex justify-between">
				{!product.approved && (
					<Button
						text="Aprobă"
						containerClassName="success-light mx-1 w-full font-semibold"
						onClick={() => setShowApproveModal(true)}
					/>
				)}
				{!product.approved && !product.denied && (
					<Button
						text="Refuză"
						containerClassName="danger-light mx-1 w-full font-semibold"
						onClick={() => setShowDenyModal(true)}
					/>
				)}
				<Button
					text="Editează"
					containerClassName="primary-light mx-1 w-full font-semibold"
					onClick={onEditProduct}
				/>
			</div>

			{showApproveModal && (
				<UIModal
					name="approve-product"
					onRequestClose={() => setShowApproveModal(false)}
					className="w-full max-w-[300px] p-6 h-[500px]"
				>
					<UIModalCloseButton theme="gray" onClick={() => setShowApproveModal(false)} />
					<h3 className="mb-4">Aprobă</h3>

					<CheckBox
						onChange={(e) => setNotifyArtistOnApprove(e.target.checked)}
						label="Notifică artistul"
						className="mb-3"
						checked={notifyArtistOnApprove}
					/>

					<InputSelect
						id="select-category"
						options={Object.values(Category)}
						placeHolder="Alege categoria"
						selectedOption={selectedCategory}
						onSelect={(option) => setSelectedCategory(option)}
					/>

					<Button
						text="Aprobă produsul"
						onClick={handleApprove}
						containerClassName="w-full success"
						loading={isLoading}
					/>
				</UIModal>
			)}

			{showDenyModal && (
				<UIModal
					name="deny-product"
					onRequestClose={() => setShowDenyModal(false)}
					className="w-full max-w-[420px] p-6"
				>
					<UIModalCloseButton theme="gray" onClick={() => setShowDenyModal(false)} />

					<Form onSubmit={handleDeny}>
						<InputTextArea
							label="Specifică motivul"
							name="reasonForDenial"
							rows={3}
							containerClassName="mt-3 !mb-2"
						/>
						<p className="text-secondary text-sm mb-2">* Artistul va fi anunțat prin email</p>
						<Button type="submit" text="Refuză produsul" containerClassName="w-full danger" />
					</Form>
				</UIModal>
			)}
		</div>
	);
}
