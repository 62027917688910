import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
	RiMapPinLine,
	RiEdit2Fill,
	RiSendPlaneFill,
	RiShareCircleFill,
	RiEyeFill,
	RiPaletteLine,
} from 'react-icons/ri';
import { PublicProfileSkeleton } from '../utils/skeletons';
import { formatRating, formatReceivedReviews } from '../utils/format';
import ReactGA from 'react-ga4';
import UserContext from '../context/UserContext';
import Avatar from '../components/Avatar';
import Button from '../components/Button';
import RatingStars from '../components/RatingStars';
import instagramIcon from '../images/instagram.png';
import tiktokIcon from '../images/tik-tok.png';
import isArtist from 'utils/isArtist';
import LoginPanel from './login/LoginPanel';
import useScrollTo from 'hooks/useScrollTo';

export default function ProfileSection({ profile, selfActions = false }) {
	const navigate = useNavigate();
	const sendMessageAfterLogin = useRef(false);
	const scrollToReviews = useScrollTo('reviews-container');
	const { user } = useContext(UserContext);
	const [showLoginPanel, setShowLoginPanel] = useState(false);

	const rating = formatRating(profile?.averageRating);
	const isOwnProfile = user?._id === profile?._id;

	const onSendMessage = () => {
		if (user) {
			ReactGA.event({ action: 'Open chat with user', category: 'Chat' });
			navigate('/chat', {
				state: {
					messageUser: { _id: profile._id, displayName: profile.displayName, photo: profile.photo },
				},
			});
		} else {
			ReactGA.event({ action: 'Open chat with user (as guest)', category: 'Chat' });
			sendMessageAfterLogin.current = true;
			setShowLoginPanel(true);
		}
	};

	const onEditProfile = () => {
		navigate('/user/profile/edit');
	};

	const onViewProfile = () => {
		if (isArtist(user)) {
			navigate(`/artist/${user.username}`);
		} else {
			navigate(`/profile/${user._id}`);
		}
	};

	useEffect(() => {
		if (!showLoginPanel && !user) {
			sendMessageAfterLogin.current = false;
		}
		if (!showLoginPanel && user && sendMessageAfterLogin.current) {
			sendMessageAfterLogin.current = false;
			onSendMessage();
		}
	}, [user, showLoginPanel]);

	if (!profile) {
		return <PublicProfileSkeleton className="late-fade-in" />;
	}

	return (
		<div className={`mb-2 fade-in ${isOwnProfile ? '' : 'public-profile-container'}`}>
			<div className="md:flex items-center">
				<div className="flex items-center">
					<Avatar
						className="profile-avatar"
						name={profile.displayName}
						photo={profile.largePhoto}
					/>

					<div className="ml-4 md:ml-6">
						<div className="text-lg md:text-xl font-semibold">{profile.displayName}</div>
						{isArtist(profile) && (
							<div
								className="flex items-center mt-1 md:mt-2 cursor-pointer"
								onClick={scrollToReviews}
							>
								<RatingStars size={20} rating={rating} />

								<div className="ml-2">
									{rating || ''}{' '}
									<span className="text-secondary">
										({formatReceivedReviews(profile.receivedReviews)})
									</span>
								</div>
							</div>
						)}

						{profile.address?.city && (
							<div className="flex items-center mt-1 md:mt-2">
								<RiMapPinLine />
								<div className="ml-1">{profile.address.city}</div>
							</div>
						)}
					</div>
				</div>
				<div className="ml-auto flex flex-col mt-5 md:mt-0">
					{!isOwnProfile && (
						<Button
							containerClassName="primary my-1"
							className="flex items-center justify-center"
							text="Trimite mesaj"
							icon={<RiSendPlaneFill size={18} className="mr-2" />}
							onClick={onSendMessage}
						/>
					)}
					{isOwnProfile && selfActions && (
						<>
							<Button
								containerClassName="primary my-1"
								text="Editează"
								icon={<RiEdit2Fill size={18} className="mr-2" />}
								onClick={onEditProfile}
							/>
							{isArtist(profile) ? (
								<Button
									containerClassName="primary-light my-1"
									text="Vezi profilul public"
									icon={<RiEyeFill size={18} className="mr-2" />}
									onClick={onViewProfile}
								/>
							) : (
								<Button
									containerClassName="primary-light my-1"
									text="Deschide magazin"
									icon={<RiPaletteLine size={18} className="mr-2" />}
									onClick={() => navigate('/open-shop')}
								/>
							)}
						</>
					)}
					{!isOwnProfile && isArtist(user) && (
						<Link to="/user/products/add" state={{ customFor: profile }}>
							<Button
								containerClassName="my-1 primary-light w-full"
								text="Trimite ofertă"
								icon={<RiShareCircleFill size={18} className="mr-2" />}
							/>
						</Link>
					)}

					{showLoginPanel && <LoginPanel onRequestClose={() => setShowLoginPanel(false)} />}
				</div>
			</div>

			<div className="mt-4 md:mt-8 ">
				{(profile.about || selfActions) && (
					<>
						<div className="text-lg font-semibold mb-2">Despre mine</div>
						<p className="whitespace-pre-line text-dark-secondary mb-3">
							{profile.about || 'Editează profilul pentru a adăuga propriul text.'}
						</p>
					</>
				)}
				{profile.instagram && (
					<a
						href={`https://instagram.com/${profile.instagram}`}
						className="flex items-center my-1 text-[#FF5500] w-fit"
						target="__blank"
					>
						<img src={instagramIcon} width="18" className="mr-2 mt-0.5" alt="logo instagram" />{' '}
						{profile.instagram}
					</a>
				)}
				{profile.tiktok && (
					<a
						href={`https://tiktok.com/@${profile.tiktok}`}
						className="flex items-center my-1 text-black w-fit"
						target="__blank"
					>
						<img src={tiktokIcon} width="18" className="mr-2" alt="logo tiktok" />{' '}
						{profile.tiktok}
					</a>
				)}
			</div>
		</div>
	);
}
