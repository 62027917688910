import { COMPANY, CUI } from '../constants';
import { Link, useLocation } from 'react-router-dom';

function FooterLink({ to, href, children }) {
	if (to) {
		return (
			<Link to={to} className="footer-link">
				{children}
			</Link>
		);
	}

	return (
		<a href={href} target="_blank" rel="noreferrer" className="footer-link">
			{children}
		</a>
	);
}

export default function Footer() {
	const location = useLocation();
	if (location.pathname.endsWith('/invoice')) {
		return null;
	}
	return (
		<div id="footer">
			<div className="bg-black flex flex-col pb-4 text-[13px] sm:text-md">
				<div className="flex justify-between px-3 lg:w-full 2xl:w-2/3 mt-10 lg:mt-16 sm:px-6 mx-auto text-[#aaa]">
					<div className="flex justify-between lg:justify-around w-1/2 flex-col lg:flex-row mx-auto sm:mr-12">
						<div className="mb-8">
							<div className="text-lg text-white mb-3">❃ Suport clienți</div>
							<div className="px-5">
								<FooterLink to="/faq">Întrebări frecvente</FooterLink>
								<FooterLink to="/payment-types">Modalități de plată</FooterLink>
								<FooterLink href="https://anpc.ro/ce-este-sal">ANPC - SAL</FooterLink>
								<FooterLink href="https://anpc.ro/">ANPC</FooterLink>
							</div>
						</div>

						<div>
							<div className="text-lg text-white mb-3">❃ Informații</div>
							<div className="px-5">
								<FooterLink to="/about-us">Despre noi</FooterLink>
								<FooterLink to="/terms-of-service">Termeni și condiții</FooterLink>
								<FooterLink to="/gdpr">Politică de confidențialitate</FooterLink>
								<FooterLink to="/shipping-and-refund">
									Politică de livrare și retur
								</FooterLink>
								<FooterLink to="/cookies">Politică cookies</FooterLink>
							</div>
						</div>
					</div>

					<div className="flex justify-between lg:justify-around w-1/2 flex-col lg:flex-row  mx-auto">
						<div className="mb-8">
							<div className="text-lg text-white mb-3">❃ Contact</div>
							<div className="px-5">
								<FooterLink to="/contact">Contact</FooterLink>
								<FooterLink href="https://www.facebook.com/customstudio.ro">
									Facebook
								</FooterLink>
								<FooterLink href="https://www.instagram.com/customstudio.ro">
									Instagram
								</FooterLink>
								<div className="mb-1">contact@customstudio.ro</div>
								<div className="mb-1">0773 919 180</div>
							</div>
						</div>

						<div>
							<div className="text-lg text-white mb-3">❃ Custom Studio</div>
							<div className="px-5">
								<div className="mb-1">{COMPANY}</div>
								<div className="mb-1">CUI {CUI}</div>
								<div className="mb-1">București, Str. Stoian Militaru, Nr. 99</div>
							</div>
						</div>
					</div>
				</div>
				<div className="text-white text-center font-bold mt-8">
					Custom Studio © {new Date().getFullYear()}
				</div>
			</div>
		</div>
	);
}
