import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Review } from './Review';
import LoadingSpinner from './LoadingSpinner';

export default function ReviewsContainer({ id = 'reviews-container', className = '', data }) {
	const indicatorRef = useRef();
	const [index, setIndex] = useState(0);
	const [firstReviews, setFirstReviews] = useState(null);
	const [secondReviews, setSecondReviews] = useState(null);
	const [isFetching, setIsFetching] = useState(true);

	const switchToFirst = () => {
		if (index !== 0 && !isFetching) {
			setIndex(0);
		}
	};

	const switchToSecond = async () => {
		if (index !== 1 && !isFetching) {
			setIndex(1);

			if (!secondReviews) {
				setIsFetching(true);

				const res = await data[1].getReviews();
				if (res.error) toast.error(res.error);
				else setSecondReviews(res.reverse());

				setIsFetching(false);
			}
		}
	};

	const renderReviews = (reviews) => {
		if (!reviews) return;
		if (reviews.length === 0) {
			return <div className="text-center text-secondary max-w-md">Nicio recenzie 😔</div>;
		}
		return reviews.map((r) => <Review key={r._id} review={r} showStatus={data[index].showStatus} />);
	};

	// move the indicator when the index changes
	useEffect(() => {
		if (!indicatorRef.current) return;

		if (index === 1) {
			indicatorRef.current.style.transform = 'translateX(100%)';
		} else {
			indicatorRef.current.style.transform = '';
		}
	}, [index]);

	// fetch initial reviews
	useEffect(() => {
		async function fetchData() {
			const res = await data[0].getReviews();

			if (res.error) toast.error(res.error);
			else setFirstReviews(res.reverse());

			setIsFetching(false);
		}
		fetchData();
	}, []);

	return (
		<div id={id} className={className}>
			<div className="max-w-md mb-7">
				<div className="inline-block w-1/2 cursor-pointer">
					<div className={`reviews-button ${index === 0 ? 'active' : ''}`} onClick={switchToFirst}>
						<p>{data[0].label}</p>
						<span className="reviews-count">{data[0].reviewsCount}</span>
					</div>

					{data.length === 1 && (
						<div className="w-full bg-eee h-1 rounded-sm mt-3">
							<div className="reviews-indicator !w-full" />
						</div>
					)}
				</div>

				{data.length > 1 && (
					<>
						<div className="inline-block w-1/2 cursor-pointer">
							<div
								className={`reviews-button ${index === 1 ? 'active' : ''}`}
								onClick={switchToSecond}
							>
								<p>{data[1].label}</p>
								<span className="reviews-count">{data[1].reviewsCount}</span>
							</div>
						</div>

						<div className="w-full bg-eee h-1 rounded-sm mt-3">
							<div className="reviews-indicator" ref={indicatorRef}></div>
						</div>
					</>
				)}
			</div>

			{/* Reviews list */}
			{isFetching && (
				<div className="max-w-md">
					<LoadingSpinner className="mx-auto text-secondary" size={24} />
				</div>
			)}
			{index === 0 && renderReviews(firstReviews)}
			{index === 1 && renderReviews(secondReviews)}
		</div>
	);
}
