import { InputText } from 'components/Input';
import Button from 'components/Button';
import WhiteContainer from 'components/WhiteContainer';
import AddressWrapper from 'components/AddressWrapper';
import Form from 'components/Form';
import { Link } from 'react-router-dom';

export default function Individual({ user, openAddressModal, onSubmit }) {
	return (
		<WhiteContainer className="px-8 py-6">
			<Form onSubmit={onSubmit}>
				<InputText
					type="text"
					label="Nume complet"
					name="fullName"
					placeHolder="Nume și prenume"
					containerClassName="mb-5"
					defaultValue={user.fullName}
				/>

				<div className="mb-5">
					<p className="font-semibold mb-1">Adresă de domiciliu</p>
					<AddressWrapper address={user.address} compact />
					<Button
						text={user.address ? 'Modifică adresa' : 'Adaugă adresă'}
						onClick={openAddressModal}
						containerClassName="w-full primary-light mt-3"
					/>
				</div>

				<InputText
					label="IBAN"
					placeHolder="Opțional: ROxx xxxx xxxx xxxx xxxx xxxx"
					className="mb-5"
					name="iban"
					containerClassName="!mb-0"
					info="Contul bancar în care îți vom transfera banii din comenzi. Îl poți adăuga mai târziu."
				/>

				<div>
					<input id="terms-checkbox" type="checkbox" name="termsAccepted" />
					<label htmlFor="terms-checkbox" className="ml-1.5">
						Sunt de acord cu{' '}
						<Link to="/terms-of-service" className="link">
							Termeni și condiții
						</Link>
					</label>
				</div>

				<Button type="submit" containerClassName="primary w-full mt-5" text="Continuă" />
			</Form>
		</WhiteContainer>
	);
}
