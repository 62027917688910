import ReactGA from 'react-ga4';
import UserContext from '../../context/UserContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { API } from '../../constants';
import { RiGoogleFill, RiFacebookFill } from 'react-icons/ri';
import { FaTiktok } from 'react-icons/fa';

const waitForPopupClose = (popup, callback) => {
	const check = setInterval(() => {
		if (popup?.closed) {
			window.clearInterval(check);
			callback();
		}
	}, 500);
};

function popupWindow(url, windowName, width, height) {
	const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
	const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
	return window.open(
		url,
		windowName,
		`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
	);
}

function onLoginWithProvider(provider, checkSession, onClose, width = 448, height = 500) {
	return () => {
		ReactGA.event('login', { method: provider });

		const loginUrl = `${API}/auth/${provider.toLowerCase()}`;
		const loginWindow = popupWindow(loginUrl, provider, width, height);

		waitForPopupClose(loginWindow, async () => {
			const user = await checkSession();
			if (!user) {
				toast.error(`Eroare la conectarea cu ${provider}!`);
			} else {
				onClose(user);
				window.ttq.track('CompleteRegistration', { email: user.email });
			}
		});
	};
}

export function GoogleLoginButton({ className, onClose }) {
	const { checkSession } = useContext(UserContext);

	return (
		<button
			onClick={onLoginWithProvider('Google', checkSession, onClose)}
			className={`provider-login-button bg-google btn-google ${className || ''}`}
		>
			<div className="icon">
				<RiGoogleFill size={18} />
				<span className="separator">|</span>
			</div>
			<div className="flex-grow text-center">Google</div>
		</button>
	);
}

export function FacebookLoginButton({ className, onClose }) {
	const { checkSession } = useContext(UserContext);

	return (
		<button
			onClick={onLoginWithProvider('Facebook', checkSession, onClose, 624, 550)}
			className={'provider-login-button bg-facebook btn-facebook ' + className}
		>
			<div className="icon">
				<RiFacebookFill size={18} />
				<span className="separator">|</span>
			</div>

			<div className="flex-grow text-center">Facebook</div>
		</button>
	);
}

export function TikTokLoginButton({ className, onClose }) {
	const { checkSession } = useContext(UserContext);

	return (
		<button
			onClick={onLoginWithProvider('TikTok', checkSession, onClose, 420, 800)}
			className={'provider-login-button bg-primary hover:bg-black ' + className}
		>
			<div className="icon">
				<FaTiktok size={18} />
				<span className="separator">|</span>
			</div>

			<div className="flex-grow text-center">TikTok</div>
		</button>
	);
}
