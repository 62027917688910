import LoadingSpinner from 'components/LoadingSpinner';
import { LongArrowRight } from 'icons';
import { RiArrowDownLine, RiArrowRightLine, RiCheckLine } from 'react-icons/ri';

export default function HomeRoadmap() {
	return (
		<div className="flex justify-center items-center md:justify-start mt-8 mb-7 text-md md:text-lg">
			<div className="w-6 h-6 rounded-full bg-green flex justify-center items-center">
				<RiCheckLine className="text-white" size={14} />
			</div>
			<p className="roadmap-stage-name text-green">Lansare</p>

			<LongArrowRight className="mx-4 hidden lg:block" />
			<RiArrowRightLine className="text-blue mx-2 lg:hidden" />

			<LoadingSpinner className="text-blue shrink-0" size={26} />
			<p className="roadmap-stage-name !ml-2 text-blue relative">
				Expansiune
				<RiArrowDownLine className="roadmap-floating-pointing-arrow" />
			</p>

			<LongArrowRight className="mx-4 hidden lg:block" fill="#d0d0d0" />
			<RiArrowRightLine className="text-[#d0d0d0] mx-1 lg:hidden" />

			<div className="w-6 h-6 border-1 border-[#d9d9d9] rounded-full p-0.5 relative shrink-0">
				<div className="w-full h-full rounded-full bg-[#d9d9d9]" />
			</div>
			<p className="roadmap-stage-name text-light-secondary">Promovare</p>
		</div>
	);
}
