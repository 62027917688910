import { useState } from 'react';
import ReactGA from 'react-ga4';
import Login from './Login';
import Register from './Register';
import UIModal from 'components/modals/UIModal';

const LOGIN = 'login';
const REGISTER = 'register';

export default function LoginPanel({ onRequestClose }) {
	const [page, setPage] = useState(LOGIN);

	const changePage = (e, page) => {
		e.stopPropagation();
		setPage(page);
	};

	const onRequestCloseProxy = (e) => {
		ReactGA.event({ action: 'Close login panel', category: 'login' });
		onRequestClose(e);
	};

	return (
		<UIModal
			name="login-panel"
			containerClassName={`${page}-page`}
			className="login-container"
			onRequestClose={onRequestCloseProxy}
		>
			{page === LOGIN && (
				<Login onClose={onRequestClose} goToRegister={(e) => changePage(e, REGISTER)} />
			)}
			{page === REGISTER && (
				<Register onClose={onRequestClose} goToLogin={(e) => changePage(e, LOGIN)} />
			)}
		</UIModal>
	);
}
