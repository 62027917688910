import { toast } from 'react-toastify';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Status } from 'custom-studio-constants';
import { get } from 'api/_methods';
import { updateCashOnDeliveryExtraCost } from 'api/userApi';
import { oneOrMore } from 'utils/utils';
import useSWR from 'swr';
// import useSWRImmutable from 'swr/immutable';
import WhiteContainer from '../../components/WhiteContainer';
import useOutletTitle from '../../hooks/useOutletTitle';
import ProfileSection from '../../components/ProfileSection';
import UserContext from '../../context/UserContext';
import StatusIcon from 'components/StatusIcon';
import Button from 'components/Button';
import RequestCashPaymentModal from 'components/modals/RequestCashPaymentModal';
import PriceChangeInput from 'components/PriceChangeInput';
import Form from 'components/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import isArtist from 'utils/isArtist';

export default function OwnProfile() {
	useOutletTitle('Profil');
	const { user, setUser } = useContext(UserContext);
	const [showRequestModal, setShowRequestModal] = useState(false);
	const [canSaveExtraCost, setCanSaveExtraCost] = useState(false);

	const { data: productsSummary } = useSWR(
		isArtist(user) ? `/users/${user._id}/products-summary` : null,
		get
	);

	// TODO: stats
	// const { data: stats } = useSWRImmutable(isArtist(user) ? `/users/${user._id}/stats` : null, get);

	const linkToPublicShop = `${window.location.origin}/shop/${user.username}`;

	const saveCashOnDeliveryExtraCost = async (formData) => {
		const cost = formData.get('cashOnDeliveryExtraCost') || 0;
		const res = await updateCashOnDeliveryExtraCost(cost);
		if (res.error) {
			toast.error(res.error);
		} else {
			toast.success(res.message, { autoClose: 1500 });
			setUser({ ...user, cashOnDeliveryExtraCost: cost });
			setCanSaveExtraCost(false);
		}
	};

	return (
		<>
			<WhiteContainer className="p-4 md:p-6 overflow-hidden">
				<ProfileSection profile={user} selfActions={true} />
			</WhiteContainer>

			{isArtist(user) && (
				<>
					<p className="mt-10 mb-3 text-xl text-dark-secondary">Link către magazinul tău</p>
					<WhiteContainer className="mt-4 p-4">
						<p className="mb-3">Spune-le clienților unde te pot găsi:</p>
						<div className="w-full bg-light-blue text-blue flex items-center justify-between flex-wrap rounded-md px-4 py-1.5">
							<a
								href={linkToPublicShop}
								target="_blank"
								rel="noreferrer"
								className="link break-all"
							>
								{linkToPublicShop}
							</a>
							<div
								className="bg-white px-3 py-1 rounded-md cursor-pointer mt-2 w-full md:w-fit md:mt-0 text-center "
								onClick={() => {
									navigator.clipboard.writeText(linkToPublicShop);
									toast.info('Link-ul a fost copiat!');
								}}
							>
								Copiază
							</div>
						</div>
					</WhiteContainer>

					<p className="mt-10 mb-3 text-xl text-dark-secondary">Produsele mele</p>

					<WhiteContainer className="p-4 md:px-6">
						<div className="flex items-center justify-between">
							{productsSummary ? (
								<div className="fade-in text-dark-secondary">
									<p>
										{productsSummary.activeProducts}{' '}
										{oneOrMore(
											productsSummary.activeProducts,
											'produse active',
											'produs activ'
										)}
									</p>
									{productsSummary.pendingProducts > 0 && (
										<p className="mt-1">
											{productsSummary.pendingProducts}{' '}
											{oneOrMore(productsSummary.pendingProducts, 'produse', 'produs')}{' '}
											în aprobare
										</p>
									)}
									{productsSummary.deniedProducts > 0 && (
										<p className="mt-1">
											{productsSummary.deniedProducts}{' '}
											{oneOrMore(
												productsSummary.deniedProducts,
												'produse respinse',
												'produs respins'
											)}
										</p>
									)}
								</div>
							) : (
								<LoadingSpinner className="late-fade-in" />
							)}

							<Link to="/user/products/add">
								<Button containerClassName="primary-light" text="Adaugă produs" />
							</Link>
						</div>
					</WhiteContainer>

					<p className="mt-10 mb-3 text-xl text-dark-secondary">Metode de plată acceptate</p>

					<WhiteContainer className="p-4 md:p-6">
						<div className="flex items-center mb-1">
							<StatusIcon size={28} status={Status.Approved} />
							<p className="ml-2 font-semibold">Plată online</p>
						</div>
						<p className="ml-9 text-secondary text-md">
							Poți accepta plăți online prin intermediul platformei Custom Studio. Toate
							comenzile efectuate prin această metodă se supun{' '}
							<Link to="/shipping-and-refund" className="link">
								politicii noastre de livrare si retur.
							</Link>
						</p>
					</WhiteContainer>

					<WhiteContainer className="mt-4 p-4 md:p-6 overflow-hidden">
						<div className="flex items-center mb-1">
							<StatusIcon size={28} status={user.cashOnDeliveryStatus} />
							<p className="ml-2 font-semibold">Plată la livrare</p>
						</div>

						{user.cashOnDeliveryStatus === Status.Approved && (
							<Form onSubmit={saveCashOnDeliveryExtraCost} className="ml-9">
								<p className="text-secondary text-md">
									Plata la livrare este activă. Editează produsele pentru care vrei să
									accepți plata la livrare și bifează această funcționalitate.
								</p>
								<div className="flex items-center mt-2">
									<p>Cost suplimentar:</p>
									<PriceChangeInput
										name="cashOnDeliveryExtraCost"
										containerClassName="ml-2"
										defaultValue={user.cashOnDeliveryExtraCost}
										onChange={() => setCanSaveExtraCost(true)}
									/>
									{canSaveExtraCost && (
										<Button
											type="submit"
											size="small"
											containerClassName="primary-light ml-2 fade-in"
											text="Salvează"
										/>
									)}
								</div>
							</Form>
						)}

						{user.cashOnDeliveryStatus === Status.Pending && (
							<p className="ml-9 text-secondary text-md">
								Cererea ta este în așteptare. Un moderator o va evalua în cel mai scurt timp.
								Te vom anunța pe email despre orice actualizare.
							</p>
						)}

						{user.cashOnDeliveryStatus === Status.Disabled && (
							<div className="ml-9">
								<p className=" text-secondary text-md">
									Plata la livrare nu este activă.
									<br />
									Aceasta îți oferă posibilitatea de a accepta și plata cash la livrare doar
									la produsele selectate de tine.
								</p>
								<Button
									containerClassName="mt-4 primary-light text-md font-semibold !py-2 !px-4"
									text="Activează plata la livrare"
									onClick={() => setShowRequestModal(true)}
								/>
							</div>
						)}

						{user.cashOnDeliveryStatus === Status.Rejected && (
							<div className="ml-9">
								<p className="text-secondary text-md">Cererea ta a fost respinsă.</p>
							</div>
						)}

						{showRequestModal && (
							<RequestCashPaymentModal onRequestClose={() => setShowRequestModal(false)} />
						)}
					</WhiteContainer>
				</>
			)}
		</>
	);
}
