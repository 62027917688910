import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	RiChat3Line,
	RiShoppingCartLine,
	RiMenuLine,
	RiInformationLine,
	RiSearchLine,
	RiStore2Line,
} from 'react-icons/ri';
import ReactGA from 'react-ga4';
import UserContext from '../../context/UserContext';
import useOpenOnClick from '../../hooks/useOpenOnClick';
import UserDropdown from './UserDropdown';
import UnseenMessagesBadge from './UnseenMessagesBadge';
import SideMenu from './SideMenu';
import Logo from './Logo';
import LoginPanel from 'components/login/LoginPanel';

const links = [
	{
		text: 'Descoperă',
		page: '/products',
		desktopIcon: <RiSearchLine size={22} className="mr-1 text-darkest-gray" />,
		mobileIcon: <RiSearchLine size={22} className="mr-2 text-darkest-gray" />,
	},
	{
		text: 'Magazine',
		page: '/artists',
		desktopIcon: <RiStore2Line size={22} className="mr-1 text-darkest-gray" />,
		mobileIcon: <RiStore2Line size={22} className="mr-2 text-darkest-gray" />,
	},
	{
		text: 'Ajutor',
		page: '/faq',
		desktopIcon: <RiInformationLine size={22} className="mr-1 text-secondary" />,
		mobileIcon: <RiInformationLine size={22} className="mr-2 text-dark-secondary" />,
		color: 'text-dark-secondary',
	},
];

export default function Navbar(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, cart } = useContext(UserContext);
	const [showLoginPanel, setShowLoginPanel] = useState(false);
	const [openUserPanel, closeUserPanel] = useOpenOnClick('user-dropdown');
	const [showSideMenu, setShowSideMenu] = useState(false);
	const [cartItems, setCartItems] = useState(0);

	const navigateTo = (path) => {
		closeUserPanel();
		navigate(path);
	};

	const openLoginPanelFromSideNav = () => {
		ReactGA.event({ action: 'Open login panel', category: 'login' });
		setShowSideMenu(false);
		setTimeout(() => setShowLoginPanel(true), 200);
	};

	useEffect(() => {
		let items = 0;
		for (const subOrder of cart.subOrders) {
			for (const item of subOrder.cartItems) {
				items += item.quantity;
			}
		}
		setCartItems(items);
	}, [cart]);

	if (location.pathname.endsWith('/invoice')) {
		return null;
	}

	return (
		<header>
			{showLoginPanel && <LoginPanel onRequestClose={() => setShowLoginPanel(false)} />}

			<nav className="container flex justify-between">
				<div className="flex items-center relative">
					<RiMenuLine
						size={24}
						className="md:hidden mr-5 popover-parent"
						onClick={() => setShowSideMenu(true)}
					/>

					{/* Side menu */}
					{showSideMenu && (
						<SideMenu
							onRequestClose={() => setShowSideMenu(false)}
							openLoginPanel={() => openLoginPanelFromSideNav()}
						/>
					)}

					<Logo />

					<ul className="hidden md:flex">
						{links.map((link) => (
							<li className="z-10" key={link.page}>
								<Link
									to={link.page}
									className="flex items-center animated-fill px-3 py-2 mx-1"
								>
									{link.desktopIcon}
									<span className={link.color ? link.color : 'text-primary'}>
										{link.text}
									</span>
								</Link>
							</li>
						))}
					</ul>
				</div>

				<ul className="flex items-center relative">
					{user && (
						<li className="z-10 mx-1">
							<Link to="/chat" className="animated-fill block p-2.5 sm:mx-0" title="Chat">
								<RiChat3Line size={23} color="#333" className="chat-icon" />
								<UnseenMessagesBadge />
							</Link>
						</li>
					)}

					<li className="z-10 mx-1">
						<Link
							to="/cart"
							className="animated-fill block p-2.5  sm:mx-0"
							title="Coș de cumpărături"
						>
							<RiShoppingCartLine size={23} color="#333" />
							{cartItems > 0 && <div className="navbar__badge">{cartItems}</div>}
						</Link>
					</li>

					<UserDropdown
						openLoginPanel={() => setShowLoginPanel(true)}
						openUserPanel={openUserPanel}
						closePanels={closeUserPanel}
						navigateTo={navigateTo}
					/>
				</ul>
			</nav>
		</header>
	);
}
